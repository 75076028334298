import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as actions from "reducer/utilitySlice";

const PlanCard = ({ plan, selectedPlan, setSelectedPlan }) => {
  return (
    <div
      key={plan?.id}
      className={`${
        plan?.id === selectedPlan ? "plan-card-selected" : "plan-card"
      } mx-3 ${selectedPlan !== undefined ? "cursor-pointer" : ""}`}
      onClick={() => {
        if (selectedPlan !== undefined) {
          setSelectedPlan(plan.id);
        }
      }}
    >
      <h2>{plan?.name}</h2>
      <p className="description">{plan?.description}</p>
      <p className="price">
        {t("price")}: ${plan?.price}
      </p>
    </div>
  );
};

const DocumentUploadForm = ({ documentTypes, sessionId, currentDocuments }) => {
  const [uploads, setUploads] = useState({});

  const handleFileChange = (documentId, event) => {
    const file = event.target.files[0];
    setUploads({ ...uploads, [documentId]: file });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const allUploaded = Object.values(uploads).every((upload) => upload);
    if (allUploaded) {
      const formData = new FormData();
      formData.append("session", sessionId);
      Object.keys(uploads).forEach((documentId) => {
        formData.append(`type_${documentId}`, uploads[documentId]);
      });
      const response = await actions.bulkCreateSessionDocument(formData);
      if (response) {
        alert(t("docsUploadedSuccessfully"));
      }
    } else {
      alert(t("pleaseUploadAllFiles"));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {documentTypes.map((document) => {
        let is_available = currentDocuments.filter(
          (doc) => doc.document_type === document.document_type
        );

        return (
          <div key={document.id} className="mb-3">
            <h4>{document.document_type_title}</h4>
            {is_available.length > 0 ? (
              t("submittedBeingReviewed")
            ) : (
              <Form.Control
                type="file"
                id={document.id}
                onChange={(event) =>
                  handleFileChange(document.document_type, event)
                }
                // required
              />
            )}
          </div>
        );
      })}
      <div className="text-center">
        <Button type="submit">{t("submit")}</Button>
      </div>
    </Form>
  );
};

const ParticularSessions = () => {
  const { sessionId } = useParams();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [plan, setPlan] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchSession = async () => {
    const response = await actions.getSession(sessionId);
    if (response) {
      fetchOrder(response?.order);
      setCurrentSession(response);
      setPlan(response?.plan_data);
    }
  };

  const fetchOrder = async (orderId) => {
    const response = await actions.getOrder(orderId);
    if (response) {
      setCurrentOrder(response);
    }
  };

  const checkout = async () => {
    const formData = new FormData();
    formData.append("user", user.id);
    formData.append("session", sessionId);
    formData.append("total", currentSession.plan_data.price);
    const response = await actions.createOrder(formData);
    if (response) {
      console.log("Order created");
    }
  };

  useEffect(() => {
    console.log(sessionId);
    if (sessionId) {
      fetchSession();
    }
  }, [sessionId]);

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "90vh" }}
    >
      {!currentOrder ? (
        <div>
          <PlanCard plan={plan} />
          {currentSession?.step === 1 && (
            <div className="w-100 d-flex justify-content-center">
              <DocumentUploadForm
                documentTypes={reqDocumentTypes}
                sessionId={sessionId}
                currentDocuments={currentSession?.session_documents}
              />
            </div>
          )}
          {currentSession?.step === 2 && (
            <div className="w-100 d-flex justify-content-center">
              {t("docsBeingReviewed")}
            </div>
          )}
          {currentSession?.step === 3 && (
            <>
              <div className="w-100 d-flex justify-content-center">
                {`${t("allDocsBeingReviewed").$("pleaseProceedToCheckout")}`}{" "}
              </div>
              <div className="mt-3 text-center">
                <Button onClick={() => checkout()}>{t("checkout")}</Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="text-center">
          <div>{t("orderAlreadyCreated")}</div>
          <Button
            className="mt-3"
            onClick={() => navigate(`/account/orders/${currentOrder.id}`)}
          >
            {t("goToOrder")}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ParticularSessions;
