import React from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const BoostBusiness = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container
      fluid
      className="get-in-touch-home-background"
      style={{ minHeight: "80vh", borderRadius: large ? 48 : 20 }}
    >
      <Container>
        {large ? (
          <h2 className="text-white text-center">
            {t("Ready to boost your business in Dubai? We're here to help")}
          </h2>
        ) : (
          <h1 className="text-white text-center">
            {t("Ready to boost your business in Dubai? We're here to help")}
          </h1>
        )}
        <div className="d-flex justify-content-center align-items-center">
          <Button
            className="mt-3 bg-primary text-white primary-button border border-primary"
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              borderRadius: 40,
              height: 60,
            }}
            onClick={() => navigate(`/get-in-touch`)}
          >
            {t("getInTouch")}
          </Button>
        </div>
      </Container>
    </Container>
  );
};

export default BoostBusiness;
