import { useSpring, animated } from "react-spring";

const FAQSpringElement = (props) => {
  const { answer } = props;

  const animationStyleOpen = useSpring({
    from: { marginTop: -10, opacity: 0 },
    opacity: 1,
    marginTop: 5,
    config: { duration: 500 },
  });

  const animationStyleClose = useSpring({
    from: { marginTop: 10, opacity: 1 },
    to: { marginTop: -10, opacity: 0 },
    config: { duration: 500 },
  });

  return (
    <animated.div className="text-secondary" style={{ ...animationStyleOpen }}>
      {answer}
    </animated.div>
  );
};

export default FAQSpringElement;
