import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, GetInTouchFormSelect } from "components";
import { useLocation, useNavigate } from "react-router-dom";
// import * as actions from "reducer/utilitySlice";
import {
  licenseService,
  commercialLicenseService,
  professionalLicenseService,
  industrialLicenseService,
} from "assets";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
// import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import { FaHandsHelping } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import { BsPersonCheckFill } from "react-icons/bs";
import { TbLicense } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { BusinessSetupCard } from "./BusinessSetupService/BusinessSetupServices";

const LicenseService = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const benefits = [
    {
      id: 1,
      title: t("accesstoLocalandGlobalmarkets"),
    },
    {
      id: 2,
      title: t("diversificationofRevenueStreams"),
    },
    {
      id: 3,
      title: t("accessToGovernmentIncentives"),
    },
    {
      id: 4,
      title: t("accessToSkilledLabor"),
    },
    {
      id: 5,
      title: "Trade across a diverse array of goods and services",
    }
  ];

  const topReasons = [
    {
      id: 1,
      icon: <FaHandsHelping color="white" size={25} />,
      title: t("initialConsultation"),
      description: t(t("initialConsultaionText")),
    },
    {
      id: 2,
      icon: <IoDocument color="white" size={25} />,
      title: t("documentationPreparation"),
      description: t("documentPreperationText"),
    },
    {
      id: 3,
      icon: <BsPersonCheckFill color="white" size={25} />,
      title: t("govAndEDA"),
      description: t("govAndEDAText"),
    },
    {
      id: 4,
      icon: <TbLicense color="white" size={25} />,
      title: t("licenseAcquisition"),
      description: t("licenseAcquisitionText"),
    },
    {
      id: 5,
      icon: <BiSupport color="white" size={25} />,
      title: t("ongoingSupport"),
      description: t("ongoingSupportText"),
    },
  ];

  const childServices = [
    {
      id: 1,
      title: t("commercialLicense"),
      description: "Commercial Trade License in the UAE is essential for companies involved in buying or selling goods. Whether trading within the UAE or internationally, obtaining a commercial trade license is mandatory. Activities covered under a UAE commercial trade license include imports and exports, product trading, rental services, brokerage firms, logistics, and real estate, among others.",
      slug: null,
      service_image: commercialLicenseService,
    },
    {
      id: 2,
      title: t("professionalLicense"),
      description: "Professional Trade Licenses in the UAE are mandatory for service-oriented companies. These licenses allow 100% ownership but require a local service agent to facilitate the process. Businesses with professional trade licenses can engage in activities such as management consulting, law firms, marketing and design, auditing, accounting, and more.",
      slug: null,
      service_image: professionalLicenseService,
    },
    {
      id: 3,
      title: t("industrialLicense"),
      description: "An Industrial Trade License in Dubai and across the UAE is issued to businesses engaged in industrial and manufacturing activities. This license requires a physical office within the country. Notable activities under this license include manufacturing, casing, furnishing, and packaging products.",
      slug: null,
      service_image: industrialLicenseService,
    }
  ]

  // const [plans, setPlans] = useState([]);
  // const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  // const fetchServicePlans = async () => {
  //   const response = await actions.getServicePlans(param2);
  //   if (response) {
  //     setPlans(response.results);
  //   }
  // };

  // const fetchServiceReqDocuments = async () => {
  //   const response = await actions.getServiceDocumentTypes(param2);
  //   if (response) {
  //     setReqDocumentTypes(response.results);
  //   }
  // };

  // const createSession = async (planId) => {
  //   const response = await actions.addSession({ plan: planId });
  //   if (response) {
  //     dispatch(actions.setSessionId(response.id));
  //     navigate(`/services/${param2}/plan`);
  //   }
  // };

  // useEffect(() => {
  //   if (param2) {
  //     fetchServicePlans();
  //     fetchServiceReqDocuments();
  //   }
  // }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">{t("licenseServices")}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 py-5`}>
          <Col lg={8}>
            <div className="page-header mb-3">
              {t("Your Partner for Seamless Business Licensing in Dubai")}
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              {t("licenseServicesPara1")}
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              {t("licenseServicesPara2")}
            </div>
          </Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}>
        <Container className="d-flex flex-column my-5 py-5">
          <div className="page-header mb-3 pt-5">
            Acquiring a mainland license in Dubai offers several distinct benefits for businesses
          </div>
          <div className="subtitle-400 text-secondary mb-4">
            {t("licenseServicesPara3Title")}
          </div>
          <Row className="pb-5">
            <Col lg={5}>
              <Image src={licenseService} alt="License Photo" width="100%" style={{ borderRadius: 20 }} />
            </Col>
            <Col lg={7} className="d-flex flex-column justify-content-between">
              {benefits.map((benefit) => {
                return (
                  <div className="d-flex">
                    <div className="me-2 d-flex justify-content-center align-items-center bg-primary" style={{ width: 30, height: 30, borderRadius: 15 }}>
                      <FaCheck color="white" size={15} />
                    </div>
                    <div className="subtitle-400 text-secondary mb-3">
                      {benefit.title}
                    </div>
                  </div>
                )
              })}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <div className={`${large ? "w-75" : ""}`}>
          <div className="page-header mb-3 pt-5">
            Requirements for obtaining a trade license
          </div>
          <div className="subtitle-400 text-secondary mb-4">
            The process of obtaining a Business Trade License in Dubai involves several
            detailed steps. The requirements may vary depending on the type of license but
            these are a few general requirements,
          </div>
        </div>
        <Row className={`mb-5 pb-5 ${large ? "row-cols-2" : "row-cols-1"}`}>
          {topReasons.map((reason) => {
            return (
              <Col className="mb-4">
                <Card
                  className="p-4 d-flex justify-content-between border-0"
                  style={{ minHeight: 250, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                >
                  <div
                    className="bg-primary d-flex justify-content-center align-items-center mb-lg-0 mb-2"
                    style={{ width: 48, height: 48, borderRadius: 10 }}
                  >
                    {reason.icon}
                  </div>
                  <div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div className="font-size16 text-secondary">
                      {reason.description}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="bg-tertiary py-5">
        <Container className="d-flex flex-column align-items-start my-5">
          <div className="page-header my-2">
            Types of Trade License in the UAE
          </div>
          <div className="subtitle-400 text-secondary mb-4">
            The Department of Economic Development has categorized thousands of activities,
            streamlining major businesses under three primary trade licenses in Dubai and the UAE.
          </div>
          {childServices.map((service) => {
            return (
              <BusinessSetupCard service={service} />
            );
          })}
        </Container>
      </Container>
      {/* <Container className="p-0 d-flex flex-column justify-content-center align-items-center mb-5 pb-5">
        <div>
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("documents")}</b>
          </div>
          <h3 className="my-2">{t("processDocuments")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("letsStartWithDocumentation")}
          </div>
        </div>
        {plans.map((plan) => {
          return (
            <PlanCard
              plan={plan}
              reqDocumentTypes={reqDocumentTypes}
              serviceSlug={param2}
              createSession={createSession}
            />
          );
        })}
      </Container> */}
      <BoostBusiness />
      <FAQ />
      <Subscribe />
    </>
  );
};

export default LicenseService;
