import { Subscribe } from "components";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as authSlices from "reducer/authSlices";

const BasicDetails = () => {
  const form = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const user = useSelector((state) => state.auth.user);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    setIsSubmitting(true);
    const response = await authSlices.userLogin(formData);
    if (response) {
      if (response["msg"]) {
        // switch (response["msg"]) {
        //   case "NOT_VERIFIED":
        //     setShowForm();
        //     break;
        //   default:
        //     break;
        // }
      } else {
        dispatch(response);
      }
    }
  };

  return (
    <>
      <Container className="mb-4">
        <div
          className="p-3 p-lg-4 my-3 my-lg-4 mb-lg-5"
          style={{ borderRadius: 12 }}
        >
          <Form
            className="text-start w-100"
            ref={form}
            onSubmit={(e) => handleSave(e)}
          >
            <Row xs={1} lg={2}>
              <Col>
                <Form.Group className="mb-3 mb-lg-4">
                  <Form.Label className="body-text1">{t("name")}</Form.Label>
                  <Form.Control
                    name="name"
                    placeholder={t("enterYourName")}
                    defaultValue={user?.first_name}
                    type="text"
                    className="bg-white ps-4"
                    style={{
                      height: 60,
                      borderRadius: 30,
                      border: "1px solid #D0D5DD",
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mb-lg-4">
                  <Form.Label className="body-text1">{t("country")}</Form.Label>
                  {/* <Form.Control
                    name="country"
                    placeholder=""
                    type="text"
                    className="bg-white ps-4"
                    style={{
                      height: 60,
                      borderRadius: 30,
                      border: "1px solid #D0D5DD",
                    }}
                  /> */}
                  <Form.Select
                    // size="lg"
                    style={{
                      height: 60,
                      borderRadius: 30,
                      border: "1px solid #D0D5DD",
                    }}
                  >
                    <option disabled style={{ height: 40 }}>
                      {t("chooseCountry")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mb-lg-4">
                  <Form.Label className="body-text1">{t("mobile")}</Form.Label>
                  <div
                    className="d-flex overflow-hidden bg-white"
                    style={{
                      height: 60,
                      borderRadius: 30,
                      border: "1px solid #D0D5DD",
                    }}
                  >
                    <Form.Select
                      className="border-0 d-flex text-center bg-white"
                      style={{ width: 100 }}
                    >
                      <option style={{ height: 40 }} selected>
                        {/* <img src={uaeFlag} alt="" className="img-fluid" width={24} />  */}
                        IN
                      </option>
                    </Form.Select>
                    <Form.Control
                      name="mobile"
                      placeholder={t("enterYourMobile")}
                      defaultValue={user?.mobile}
                      type="mobile"
                      className="bg-white ps-4 border-0 w-100"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mb-lg-4">
                  <Form.Label className="body-text1">{t("email")}</Form.Label>
                  <Form.Control
                    name="email"
                    placeholder={t("enterYourEmail")}
                    defaultValue={user?.email}
                    type="text"
                    className="bg-white ps-4"
                    style={{
                      height: 60,
                      borderRadius: 30,
                      border: "1px solid #D0D5DD",
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2 mb-lg-3">
                  <Form.Label className="body-text1">
                    {t("addYourFullAddress")}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="address"
                    placeholder={t("enterYourAddress")}
                    type="text"
                    className="bg-white ps-4 pt-3"
                    style={{
                      // height: 60,
                      borderRadius: 30,
                      border: "1px solid #D0D5DD",
                    }}
                  />
                </Form.Group>
                <div
                  className="p-2 rounded mb-3 mb-lg-4"
                  style={{ background: "#F9F5FF" }}
                >
                  {`${t("note")}:-  ${t(
                    "Document will be shared by given address or register email id"
                  )}`}
                </div>
              </Col>
            </Row>

            <div className="d-flex">
              <Button
                className={`my-2 border-primary text-primary bg-white poppins-600 me-3 me-lg-4 ${
                  medium ? "" : "w-100"
                }`}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 30,
                  height: 60,
                }}
              >
                <span className="poppins">{t("cancel")}</span>
              </Button>
              <Button
                className={`my-2 bg-primary text-white primary-button poppins-600 ${
                  medium ? "" : "w-100"
                }`}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 30,
                  height: 60,
                }}
                type="submit"
              >
                {isSubmitting ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Saving...
                  </span>
                ) : (
                  <span className="poppins">{t("saveChanges")}</span>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default BasicDetails;
