import React, { useRef, useState } from "react";
import { Button, Form, Image, Row, Col } from "react-bootstrap";
import * as actions from "reducer/utilitySlice";
import { checkbox } from "assets";
import { notify } from "utilities/notifications/Notify";
import { IoDocumentOutline } from "react-icons/io5";
import { PiEyeBold } from "react-icons/pi";
import { MdDeleteOutline } from "react-icons/md";
import { t } from "i18next";

const DocumentUploadForm = ({
  documentTypes,
  session,
  fetchSession,
  currentDocuments,
  type = "plan",
}) => {
  const [uploads, setUploads] = useState({});
  const docRef = useRef(null);

  const handleFileChange = (documentId, event) => {
    const file = event.target.files[0];
    setUploads({ ...uploads, [documentId]: file });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const allUploaded = Object.values(uploads).every((upload) => upload);
    if (allUploaded) {
      const formData = new FormData();
      formData.append("session", session.id);
      Object.keys(uploads).forEach((documentId) => {
        formData.append(`type_${documentId}`, uploads[documentId]);
      });
      const response = await actions.bulkCreateSessionDocument(formData);
      if (response) {
        notify(t("docsUploadedSuccessfully"), "success");
        setUploads([]);
        fetchSession();
      }
    } else {
      notify("pleaseUploadAllFiles", "error");
    }
  };

  const handleDeleteDocument = async (document_id) => {
    const response = await actions.deleteSessionDocument({ id: document_id });
    if (response) {
      notify(response["msg"], "success");
      fetchSession();
    }
  };

  const handleFileReupload = async (event, documentTypeId, document_id) => {
    const response = await actions.deleteSessionDocument({ id: document_id });
    if (response) {
      notify(response["msg"], "success");
      fetchSession();
    }
    const file = event.target.files[0];
    setUploads({ ...uploads, [documentTypeId]: file });
  };

  return (
    <Form
      className={`w-100 ${
        type === "plan" ? "p-4" : ""
      } d-flex flex-column justify-content-center align-items-center`}
      style={{ borderRadius: 24 }}
      onSubmit={handleSubmit}
    >
      <div
        className="w-100 d-flex flex-column border"
        style={{ borderRadius: 24 }}
      >
        <Row className="py-2">
          <Col sm={1} />
          <Col sm={6} className="d-flex align-items-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 40,
                height: 40,
                border: "8px solid #F9F5FF",
                borderRadius: 20,
                backgroundColor: "#F4EBFF",
              }}
            >
              <IoDocumentOutline size={18} color="#0010F7" />
            </div>
            <span className="body-text2 text-secondary">Document List</span>
          </Col>
          <Col
            sm={2}
            className="d-flex align-items-center body-text2 text-secondary"
          >
            Approval Status
          </Col>
          <Col
            sm={1}
            className="d-flex align-items-center body-text2 text-secondary"
          >
            Size
          </Col>
          <Col sm={2}></Col>
        </Row>
        {documentTypes.map((document) => {
          let is_available = currentDocuments.filter(
            (doc) => doc.document_type === document.document_type
          );
          return (
            <>
              <hr className="m-0" style={{ color: "#475467" }} />
              <Row>
                <Col
                  sm={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  {is_available.length > 0 ? (
                    <Image src={checkbox} alt="Checkbox" />
                  ) : (
                    <Form.Check disabled />
                  )}
                </Col>
                <Col sm={6} className="d-flex flex-column py-4">
                  <div className="body-text1">
                    <b>{document.document_type_title}:</b>
                  </div>
                  <div className="body-text1 text-secondary">
                    {document.document_type_description}
                  </div>
                </Col>
                <Col
                  sm={2}
                  className="d-flex align-items-center body-text2 text-secondary"
                >
                  {is_available[0]?.status}
                </Col>
                <Col
                  sm={1}
                  className="d-flex align-items-center body-text2 text-secondary"
                >
                  {uploads.hasOwnProperty(document.document_type)
                    ? uploads[document.document_type]?.size / 1000 + " kB"
                    : is_available[0]?.document_data?.size}
                </Col>
                <Col sm={2} className="d-flex align-items-center">
                  {is_available.length > 0 ? (
                    is_available[0]?.status === "REJECTED" ? (
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <div className="custom-file-input">
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            onChange={(event) =>
                              handleFileReupload(
                                event,
                                document.document_type,
                                is_available[0].document
                              )
                            }
                          />
                          <div htmlFor="fileInput">
                            {uploads.hasOwnProperty(document.document_type)
                              ? uploads[document.document_type]?.name
                              : "Reupload"}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <a
                          href={is_available[0]?.document_data?.document_file}
                          target="_blank"
                          className="text-decoration-none"
                        >
                          <div
                            className="d-flex justify-content-center align-items-center me-3 cursor-pointer"
                            style={{
                              width: 48,
                              height: 48,
                              border: "8px solid #F9F5FF",
                              borderRadius: 24,
                              backgroundColor: "#F4EBFF",
                            }}
                          >
                            <PiEyeBold className="text-primary" size={22} />
                          </div>
                        </a>
                        {session.step < 4 && (
                          <div
                            className="d-flex justify-content-center align-items-center cursor-pointer"
                            style={{
                              width: 48,
                              height: 48,
                              border: "8px solid #F9F5FF",
                              borderRadius: 24,
                              backgroundColor: "#F4EBFF",
                            }}
                            onClick={() =>
                              handleDeleteDocument(is_available[0].document)
                            }
                          >
                            <MdDeleteOutline
                              className="text-primary"
                              size={22}
                            />
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <div className="custom-file-input">
                        <input
                          type="file"
                          id="fileInput"
                          className="file-input"
                          onChange={(event) =>
                            handleFileChange(document.document_type, event)
                          }
                        />
                        <div htmlFor="fileInput">
                          {uploads.hasOwnProperty(document.document_type)
                            ? uploads[document.document_type]?.name
                            : t("upload")}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          );
        })}
      </div>
      {session.step < 4 && (
        <Button
          className="mt-4 text-white primary-button border border-primary"
          style={{
            paddingLeft: 35,
            paddingRight: 35,
            borderRadius: 40,
            height: 60,
          }}
          type="submit"
        >
          {t("submit")}
        </Button>
      )}
    </Form>
  );
};

export default DocumentUploadForm;
