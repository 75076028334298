import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
    whatSetsUsApart,
} from "assets";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";

const SetUsApart = () => {
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <Container
            fluid
            className={`d-flex flex-column align-items-center p-0 ${large
                ? "justify-content-center"
                : "pt-5"
                }`}
            style={{
                minHeight: "100vh",
            }}
        >
            <div className="custom-header custom-blue-color mt-5 mb-3" style={{ zIndex: 999 }}>
                {t("whatSetsUsApart")}?
            </div>
            <Container className="text-primary">
                <div className="d-flex flex-column justify-content-center align-items-center subtitle-400 text-center">
                    <span>{t("whatSetsUsApartDescription1")}</span>
                    <span>{t("whatSetsUsApartDescription2")}</span>
                </div>
                <div className="d-flex justify-content-center align-items-center my-5">
                    <Image className="w-100" src={whatSetsUsApart} alt="SKV Logo" />
                </div>
            </Container>
        </Container>
    );
};

export default SetUsApart;
