import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as utilityActions from "reducer/utilitySlice";
import { pending, current, done } from "assets";
import { DocumentUploadForm } from "components";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";

const InquiryCard = ({ inquiry, setSelectedSession }) => {
  const { t, i18n } = useTranslation();

  return (
    <Card
      className="border-primary overflow-hidden cursor-pointer my-4 my-lg-3"
      style={{
        background: "#F3F4FB",
        borderColor: "#8B8DDA",
        minHeight: 200,
        borderRadius: 24,
      }}
      onClick={() => setSelectedSession(inquiry)}
    >
      <Card.Body
        className="border-primary"
        style={{ background: "#F9F5FF", borderBottom: "1px solid" }}
      >
        <Row className="d-flex algin-items-center p-3" style={{ height: 150 }}>
          <Col
            lg={9}
            className="d-flex flex-column justify-content-center align-items-start"
          >
            <Card.Title>{inquiry?.plan_data?.service_data?.title}</Card.Title>
            <Card.Text>
              {t("plan")} - {inquiry?.plan_data?.name}
            </Card.Text>
          </Col>
          <Col
            lg={3}
            className="user-select-none page-header p-0 mb-2 d-flex flex-column justify-content-center align-items-end p-4"
            style={{ fontWeight: 700 }}
          >
            ${inquiry?.plan_data?.price}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="bg-white text-primary px-4 py-3">
        <Card.Title className="mx-2 d-flex flex-column">
          <Row>
            <Col sm={4}>{t("documentStatus")}</Col>
            <Col sm={2}>:</Col>
            <Col sm={6}>{inquiry?.status}</Col>
          </Row>
          <Row className="mt-2">
            <Col sm={4}>{t("paymentStatus")}</Col>
            <Col sm={2}>:</Col>
            <Col sm={6}>{inquiry?.payment_status ? "PAID" : "PENDING"}</Col>
          </Row>
          <Row className="mt-2">
            <Col sm={4}>{t("servicesStatus")}</Col>
            <Col sm={2}>:</Col>
            <Col sm={6}>{inquiry?.session_closed ? "CLOSED" : "OPEN"}</Col>
          </Row>
        </Card.Title>
      </Card.Footer>
    </Card>
  );
};

const StatusInfo = ({ status, received_on, verified_on, delivery_on }) => {
  return (
    <div className="mt-3 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="poppins-600">Documents Received</span>
        <span style={{ color: "#0010F7" }}>
          Received on {moment(received_on).format("DD-MM-YY")}
        </span>
      </div>
      <div style={{ height: 0, width: 300 }} />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="poppins-600">Verification</span>
        <span style={{ color: "#0010F7" }}>
          Verified on {moment(verified_on).format("DD-MM-YY")}
        </span>
      </div>
      <div style={{ height: 0, width: 290 }} />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="poppins-600">Delivery</span>
        <span style={{ color: "#0010F7" }}>
          Expected on{" "}
          {delivery_on ? moment(delivery_on).format("DD-MM-YY") : "-"}
        </span>
      </div>
    </div>
  );
};

const InquiryDetails = ({ thisSession }) => {
  const [currentSession, setCurrentSession] = useState(thisSession);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchServiceReqDocuments = async () => {
    const response = await utilityActions.getServiceDocumentTypes(
      currentSession?.plan_data?.service_data?.slug
    );
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const fetchSession = async () => {
    const response = await utilityActions.getSession(currentSession.id);
    if (response) {
      setCurrentSession(response);
    }
  };

  useEffect(() => {
    if (currentSession) {
      fetchServiceReqDocuments();
    }
  }, [currentSession]);

  return (
    <>
      <Card className="mt-4 px-5 pt-5 border" style={{ borderRadius: "20px" }}>
        <b className="mb-4">{t("trackYourProgress")}</b>
        <h5>{currentSession?.plan_data?.service_data?.title}</h5>
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="w-100">
            {currentSession.step === 2 ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="bg-tertiary d-flex justify-content-center align-items-center"
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <Image src={current} alt="Current" style={{ width: 32, height: 32 }} />
                  </div>
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#EAECF0",
                    }}
                  />
                  <Image src={pending} alt="Pending" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#EAECF0",
                    }}
                  />
                  <Image src={pending} alt="Pending" style={{ width: 32, height: 32 }} />
                </div>
                <StatusInfo
                  status={currentSession.status}
                  received_on={currentSession.created_at}
                  verified_on={currentSession.updated_at}
                  delivery_on={currentSession.delivery_date}
                />
              </div>
            ) : currentSession.step === 3 ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <div
                    className="bg-tertiary d-flex justify-content-center align-items-center"
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <Image src={current} alt="Current" style={{ width: 32, height: 32 }} />
                  </div>
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#EAECF0",
                    }}
                  />
                  <Image src={pending} alt="Pending" style={{ width: 32, height: 32 }} />
                </div>
                <StatusInfo
                  status={currentSession.status}
                  received_on={currentSession.created_at}
                  verified_on={currentSession.updated_at}
                  delivery_on={currentSession.delivery_date}
                />
              </div>
            ) : currentSession.step === 4 ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <div
                    className="bg-tertiary d-flex justify-content-center align-items-center"
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <Image src={current} alt="Current" style={{ width: 32, height: 32 }} />
                  </div>
                </div>
                <StatusInfo
                  status={currentSession.status}
                  received_on={currentSession.created_at}
                  verified_on={currentSession.updated_at}
                  delivery_on={currentSession.delivery_date}
                />
              </div>
            ) : currentSession.step === 5 ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                </div>
                <StatusInfo
                  status={currentSession.status}
                  received_on={currentSession.created_at}
                  verified_on={currentSession.updated_at}
                  delivery_on={currentSession.delivery_date}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Card>
      {(currentSession.step === 2 ||
        currentSession.step === 3 ||
        currentSession.step === 4) && (
        <div className="d-flex justify-content-center align-items-center">
          <Card
            className="w-100 my-5 d-flex justify-content-center align-items-center border-0"
            style={{ borderRadius: 16 }}
          >
            <DocumentUploadForm
              documentTypes={reqDocumentTypes}
              session={currentSession}
              fetchSession={fetchSession}
              currentDocuments={currentSession?.session_documents}
              type="account"
            />
          </Card>
        </div>
      )}
    </>
  );
};

const TrackServices = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const user = useSelector((state) => state.auth.user);
  const [selectedSession, setSelectedSession] = useState(null);
  const [userSessions, setUserSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchAllUserSessions = async () => {
    const response = await utilityActions.getSessions(user.id);
    if (response) {
      setUserSessions(response.results);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchAllUserSessions();
    }
  }, [user]);

  return (
    <>
      <Container className="mt-4 mb-4 mb-lg-5">
        <div
          className="pb-3 mb-4"
          style={{ borderBottom: "2px solid #EAECF0" }}
        >
          <div className="font-size32 poppins-500">{t("services")}</div>
          <div>{t("trackYourService")}</div>
        </div>
        {selectedSession ? (
          <>
            <h5 className="d-flex text-primary">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSelectedSession(null);
                  fetchAllUserSessions();
                }}
              >
                <IoArrowBack />
                <span className="ms-2">Back</span>
              </div>
            </h5>
            <InquiryDetails thisSession={selectedSession} />
          </>
        ) : (
          <>
            {loading ? (
              <div
                className="w-100 d-flex flex-column justify-content-center align-items-center"
                style={{ minHeight: "30vh" }}
              >
                <Spinner />
              </div>
            ) : (
              <Row className="row-cols-2">
                {userSessions.length > 0 ? (
                  <>
                    {userSessions.map((inquiry, index) => (
                      <Col>
                        <InquiryCard
                          key={index}
                          inquiry={inquiry}
                          setSelectedSession={setSelectedSession}
                        />
                      </Col>
                    ))}
                  </>
                ) : (
                  <div
                    className="w-100 d-flex flex-column justify-content-center align-items-center"
                    style={{ minHeight: "30vh" }}
                  >
                    <span>No Services yet!</span>
                    <Button
                      className="mt-5 bg-primary text-white primary-button border border-primary poppins-600"
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        borderRadius: 30,
                        height: 60,
                      }}
                      onClick={(e) => navigate(`/services`)}
                    >
                      {t("goToServices")}
                    </Button>
                  </div>
                )}
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default TrackServices;
