import { Image, Button } from "react-bootstrap";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { google_icon } from "assets";
import axios from "axios";
import { useDispatch } from "react-redux";
import * as authSlices from "reducer/authSlices";
import { useState } from "react";
import { notify } from "utilities/notifications/Notify";
import { useTranslation } from "react-i18next";

const GoogleLoginButton = ({ setShowLoginModal, type = null }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [textColor, setTextColor] = useState(false);

  const onGoogleLoginSuccess = async (codeResponse) => {
    // console.log(codeResponse);
    const { access_token } = codeResponse;

    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v1/userinfo/",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response.status === 200) {
      // console.log(response.data);
      const { email, name } = response.data;
      try {
        const resp1 = await axios.post("/user/social_login/", {
          username: email,
          first_name: name,
        });
        if (resp1.status === 200) {
          dispatch(authSlices.saveSocialLoginInfo(resp1.data));
          setShowLoginModal(false);
        }
      } catch (err) {
        console.log({ err });
        // notify(err?.response?.data?.msg, "error");
      }
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
  });

  if (type === "mobile") {
    return (
      <>
        <div className="text-center">{t("or")}</div>
        <Button
          className="my-2 text-primary bg-white poppins-600 w-100"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            height: 60,
          }}
          onClick={async () => await googleLogin()}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="me-2">
              <Image src={google_icon} alt="Google Icon" />
            </div>
            <div>{t("loginWithGoogle")}</div>
          </div>
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="text-center">{t("or")}</div>
      <Button
        className="my-2 text-primary bg-white poppins-600 w-100"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 30,
          height: 60,
        }}
        onClick={async () => await googleLogin()}
        disabled
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="me-2">
            <Image src={google_icon} alt="Google Icon" />
          </div>
          <div>{t("loginWithGoogle")}</div>
        </div>
      </Button>
    </>
  );
};

export default GoogleLoginButton;
