import React, { useState } from "react";
import { Card, Form, Row, Col, Image, Button } from "react-bootstrap";
import { checkicon } from "assets";
import { t } from "i18next";
import { IoDocuments } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";

const PlanCard = (props) => {
  const { plan, reqDocumentTypes, createSession } = props;
  const [allowBuy, setAllowBuy] = useState(false);
  const large = useMediaQuery({ query: "(min-width: 992px)" });


  return (
    <Card
      className={`${large ? "w-75" : "w-100"}`}
      style={{ border: "1px solid #EAECF0", borderRadius: 16 }}
    >
      <div
        className="d-flex justify-content-between py-4 px-5"
        style={{ borderBottom: "1px solid #EAECF0" }}
      >
        <span className="d-flex flex-column justify-content-center">
          <h4>{plan?.name}</h4>
          <div className="text-secondary">
            {plan?.description}
          </div>
        </span>
        <span className="d-flex justify-content-center align-items-center">
          <span
            className="d-flex justify-content-center align-items-center me-3"
            style={{ width: 60, height: 60, borderRadius: 30, backgroundColor: "#f7cc4c" }}
          >
            <IoDocuments color="white" size={30} />
          </span>
        </span>
      </div>
      <div className="p-5" style={{ borderBottom: "1px solid #EAECF0" }}>
        <b className="p-0 m-0">{t("documentsListRequired")}</b>
        <Row className={`${large ? "row-cols-3" : "row-cols-1"}`}>
          {reqDocumentTypes.map((doc) => {
            return (
              <Col className="my-2">
                <Image src={checkicon} alt="Check Icon" />
                <span className="text-secondary ms-2">
                  {doc.document_type_title}
                </span>
              </Col>
            );
          })}
        </Row>
      </div>
      <Form
        className="p-5"
        onSubmit={(e) => {
          e.preventDefault();
          createSession(plan.id);
        }}
      >
        <Form.Group>
          <Form.Check
            type="checkbox"
            label={t("docsAgreeText")}
            required
            onChange={(e) => {
              setAllowBuy(e.target.checked);
            }}
          />
        </Form.Group>
        <div className="text-center mt-3">
          <Button
            type="submit"
            className={`${allowBuy ? "text-white primary-button" : "bg-tertiary text-primary"} poppins-600 ${large ? "w-25" : "w-100"}`}
            style={{ borderRadius: 30, height: 60 }}
          >
            {t("process")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default PlanCard;
