import { Button, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authSlices from "reducer/authSlices";
import { asyncStatuses } from "store/enums";
import { notify } from "utilities/notifications/Notify";
import SignupForm from "./SignupForm";
import { login } from "assets";
import GoogleLoginButton from "./GoogleLoginButton";
import VerifyForm from "./VerifyForm";
import ForgotPassword from "./ForgotPasswordForm";
import { useTranslation } from "react-i18next";

// const formDataToObject = (formData) => {
//   const object = {};
//   formData.forEach((value, key) => {
//     if (Object.prototype.hasOwnProperty.call(object, key)) {
//       if (!Array.isArray(object[key])) {
//         object[key] = [object[key]];
//       }
//       object[key].push(value);
//     } else {
//       object[key] = value;
//     }
//   });
//   return object;
// };

const LoginModal = ({
  showLoginModal,
  setShowLoginModal,
  showForm,
  setShowForm,
  email,
  setEmail,
  phone,
  setPhone,
}) => {
  const { t } = useTranslation();

  console.log({ showLoginModal });
  const form = useRef(null);
  const dispatch = useDispatch();

  const loginRequestStatus = useSelector((store) => store.auth.status);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [showForm, setShowForm] = useState("login");
  const [msg, setMsg] = useState(null);

  const [checked, setChecked] = useState(false);
  const [signedUpEmail, setSignedUpEmail] = useState("");
  const [timer, setTimer] = useState(0);

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    setIsSubmitting(true);
    // dispatch(authSlices.userLogin(formData));
    const response = await authSlices.userLogin(formData);
    if (response) {
      if (response["msg"]) {
        switch (response["msg"]) {
          case "NOT_VERIFIED":
            setShowForm();
            break;
          default:
            break;
        }
      } else {
        dispatch(response);
      }
    }
  };

  useEffect(() => {
    console.log({ loginRequestStatus, setShowLoginModal, dispatch });
    if (loginRequestStatus === asyncStatuses.LOADING) {
      // notify("Login failed");
    } else if (loginRequestStatus === asyncStatuses.SUCCESS) {
      form.current?.reset();
      setShowLoginModal(false);
      setIsSubmitting(false);
    } else if (loginRequestStatus === asyncStatuses.FAILED) {
      setIsSubmitting(false);
      dispatch(authSlices.resetAuthSlice());
    }
  }, [loginRequestStatus, setShowLoginModal, dispatch]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const formData = new FormData(form.current);
    console.log(formData);

    if (formData.get("password") !== formData.get("password1")) {
      notify(t("passwordsDidNotMatch"), "error");
      setIsSubmitting(false);
      return;
    }

    if (checked) {
      for (const value of formData.values()) {
        if (value === "" || value === null) {
          setIsSubmitting(false);
          notify(t("pleaseEnterAllTheValues"));
          return false;
        }
      }
      formData.append("username", formData.get("email"));
      const response = await authSlices.signingUp(formData);
      console.log(response);
      if (response.status === 201) {
        form.current.reset();
        setIsSubmitting(false);
        notify(response.data.msg, "success");
        setTimer(response.data.time_left);
        setSignedUpEmail(formData.get("email"));
        setShowForm("verify");
      } else if (response?.response?.status === 400) {
        notify(t("pleaseEnterValidData"), "error");
      } else {
        notify(t("problemWhileSigningUp"), "error");
      }
      setIsSubmitting(false);
    } else {
      notify(t("pleaseAgreeToTermsAndConditions"), "warning");
      setIsSubmitting(false);
    }
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);
    formData.append("username", signedUpEmail);
    const response = await authSlices.verifyAccount(formData);
    if (response) {
      console.log(response);
      switch (response.status) {
        case "VERIFIED":
          notify(response.msg, "success");
          setShowForm("login");
          break;
        default:
          setIsSubmitting(false);
      }
      setIsSubmitting(false);
    } else {
      setMsg(null);
    }
  };

  const resendOtpRequest = async (e) => {
    e.preventDefault();
    const response = await authSlices.resendVerifyOtp({
      username: signedUpEmail,
      email: signedUpEmail,
    });
    if (response) {
      notify(response.msg, "success");
      setTimer(response.time_left);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const onModalHide = useCallback(() => {
    setShowForm("login");
    setShowLoginModal(false);
  }, [setShowForm, setShowLoginModal]);

  return (
    <Modal
      dialogClassName="d-flex align-items-center h-100"
      contentClassName="bg-white border-R80"
      show={showLoginModal}
      onHide={onModalHide}
      animation={false}
      size="xl"
    >
      <Modal.Body className="p-0">
        <Row className="m-0 w-100 d-flex d-flex justify-content-center align-items-center">
          <Col sm={6}>
            <div className="border-grey rounded">
              {showForm === "login" ? (
                <div className="px-5">
                  <h3 className="pe-3">{t("welcomeBack")}</h3>
                  <div className="text-secondary my-3">
                    {t("pleaseEnterYourDetails")}
                  </div>
                  <Form
                    className="text-start w-100"
                    ref={form}
                    onSubmit={(e) => handleLogin(e)}
                  >
                    <Form.Group className="mt-4">
                      <Form.Label className="body-text1">
                        {t("email")}
                      </Form.Label>
                      <Form.Control
                        name="username"
                        placeholder={t("enterYourEmail")}
                        type="text"
                        className="bg-white ps-4"
                        style={{
                          height: 60,
                          borderRadius: 30,
                          border: "1px solid #D0D5DD",
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mt-4">
                      <Form.Label className="body-text1">
                        {t("password")}
                      </Form.Label>
                      <Form.Control
                        name="password"
                        placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                        type="password"
                        className="bg-white ps-4"
                        style={{
                          height: 60,
                          borderRadius: 30,
                          border: "1px solid #D0D5DD",
                        }}
                      />
                    </Form.Group>
                    <div
                      className="d-flex justify-content-end mt-2 mb-4 cursor-pointer text-bold"
                      onClick={() => {
                        setShowForm("forgotpassword");
                      }}
                    >
                      <small className="text-primary">
                        {t("forgotPassword")}
                      </small>
                    </div>
                    <Button
                      className="my-2 bg-primary text-white primary-button poppins-600 w-100"
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        borderRadius: 30,
                        height: 60,
                      }}
                      type="submit"
                    >
                      {isSubmitting ? (
                        <span>
                          <Spinner size="sm" className="me-2" />
                          {`${t("signingIn")}...`}
                        </span>
                      ) : (
                        <span className="poppins">{t("signIn")}</span>
                      )}
                    </Button>
                  </Form>
                  <GoogleLoginButton setShowLoginModal={setShowLoginModal} />
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="text-lightgrey me-2">
                      {t("dontHaveAnAccount")}
                    </p>
                    <div
                      onClick={() => setShowForm("signup")}
                      className="cursor-pointer"
                    >
                      <p className="text-primary">
                        <b>{t("Sign Up")}</b>
                      </p>
                    </div>
                  </div>
                </div>
              ) : showForm === "signup" ? (
                <SignupForm
                  form={form}
                  handleSignUp={handleSignUp}
                  checked={checked}
                  setChecked={setChecked}
                  isSubmitting={isSubmitting}
                  setShowForm={setShowForm}
                />
              ) : showForm === "verify" ? (
                <VerifyForm
                  form={form}
                  handleVerifyEmail={handleVerifyEmail}
                  resendOtpRequest={resendOtpRequest}
                  setShowForm={setShowForm}
                  msg={msg}
                  timer={timer}
                  setTimer={setTimer}
                />
              ) : showForm === "forgotpassword" ? (
                <ForgotPassword setShowForm={setShowForm} />
              ) : null}
            </div>
          </Col>
          <Col sm={6} className="m-0 p-0 d-flex justify-content-end">
            <Image src={login} alt="Login Image" className="border-R80" style={{ height: 700 }} />
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Body className="d-flex flex-column p-3">
        <Button variant="secondary" className="mb-3">
          Sign In with Google
        </Button>
        <Button variant="secondary" className="mb-3">
          Sign In with Apple
        </Button>
        <Button variant="secondary" className="mb-3">
          Sign In with Facebook
        </Button> */}
      {/* <div>
          <p className="text-lightgrey text-center">
            Don't have account account?{" "}
            <span className="text-blue">Sign Up</span>
          </p>
        </div> */}
      {/* </Modal.Body> */}
      {/* <Modal.Footer className="text-center">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default LoginModal;
