import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notify } from "utilities/notifications/Notify";
import * as utilitySlice from "reducer/utilitySlice";


const GetInTouchFormSelect = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);

  const handleServiceChange = (event) => {
    const options = event.target.options;
    const selectedOptions = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedOptions.push(options[i].value);
      }
    }
    setSelectedServices(selectedOptions);
  };

  const fetchContactService = async () => {
    const response = await utilitySlice.getContactServices();
    if (response.status === 200) {
      setServices(response.data)
    }
  }

  const handleSendMessge = async (e) => {
    e.preventDefault();

    setIsSending(true);
    const formData = new FormData(form.current);

    for (const value of formData.values()) {
      if (value === "" || value === null) {
        notify("Please enter all the values");
        setIsSending(false);
        return false;
      }
    }

    formData.append('service_list', JSON.stringify(selectedServices));

    const response = await utilitySlice.addContact(formData);
    if (response.status === 201) {
      form.current.reset();
      notify(
        "Thank you for contacting us. We'll connect with you soon!",
        "success"
      );
    } else {
      notify("There was some problem submitting the form!", "error");
    }
    setIsSending(false);
  };

  useEffect(() => {
    fetchContactService()
  }, [])

  return (
    <Form ref={form} className="text-primary px-4" onSubmit={(e) => handleSendMessge(e)}>
      <h4 className="mt-4">Connect with us!</h4>
      <Form.Group className="mt-3">
        <Form.Control
          name="full_name"
          placeholder="Enter your Full Name"
          type="text"
          className="bg-white px-4 border-primary text-primary poppins-600"
          style={{
            height: 50,
            borderRadius: 25,
          }}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Control
          name="email"
          placeholder="Enter your Email"
          type="text"
          className="bg-white px-4 border-primary text-primary poppins-600"
          style={{
            height: 50,
            borderRadius: 25,
          }}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Control
          name="phone"
          placeholder="Enter your Phone Number"
          type="text"
          className="bg-white px-4 border-primary text-primary poppins-600"
          style={{
            height: 50,
            borderRadius: 25,
          }}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Select
          name="services"
          value={selectedServices}
          onChange={handleServiceChange}
          className="px-4 border-primary text-primary poppins-600"
          style={{
            height: 50,
            borderRadius: 25,
            border: "1px solid #D0D5DD",
          }}
        >
          {services.map((service) => (
            <option key={service.id} value={service.id}>
              {t(service.name)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Control
          name="message"
          as="textarea"
          rows={3}
          placeholder={t("leaveUsAMessage")}
          type="text"
          className="bg-white p-4 border-primary text-primary poppins-600"
          style={{ borderRadius: 30 }}
        />
      </Form.Group>
      <Button
        className="my-4 bg-primary text-white primary-button poppins-600 w-100"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 30,
          height: 60,
        }}
        disabled={isSending}
        type="submit"
      >
        {isSending ? (
          <span>
            <Spinner size="sm" className="me-2" />
            Sending...
          </span>
        ) : (
          <span className="poppins">Send</span>
        )}
      </Button>
    </Form>
  );
};

export default GetInTouchFormSelect;
