import { confusedWhatToDo } from 'assets';
import React from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const NotSureWhatToDo = ({ expert = "Business Setup", targetRef }) => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <Container fluid className="bg-tertiary py-5 mb-5">
            <Container className="d-flex justify-content-center align-items-center">
                <Image src={confusedWhatToDo} alt="Confused what to do?" style={{ maxWidth: 250 }} />
                <div className="ms-5 d-flex flex-column justify-content-center">
                    <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
                        <b>Confused where to start from? Not sure which services to avail?</b>
                    </div>
                    <h3 className="my-2">Give us a call and talk to our {expert} Experts!</h3>
                    <div className="subtitle-400 text-secondary text-center mt-4">
                        <Button
                            className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"}`}
                            style={{
                                paddingLeft: 30,
                                paddingRight: 30,
                                borderRadius: 25,
                                height: 50,
                            }}
                            onClick={() => {
                                if (targetRef) {
                                    targetRef.current?.scrollIntoView({ behavior: "smooth" })
                                } else {
                                    navigate(`/get-in-touch`)
                                }
                            }}
                        >
                            Schedule a Call
                        </Button>
                    </div>
                </div>
            </Container>
        </Container>
    )
}

export default NotSureWhatToDo