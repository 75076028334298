import {
  branchesMap,
  branchesMapMobile,
  uk,
  uae,
  hungary,
} from "assets";
import React, { useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Breakpoint } from "react-socks";
import Flags from "country-flag-icons/react/3x2";
import { useSpring, animated } from "react-spring";
import ReactCardFlip from "react-card-flip";


const OurBranches = ({ backgroundColor = "bg-white" }) => {
  const { t, i18n } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const FlagIcon = ({ code }) => {
    const Flag = Flags[code];

    return (
      <Flag
        className="text-black"
        style={{ height: 40 }}
      />
    );
  };

  const branchesData = [
    {
      title: t("UAE") + " (H.O.)",
      image: uae,
      flag: <FlagIcon code="AE" />,
      address: t("Gold souq deira dubai, UAE"),
      number: "+971527396758",
      isHeadOffice: true,
    },
    {
      title: t("unitedKingdom"),
      image: uk,
      flag: <FlagIcon code="GB" />,
      address: t("1st Floor 124 Cleveland Street, London, UK"),
      number: "+447700305114",
      isHeadOffice: false,
    },
    {
      title: t("hungary"),
      image: hungary,
      flag: <FlagIcon code="HU" />,
      address: t("2073 Tök, Fő utca 1"),
      number: "+36202767431",
      isHeadOffice: false,
    },
  ];

  return (
    <>
      <div
        className={`py-5 user-select-none container-fluid ${backgroundColor}`}
        style={{ minHeight: "90vh" }}
      >
        <div className={`d-flex flex-column justify-content-center align-items-center ${large ? "container" : "container-fluid p-0"}`}>
          <div className="custom-header custom-blue-color poppins-600 mb-3">Our Networks</div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            {t("Say hello to our friendly team at one of these locations.")}
          </div>
          <div className="w-100 position-relative">
            <Image
              src={large ? branchesMap : branchesMapMobile}
              alt="Branches Map"
              className="img-fluid w-100"
            />
            <div
              className="d-flex justify-content-center w-100 py-5"
              style={{
                marginTop: large ? "-22vh" : -60,
                // backgroundColor: large ? "" : "#F2F5F7",
              }}
            >
              <Row className="w-100 container" xs={1} lg={3}>
                {branchesData.map((item, index) => (
                  <Col key={index} className="mb-4">
                    <BranchCard item={item} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* </Breakpoint> */}
    </>
  );
};

const BranchCard = ({ item }) => {
  const { t, i18n } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [hover, setHover] = useState(false);

  const props_desktop = useSpring({
    borderRadius: 24,
    opacity: 1,
    transform: hover ? "scale(1.1)" : "scale(1)",
    backgroundColor: "rgba(0, 0, 0, 0)",
  });

  return (
    <>
      <Breakpoint large up>
        <animated.div
          style={props_desktop}
        >
          <Card
            className="overflow-hidden"
            style={{
              background: "#F3F4FB",
              borderColor: "#8B8DDA",
              // height: large ? 260 : 200,
              borderRadius: 24,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {/* <div className="d-flex" style={{ height: large ? 260 : 200 }}> */}
            <div className="d-flex">
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <Card.Img
                  variant="center"
                  src={item.image}
                  alt={item.title}
                  className="user-select-none"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
              {/* {item.isHeadOffice && (
            <div
              className="d-flex flex-column jusity-content-center align-items-center position-absolute"
              style={{
                right: 0,
                width: 50,
                height: large ? 260 : 200,
                background: "#D8D9F3",
              }}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: 300,
                  width: large ? 260 : 200,
                  transform: "rotate(90deg)",
                }}
              >
                {t("headOffice")}
              </div>
            </div>
          )} */}
              {hover && (
                <div
                  className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                </div>
              )}
              {hover &&
                <div
                  className="position-absolute d-flex flex-column justify-content-center align-items-center"
                  style={{ top: "45%", right: item.isHeadOffice ? "30%" : "42%", zIndex: 99, borderRadius: "20px" }}
                >
                  <div className="mb-3">
                    {item.flag}
                  </div>
                  {item.isHeadOffice && <h4
                    className="d-flex justify-content-center align-items-center text-white"
                  >
                    {t("headOffice")}
                  </h4>}
                </div>
              }
            </div>
          </Card>
          <div className="mx-4">
            <h4 className="px-0 pt-2">{item.title}</h4>
            <div className="d-flex subtitle-400 text-secondary">
              <span>Phone:&nbsp;</span>
              <a
                href={`tel:${item.number}`}
                target="_blank"
                rel="noopener"
                className="d-flex align-items-center text-decoration-none"
              >
                {item.number}
              </a>
            </div>
            <div className="subtitle-400 text-secondary">
              Address: {item.address}
            </div>
          </div>
        </animated.div>
      </Breakpoint>
      <Breakpoint medium down>
        <ReactCardFlip isFlipped={hover} flipDirection="horizontal">
          <Card
            className="overflow-hidden"
            style={{
              background: "#F3F4FB",
              borderColor: "#8B8DDA",
              minHeight: 200,
              borderRadius: 24,
            }}
            onClick={() => setHover(true)}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Card.Img
                variant="center"
                src={item.image}
                alt={item.title}
                className="user-select-none"
                style={{
                  width: "100%",
                  minHeight: 200,
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="mx-4 position-absolute text-white" style={{ bottom: 0 }}>
              <h2 className="py-3">{item.title}</h2>
            </div>
          </Card>
          <Card
            className="overflow-hidden"
            style={{
              background: "#F3F4FB",
              borderColor: "#8B8DDA",
              minHeight: 200,
              borderRadius: 24,
            }}
            onClick={() => setHover(false)}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Card.Img
                variant="center"
                src={item.image}
                alt={item.title}
                className="user-select-none"
                style={{
                  width: "100%",
                  minHeight: 200,
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
            </div>
            <div className="mx-4 py-3 position-absolute text-white" style={{ zIndex: 99, bottom: 0 }}>
              <div className="pt-3">
                {item.flag}
              </div>
              <h4 className="px-0 pt-2">{item.title}</h4>
              <div className="d-flex subtitle-400">
                <span>Phone:&nbsp;</span>
                <a
                  href={`tel:${item.number}`}
                  target="_blank"
                  rel="noopener"
                  className="d-flex align-items-center text-decoration-none"
                >
                  <span style={{ color: "blue" }}>{item.number}</span>
                </a>
              </div>
              <div className="subtitle-400">
                Address: {item.address}
              </div>
            </div>
          </Card>
        </ReactCardFlip>
      </Breakpoint>
    </>
  );
};

export default OurBranches;
