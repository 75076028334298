import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import FAQSpringElement from "./FAQSpringElement";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { faq } from "assets";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useTransition } from "react-spring";
import { useTranslation } from "react-i18next";

const FAQ = ({ inputFaqs }) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [selectedFaq, setSelectedFaq] = useState(1);
  const [openClose, setOpenClose] = useState(false);

  const thisFaqs = [
    {
      id: 1,
      question: t("Is there a free trial available?"),
      answer: t("freeTrialAns"),
    },
    {
      id: 2,
      question: t("Can I change my plan later?"),
      answer: t("planChangeAns"),
    },
    {
      id: 3,
      question: t("What is your cancellation policy?"),
      answer: t("cancellationPolicyAns"),
    },
    {
      id: 4,
      question: t("Can other info be added to an invoice?"),
      answer: t("invoiceInfoAns"),
    },
    {
      id: 5,
      question: t("How does billing work?"),
      answer: t("billingQueryAns"),
    },
    {
      id: 6,
      question: t("How do I change my account email?"),
      answer: t("emailChangeAns"),
    },
  ];

  const [faqs, setFaqs] = useState(thisFaqs);

  useEffect(() => {
    if (inputFaqs) {
      setFaqs(inputFaqs)
    }
  }, [])

  return (
    <Container
      className="py-lg-5 d-flex justify-content-center"
      style={{ minHeight: "55vh" }}
    >
      <Row className="p-0 m-0">
        <Col md={5} className="d-flex flex-column justify-content-between">
          <div>
            <h1 className="h3 px-0 py-2">{t("FAQs")}</h1>
            <div>{t("FAQsDesc")}</div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <Image src={faq} alt="FAQ" />
              <div className="subtitle ms-2" style={{ fontWeight: 600 }}>
                {t("stillHaveQuestions")}
              </div>
            </div>
            <Button
              className="mt-3 bg-primary text-white primary-button border border-primary poppins-600"
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Col>
        <Col md={6} className="p-0 mx-lg-4 px-2">
          {faqs.map((faq) => {
            return (
              <div key={faq.id} className="py-3 mt-2">
                <div className="d-flex justify-content-between">
                  <div
                    className="subtitle p-0"
                    onClick={() => setSelectedFaq(faq.id)}
                  >
                    {faq.question}
                  </div>
                  {selectedFaq === faq.id ? (
                    <AiOutlineMinusCircle
                      size={25}
                      color="#98A2B3"
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedFaq(0);
                        setOpenClose(false);
                      }}
                    />
                  ) : (
                    <AiOutlinePlusCircle
                      size={25}
                      color="#98A2B3"
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedFaq(faq.id);
                        setOpenClose(true);
                      }}
                    />
                  )}
                </div>
                {selectedFaq === faq.id && (
                  <FAQSpringElement answer={faq.answer} openClose={openClose} />
                )}
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
