import { aim, mission, missionAndVisionIcon, vision } from "assets";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { t } from "i18next";

const MissionAndVision = () => {

  return (
    <Container fluid className="bg-white py-5">
      {/* <Container className="">
        <div className="pt-4">
          <Image src={missionAndVisionIcon} className="" width={64} />
        </div>
        <div className="py-4">
          <h1 className="h3 px-0 pb-2">{t("Our Mission & Vision")}</h1>
          <div className="mb-4">
            <div className="subtitle mb-2">{t("mission")}</div>
            <div className="font-size16 text-secondary">{t("missionText")}</div>
          </div>
          <div className="mb-4">
            <div className="subtitle mb-2">{t("vision")}</div>
            <div className="font-size16 text-secondary">{t("visionText")}</div>
          </div>
        </div>
      </Container> */}
      <Container>
        <Row>
          <Col lg={4} className="p-0 bg-tertiary d-flex flex-column justify-content-center">
            <h3 className="px-5 pt-lg-0 pt-5">Our Mission</h3>
            <p className="pt-lg-3 pb-lg-0 pb-3 px-5">{t("missionText")}</p>
          </Col>
          <Col lg={4} className="p-0">
            <div>
              <Image className="w-100" src={mission} alt="Mission Image" />
            </div>
          </Col>
          <Col lg={4} className="p-0 bg-tertiary d-flex flex-column justify-content-center">
            <h3 className="px-5 pt-lg-0 pt-5">Our Vision</h3>
            <p className="pt-lg-3 pb-lg-0 pb-3 px-5">{t("visionText")}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="p-0">
            <Image className="w-100" src={vision} alt="Vision Image" />
          </Col>
          <Col lg={4} className="p-0 bg-tertiary d-flex flex-column justify-content-center">
            <h3 className="px-5 pt-lg-0 pt-5">Our Aim</h3>
            <p className="pt-lg-3 pb-lg-0 pb-3 px-5">Our aim is to guide you through every step of the process, from initial planning to full operational launch, ensuring a smooth and successful start for your enterprise. We offer a wide range of services including company registration, legal compliance, financial planning, market research, and strategic consulting, all designed to help you achieve your business goals.</p>
          </Col>
          <Col lg={4} className="p-0">
            <Image className="w-100" src={aim} alt="Aim Image" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default MissionAndVision;
