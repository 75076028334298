import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import * as actions from "reducer/utilitySlice";

const OrderCard = ({ order }) => {
  const navigate = useNavigate();

  return (
    <div
      className="inquiry-card cursor-pointer"
      onClick={() => navigate(`${order.id}`)}
    >
      <p>
        {t("id")}: {order.id}
      </p>
    </div>
  );
};

const Order = () => {
  const { orderId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [userOrders, setUserOrders] = useState([]);

  const fetchAllUserOrders = async () => {
    const response = await actions.getOrders(user.id);
    if (response) {
      setUserOrders(response.results);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchAllUserOrders();
    }
  }, [user]);

  if (orderId) {
    return <Outlet />;
  }

  return (
    <Container fluid style={{ paddingTop: 100 }}>
      <h3>{t("allOrders")}</h3>
      <div className="inquiries-container">
        {userOrders.map((order, index) => (
          <OrderCard key={index} order={order} />
        ))}
      </div>
    </Container>
  );
};

export default Order;
