import React, { useRef } from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
    licensePhoto,
    proPhoto,
    taxPhoto,
    amlPhoto,
    visaPhoto,
    handshakeMp4,
    handshakeWebm,
} from "assets";
import { t } from "i18next";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const AllServices = () => {

    const large = useMediaQuery({ query: "(min-width: 992px)" });

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: large ? 3 : 1,
        slidesToScroll: 1
    };

    const serviceDetails = [
        {
            id: 1,
            title: t("licenseServices"),
            image: licensePhoto,
            link: "services/license-service",
        },
        {
            id: 2,
            title: t("proServices"),
            image: proPhoto,
            link: "services/pro-service",
        },
        {
            id: 3,
            title: t("amlRegistration"),
            image: amlPhoto,
            link: "services/aml-registration",
        },
        {
            id: 4,
            title: t("visaServices"),
            image: visaPhoto,
            link: "services/visa-service",
        },
        {
            id: 5,
            title: t("vatRegistrationAndCorporateTaxRegistration"),
            image: taxPhoto,
            link: "services/vat-corporate-tax-registration",
        },
    ];

    return (
        <Container
            fluid
            className="py-5 user-select-none"
            style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
        >
            <div className="custom-header custom-blue-color mb-3 text-center">{t("whatWeOffer")}</div>
            <div className="subtitle-400 text-secondary text-center mb-5">
                {t("Your needs, our expertise. Find the perfect solution.")}
            </div>
            <Row>
                {large && <Col lg={3} className="ps-3">
                    <Card className="h-100 d-flex justify-content-center align-items-center bg-tertiary" style={{ borderRadius: 20 }}>
                        {/* <div className="custom-header poppins-600 mb-3 mt-5 text-primary">Wide varities of Services offered</div> */}
                        <video autoPlay loop muted style={{ width: "50%" }}>
                            <source src={handshakeWebm} type="video/webm" />
                            <source src={handshakeMp4} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <h3 className="poppins-600 mb-3 mt-5 text-primary">Services offered</h3>
                    </Card>
                </Col>}
                <Col lg={large ? 9 : 12}>
                    <Row>
                        <Slider ref={slider => { sliderRef = slider; }} {...settings}>
                            {serviceDetails.map((item, i) => (
                                <Col className="pe-3" lg={4}>
                                    <a href={item.link}>
                                        <Card style={{ borderRadius: 20 }}>
                                            <Image src={item.image} alt={item.title} style={{ borderRadius: 20 }} />
                                            <div
                                                className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                                                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: 20 }}
                                            >
                                            </div>
                                            <div className="mx-4 position-absolute text-white" style={{ bottom: 0 }}>
                                                <h3 className="py-3">{item.title}</h3>
                                            </div>
                                        </Card>
                                    </a>
                                </Col>
                            ))}
                        </Slider>
                    </Row>
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                <div
                    className="d-flex text-primary my-2 my-lg-4 cursor-pointer bg-tertiary border border-primary"
                    style={{
                        textDecoration: "none",
                        padding: "12px",
                        backgroundColor: "#1B1E29",
                        borderRadius: "25px",
                    }}
                    onClick={previous}
                >
                    <IoIosArrowBack size={20} />
                </div>
                <div
                    className="d-flex text-primary ms-3 my-2 my-lg-4 cursor-pointer bg-tertiary border border-primary"
                    style={{
                        textDecoration: "none",
                        padding: "12px",
                        backgroundColor: "#1B1E29",
                        borderRadius: "25px",
                    }}
                    onClick={next}
                >
                    <IoIosArrowForward size={20} />
                </div>
            </div>
        </Container>
    )

}

export default AllServices