import React, { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as authSlices from "reducer/authSlices";
import ResetPassword from "./ResetPassword";
import { notify } from "utilities/notifications/Notify";
import { t } from "i18next";

const ForgotPassword = ({ setShowForm }) => {
  const form = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);
    setEmail(formData.get("email"));
    if (formData.get("email")) {
      const response = await authSlices.sendResetOtp({
        email: formData.get("email"),
      });
      if (response) {
        switch (response.status) {
          case "SUCCESSFUL":
            notify(response.msg, "success");
            setIsSent(true);
            break;
          default:
            notify(t("errorWhileSendingOtp"), "error");
        }
        form.current.reset();
      }
    } else {
      notify(t("pleaseEnterYourEmail"), "error");
    }
    setIsSubmitting(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);

    if (formData.get("password1") === formData.get("password2")) {
      const response = await authSlices.reset_password({
        password: formData.get("password1"),
        email: email,
        otp: formData.get("otp"),
      });
      if (response) {
        form.current.reset();
        switch (response.status) {
          case "LINK_EXPIRED":
            notify(response.msg, "error");
            setIsSent(false);
            break;
          case "INCORRECT_OTP":
            notify(response.msg, "error");
            break;
          case "DOES_NOT_EXIST":
            notify(response.msg, "error");
            setIsSent(false);
            setShowForm("login");
            break;
          case "RESET_SUCCESSFUL":
            notify(response.msg, "success");
            setIsSent(false);
            setShowForm("login");
            break;
          default:
            notify(t("errorWhileSendingOtp"), "error");
        }
      }
    } else {
      notify(t("passwordsDidNotMatch"));
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className="p-5 d-flex flex-column justify-content-center"
      style={{ height: 700 }}
    >
      {isSent ? (
        <ResetPassword
          form={form}
          isSubmitting={isSubmitting}
          handleResetPassword={handleResetPassword}
        />
      ) : (
        <>
          <h3 className="pe-3">{t("forgotPassword")}</h3>
          <div className="text-secondary my-3">
            {t("No worries, we'll send you reset instructions.")}
          </div>
          <Form
            className="text-start w-100"
            ref={form}
            onSubmit={(e) => handleSendOtp(e)}
          >
            <Form.Group className="mt-2">
              <Form.Label className="body-text1">{t("email")}</Form.Label>
              <Form.Control
                name="email"
                placeholder="name@example.com"
                type="email"
                className="bg-white ps-4"
                required
                style={{
                  height: 60,
                  borderRadius: 30,
                  border: "1px solid #D0D5DD",
                }}
              />
            </Form.Group>
            <Button
              className="bg-primary text-white primary-button poppins-600 w-100 my-4"
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 30,
                height: 60,
              }}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span>
                  <Spinner size="sm" className="me-2" />
                  {`${t("sending")}...`}
                </span>
              ) : (
                <span className="poppins">{t("sendOtp")}</span>
              )}
            </Button>
          </Form>
        </>
      )}
      <div className="d-flex justify-content-center align-items-center">
        <p className="text-lightgrey me-2">{t("dontHaveAnAccount")}</p>
        <div onClick={() => setShowForm("signup")} className="cursor-pointer">
          <p className="text-primary">
            <b>{t("signUp")}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
