import React from "react";
import { Container, Image } from "react-bootstrap";
import { roadmap } from "assets";
import { useMediaQuery } from "react-responsive";

const RoadMap = () => {

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container fluid className="py-5 bg-tertiary">
      <div className={`custom-header poppins-600 pt-3 pb-2 text-center text-black`}>Company Milestones</div>
      <div className="d-flex flex-column justify-content-center align-items-center pb-4">
        <Image src={roadmap} alt="Roadmap" style={large ? { minWidth: 1200 } : { width: "100%" }} />
      </div>
    </Container >
  );
};

export default RoadMap;
