import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, PlanCard } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import {
  reason_1,
  reason_2,
  reason_3,
  reason_4,
} from "assets";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";

const VatCorpTaxRegistration = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const topReasons = [
    {
      id: 1,
      icon: reason_1,
      title: t("strategicLocation"),
      description: t(t("strategicLocationDesc")),
    },
    {
      id: 2,
      icon: reason_2,
      title: t("stableEconomy"),
      description: t("stableEconomyDesc"),
    },
    {
      id: 3,
      icon: reason_3,
      title: t("taxBenefits"),
      description: t("taxBenefitsDesc"),
    },
    {
      id: 4,
      icon: reason_4,
      title: t("globalConnectivity"),
      description: t("globalConnectivityDesc"),
    },
    {
      id: 5,
      icon: reason_1,
      title: t("qualityOfLife"),
      description: t("qualityOfLifeDesc"),
    },
    {
      id: 6,
      icon: reason_2,
      title: t("culturalDiversity"),
      description: t("culturalDiversityDesc"),
    },
    {
      id: 7,
      icon: reason_3,
      title: t("legalFramework"),
      description: t("legalFrameworkDesc"),
    },
    {
      id: 8,
      icon: reason_4,
      title: t("fullOwnership"),
      description: t("fullOwnershipDesc"),
    },
  ];

  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">
            {t("vatRegistrationAndCorporateTaxRegistration")}
          </div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="mt-5 pt-5">
        {/* <div className="mb-4 font-size32 poppins-500">{t("vatRegAndCTR")}</div> */}
        <div className="mb-2"> {t("vatRegAndCTRPara1")}</div>
        <br />
        <div className="mb-2"> {t("vatRegAndCTRPara2")}</div>
        <br />
        <div className="subtitle mb-2 text-bold mb-4">
          {t("typesOfSchengenVisa")}
        </div>
        <div className="mb-2">{`1. ${t("vatRegAndCTRPoint1")}`}</div>
        <div className="mb-2">{`2. ${t("vatRegAndCTRPoint2")}`}</div>
        <div className="mb-2">{`3. ${t("vatRegAndCTRPoint3")}`}</div>
        <div className="mb-2">{`4. ${t("vatRegAndCTRPoint4")}`}</div>
        <div className="mb-2">{`5. ${t("vatRegAndCTRPoint5")}`}</div>
        <br />
        <div className="mb-2"> {t("vatRegAndCTRPara3")}</div>
        <br />
        <div className="mb-2"> {t("vatRegAndCTRPara4")}</div>
        <br />
      </Container>
      <Container>
        <div className={`mt-5 ${large ? "w-75" : ""}`}>
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("whyToChooseUAE")}</b>
          </div>
          <div className="page-header my-2">
            {t("Top Reasons to Choose the UAE for Your Business")}
          </div>
          <div className="subtitle-400 text-secondary mb-5">
            {t("whyToChooseDesc")}
          </div>
        </div>
        <Row className={`mb-5 pb-5 ${large ? "row-cols-4" : "row-cols-1"}`}>
          {topReasons.map((reason) => {
            return (
              <Col className="mb-4">
                <Card
                  className="p-4 d-flex justify-content-between border-0"
                  style={{ height: 280, backgroundColor: "#F9FAFB" }}
                >
                  <div
                    className="bg-primary d-flex justify-content-center align-items-center"
                    style={{ width: 48, height: 48, borderRadius: 10 }}
                  >
                    <Image src={reason.icon} alt={reason.title} />
                  </div>
                  <div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div className="font-size16 text-secondary">
                      {reason.description}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className="p-0 d-flex flex-column justify-content-center align-items-center mb-5 pb-5">
        <div>
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("documents")}</b>
          </div>
          <h3 className="my-2">{t("processDocuments")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("letsStartWithDocumentation")}
          </div>
        </div>
        {plans.map((plan) => {
          return (
            <PlanCard
              plan={plan}
              reqDocumentTypes={reqDocumentTypes}
              serviceSlug={param2}
              createSession={createSession}
            />
          );
        })}
      </Container>
      <BoostBusiness />
      <FAQ />
      <Subscribe />
    </>
  );
};

export default VatCorpTaxRegistration;
