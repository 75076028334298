import React from "react";
import { Image, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { testimonialProfilePicture } from "assets";
import moment from "moment";
import { MdOutlineArrowOutward } from "react-icons/md";
import { t } from "i18next";

const BlogCard = ({ blog }) => {
  const navigate = useNavigate();

  return (
    <Card
      className="mb-4 blog-card cursor-pointer border-0 d-flex justify-content-between"
      style={{ borderRadius: 16, minHeight: 750 }}
      onClick={() => navigate(`/blogs/${blog?.slug}`)}
    >
      <div>
        <Card
          className="border-0 m-4 overflow-hidden d-flex justify-content-center align-items-center"
          dark={true}
          outlined
          style={{
            height: 320,
            border: "1px solid #3D3D3D",
          }}
        >
          <Image
            style={{ borderRadius: "8px" }}
            className="position-absolute mx-auto h-100"
            src={blog?.blog_image ? blog?.blog_image : null}
            alt={blog?.title}
          />
        </Card>
        <div className="px-4">
          <div className="text-primary mb-3">
            <b>{t("design")}</b>
          </div>
          <div className="d-flex justify-content-between">
            <h4 className="">{blog?.title}</h4>
            <span>
              <MdOutlineArrowOutward size={25} />
            </span>
          </div>
          <div className="mb-4 text-secondary">{blog?.description}</div>
        </div>
      </div>
      <div className="d-flex align-items-center p-4">
        <Image src={testimonialProfilePicture} alt="Testimonial Profile Picture" />
        <div className="ms-3 d-flex flex-column">
          <b>{blog?.author_name}</b>
          <span>{moment(blog?.published_date).format("DD MMM YYYY")}</span>
        </div>
      </div>
    </Card>
  );
};

export default BlogCard;
