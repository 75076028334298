import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import {
  Subscribe,
  BoostBusiness,
  FAQ,
  ChatWithProfessionals,
  PlanCard,
  GetInTouchFormSelect,
  NotSureWhatToDo,
} from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import {
  creativeInnovationNetwork,
  internationalBusinessCenter,
  resilientBusinessClimate,
  talentRichWorkforce,
  zap,
  reservetradename,
  llcdubai,
  professionallicense,
  branchoffice,
  soleproprietorship,
  corporatebankaccount,
  teamwithreports,
  taxPhoto,
  licensePhoto,
  compliance,
  businessInsurance,
  lowestCorporateTax,
  euMembership,
  costEffective,
  advancedInfrastructure,
  governmentSupport,
  aim,
  mission,
} from "assets";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const HungaryBusinessSetup = () => {
  const { t } = useTranslation();
  // const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const targetRef = useRef(null);

  const topReasons = [
    {
      id: 1,
      icon: lowestCorporateTax,
      title: "Lowest Corporate Tax in Europe",
      description: "Hungary offers a 9% corporate tax rate, the lowest in the European Union.",
    },
    {
      id: 2,
      icon: internationalBusinessCenter,
      title: "Strategic Location",
      description: "Situated in Central Europe, Hungary provides easy access to major EU markets and global trade routes.",
    },
    {
      id: 3,
      icon: talentRichWorkforce,
      title: "Highly Skilled Workforce",
      description: "The country boasts a well-educated and multilingual labor force.",
    },
    {
      id: 4,
      icon: resilientBusinessClimate,
      title: "Favorable Investment Climate",
      description: "Hungary provides various incentives, including grants and tax relief for foreign investors.",
    },
    {
      id: 5,
      icon: euMembership,
      title: "EU Membership Benefits",
      description: "As an EU member, businesses in Hungary have access to the entire European market.",
    },
    {
      id: 6,
      icon: costEffective,
      title: "Cost-Effective Operations",
      description: "Low costs for living, services, and operations compared to other Western European countries.",
    },
    {
      id: 7,
      icon: advancedInfrastructure,
      title: "Advanced Infrastructure",
      description: "Budapest offers modern infrastructure, including strong transport links and high-speed internet.",
    },
    {
      id: 8,
      icon: governmentSupport,
      title: "Government Support for Businesses",
      description: "Various government programs and incentives are available to support both local and foreign enterprises.",
    },
  ];

  const businessStructure = [
    { id: 1, name: "Sole Proprietorship", image: soleproprietorship },
    { id: 2, name: "Partnership (Kkt & Bt)", image: professionallicense },
    { id: 3, name: "Private Limited Company (Kft)", image: llcdubai },
    { id: 4, name: "Public Limited Company (Rt)", image: branchoffice }
  ]

  const pathways = [
    {
      id: 1,
      title: "Choosing a Business Structure",
      description: <>
        <ul>
          <li>Sole Proprietorship</li>
          <li>Partnerships (Kkt & Bt)</li>
          <li>Private Limited Company (Kft)</li>
          <li>Public Limited Company (Rt)</li>
          <li>Branch Office/Representative Office</li>
        </ul>
      </>,
      image: (
        <Row className="row-cols-2">
          {businessStructure.map((businessActivity) => {
            return (
              <Col className="mb-4">
                <Card
                  className="p-4 d-flex justify-content-center align-items-center border-0"
                  style={{
                    height: 280,
                    backgroundColor: "#eff0fa",
                    borderRadius: 20,
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#ffffff",
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                    }}
                  >
                    <Image src={businessActivity.image} alt={businessActivity.name} className="animated-image" style={{ height: 100 }} />
                  </div>
                  <div className="subtitle text-center mt-4">{businessActivity.name}</div>
                </Card>
              </Col>
            )
          })}
        </Row>
      ),
    },
    {
      id: 2,
      title: "Company Registration",
      description: <>
        <ul>
          <li>Register with the Court of Registration</li>
          <li>Company Name</li>
          <li>Legal Documentation</li>
        </ul>
      </>,
      image: (
        <Image
          className="w-100"
          src={reservetradename}
          alt="Company Registration"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 3,
      title: "Tax Registration",
      description: <>
        <ul>
          <li>VAT (Value-Added Tax)</li>
          <li>Corporate Tax</li>
          <li>Tax Number</li>
        </ul>
      </>,
      image: (
        <Image
          className="w-100"
          src={taxPhoto}
          alt="Tax Registration"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 4,
      title: "Opening a Business Bank Account",
      description: "Once your company is registered, you’ll need to open a business bank account in Hungary. This will be used to manage the company’s finances and meet financial obligations.",
      image: (
        <Image
          className="w-100"
          src={corporatebankaccount}
          alt="Corporate Bank Account"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 5,
      title: "Obtaining Licenses and Permits",
      description: "Depending on the nature of your business, you may require additional licenses or permits. For example, specific permits are necessary for businesses involved in construction, food services, or healthcare.",
      image: (
        <Image className="w-100" src={licensePhoto} alt="Licenses and Permits" style={{ borderRadius: 20 }} />
      ),
    },
    {
      id: 6,
      title: "Compliance with Employment Laws",
      description: "If you plan to hire employees, you must comply with Hungarian labor laws, which include providing a written employment contract, adhering to working hours regulations, and offering mandatory benefits like social security and pensions.",
      image: (
        <Image
          className="w-100"
          src={compliance}
          alt="Compliance"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 7,
      title: "Accounting and Bookkeeping",
      description: "Hungarian law requires businesses to maintain accurate and up-to-date accounting records. Companies must prepare annual financial statements and submit them to the tax authorities. Outsourcing bookkeeping and accounting services can ensure compliance with local regulations.",
      image: (
        <Image
          className="w-100"
          src={teamwithreports}
          alt="Accounting and Bookkeeping"
          style={{ borderRadius: 20 }}
        />
      ),
    },
    {
      id: 8,
      title: "Incentives and Support",
      description: "The Hungarian government offers various incentives for foreign investors, including grants, tax benefits, and support for research and development projects. Hungary’s Investment Promotion Agency (HIPA) can assist in accessing these incentives.",
      image: (
        <Image
          className="w-100"
          src={mission}
          alt="Government Incentives and Support"
          style={{ borderRadius: 20 }}
        />
      ),
    },
  ];

  const faqs = [
    {
      id: 1,
      question: "Why to choose a physical office?",
      answer: "It provides a space where you can work independently or meet with clients. Additionally, this can support the process of opening a Hungarian bank account by demonstrating to the bank your commitment to running a legitimate business in the local market."
    },
    {
      id: 2,
      question: "What is the 'Client Gate' in Hungary, and why is it important?",
      answer: "The Client Gate (or 'Ügyfélkapu' in Hungarian) is an entry point to Hungary's e-Government system, allowing you to manage administrative tasks online and schedule appointments at public offices. It's essential for company registration, as it enables you to register your Hungarian business at the Company Gate through this platform."
    },
    {
      id: 3,
      question: "What is the 'Company Gate' in Hungary, and why is it important?",
      answer: "The Company Gate (or 'Cégkapu in Hungarian) is a web portal that provides a secure electronic storage space where official correspondence is always accessible. It functions similarly to your company’s registered address, and registration is mandatory. Currently, only the tax authority sends messages through this portal, but other authorities will be included in the future. If you use Helpers for your accounting, we can handle the registration for you, as your accountant can be authorized to access official mail, particularly from the tax authority."
    },
    {
      id: 4,
      question: "When and how will my company receive an EU VAT number?",
      answer: "Upon registration, your company will automatically receive both an EU VAT number and a local tax number—no additional paperwork required! Every Hungarian company is eligible, so there's no need to apply. You can begin trading with your VAT number as soon as your company is registered."
    },
  ]

  const [index, set] = useState(0);
  const [currentPathway, setCurrentPathway] = useState(0);
  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param1);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param1);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param1);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param1}/plan`);
    }
  };

  useEffect(() => {
    if (param1) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param1]);

  useEffect(() => {
    let timer = setTimeout(() => {
      set((state) => (state + 1) % 8);
      setCurrentPathway((state) => (state + 1) % 8);
    }, 5000);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("businessSetup")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            Make setting up your company in the Hungary quick and hassle-free with SKV Business Services.
          </div>
          <div
            ref={targetRef}
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={7}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 80,
                height: 80,
                border: "12px solid #F9F5FF",
                borderRadius: 40,
                backgroundColor: "#F4EBFF",
              }}
            >
              <Image src={zap} alt="zap" style={{ width: 40, height: 40 }} />
            </div>
            <h4 className="my-3">
              Business Setup in Budapest, Hungary
            </h4>
            <div className="subtitle-400 text-secondary mb-3">
              Hungary boasts the lowest corporate income tax rate in Europe at just 9%,
              making it an ideal destination for starting a business within the European Union.
              Beyond its tax advantages, Hungary is also one of the safest countries on the
              continent. Its capital, Budapest, is not only stunning but also highly livable,
              offering a low cost of living combined with high-quality services.
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              At SKV Business, we offer comprehensive solutions to support your business journey.
              From the moment you decide to establish a company, we assist with strategic planning,
              tax structuring, and provide essential services such as bookkeeping, virtual office
              support, and interim managing director services.
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Container className="mb-5 pb-5">
          <div className="mt-5 pt-5 w-75">
            <div className="mt-5 text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>Hungary Business Setup</b>
            </div>
            <h3 className="my-2">Why the UK is Ideal for Business Expansion?</h3>
            <div className="subtitle-400 text-secondary mb-5">
              The UK offers numerous compelling reasons for being the top choice for
              business expansion. With its strong economy and excellent global
              connectivity, investors are drawn to the unique advantages it provides.
            </div>
          </div>
          <Row className="row-cols-2">
            {topReasons.map((reason, index) => {
              return (
                <Col className="mb-5">
                  <Card
                    className="p-4 d-flex justify-content-between align-items-center border-0"
                    style={{ height: 280 }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: 140,
                        height: 140,
                        border: "10px solid #F9F5FF",
                        borderRadius: 70,
                        backgroundColor: "#F4EBFF",
                      }}
                    >
                      <Image
                        src={reason.icon}
                        alt={reason.title}
                        style={index === 0 ? { width: 70, height: 70 } : { width: 80, height: 80 }}
                      />
                    </div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div>
                      <div className="font-size16 text-secondary">
                        {reason.description}
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <Container className="my-5 py-5">
        <div className="w-75">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("stepsAndRequirements")}</b>
          </div>
          <h3 className="my-2">{t("yourPathwayToBusinessSuccess")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("exploreStepsAndRequirementsText")}
          </div>
        </div>
        <Row className="row-cols-4">
          <Col sm={6} className="">
            {pathways.map((pathway, index) => {
              return (
                <>
                  <div
                    className="p-3 mb-3"
                    style={{
                      borderLeft:
                        currentPathway === index
                          ? "4px solid #2E3192"
                          : "4px solid #F2F4F7",
                    }}
                    onClick={() => setCurrentPathway(index)}
                  >
                    <div className="subtitle mb-2">{pathway.title}</div>
                    <div className="font-size16 text-secondary">
                      {pathway.description}
                    </div>
                  </div>
                  {currentPathway === index && !large && pathways[currentPathway].image}
                </>
              );
            })}
          </Col>
          {large && <Col sm={6}>
            <div className="sticky">
              {pathways[currentPathway].image}
            </div>
          </Col>}
        </Row>
      </Container>
      <NotSureWhatToDo expert="Hungary Business Setup" targetRef={targetRef} />
      <ChatWithProfessionals />
      <FAQ inputFaqs={faqs} />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default HungaryBusinessSetup;