import { Subscribe } from "components";
import React from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as authSlices from "reducer/authSlices";
import BillingAndInvoicing from "./BillingAndInvoicing";
import { useTranslation } from "react-i18next";

const Billing = () => {
  const { t, i18n } = useTranslation();

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const user = useSelector((state) => state.auth.user);
  return (
    <>
      <Container className="mt-4 mb-4 mb-lg-5">
        <div
          className="pb-3 mb-3"
          style={{ borderBottom: "2px solid #EAECF0" }}
        >
          <div className="font-size32 poppins-500">{t("billing")}</div>
          <div>{t("Manage your billing and payment details.")}</div>
        </div>

        <Card
          className="border-primary overflow-hidden my-4 my-lg-5"
          style={{
            background: "#F3F4FB",
            borderColor: "#8B8DDA",
            minHeight: 200,
            borderRadius: 24,
          }}
        >
          {/* <Row className="p-3" style={{ height: 180 }}>
          <Col lg={9} className="">
            <div className="subtitle" style={{ fontWeight: 600 }}>
              Pending Amount
            </div>
            <div>Document will be shared only after remaining payment done</div>
          </Col>
          <Col
            lg={3}
            className="user-select-none page-header p-0 mb-2"
            style={{ fontWeight: 700 }}
          >
            2,000+
          </Col>
        </Row> */}

          <Card.Body
            className="border-primary"
            style={{ background: "#F9F5FF", borderBottom: "1px solid" }}
          >
            <Row
              className="d-flex algin-items-center p-3"
              style={{ height: 150 }}
            >
              <Col
                lg={9}
                className="d-flex flex-column justify-content-center align-items-start"
              >
                <Card.Title>{t("pendingAmount")}</Card.Title>
                <Card.Text>
                  {t(
                    "Document will be shared only after remaining payment done"
                  )}
                </Card.Text>
              </Col>
              <Col
                lg={3}
                className="user-select-none page-header p-0 mb-2 d-flex flex-column justify-content-center align-items-end p-4"
                style={{ fontWeight: 700 }}
              >
                2,000+
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="bg-white text-primary d-flex px-4 py-3">
            <Card.Title className="ms-2">{t("payRemainingAmount")}</Card.Title>
          </Card.Footer>
        </Card>
      </Container>
      <BillingAndInvoicing />
    </>
  );
};

export default Billing;
