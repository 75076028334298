import React, { useEffect, useState } from "react";
import { Button, Container, Image, Row, Col, Card, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useTranslation } from "react-i18next";

import {
  BoostBusiness,
  BusinessSetupService,
  AllServices,
  SetUsApart,
  Subscribe,
  WhyChooseUs,
  OurNetworks,
} from "components";

import { BiSolidStar } from "react-icons/bi";

import { newSkvWhiteLogo, testimonialProfilePicture, clientTestimonial, homeHeroBackgroundWebm, homeHeroBackgroundMp4 } from "assets";
import { Breakpoint } from "react-socks";
import { useMediaQuery } from "react-responsive";


const RatingStars = ({ rating }) => {
  const { t, i18n } = useTranslation();

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<BiSolidStar color="#ffe55b" size={25} />);
    }
    return stars;
  };

  return <div>{renderStars()}</div>;
};

const Home = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const { t, i18n } = useTranslation();

  const testimonials = [
    {
      id: 0,
      image: testimonialProfilePicture,
      name: "John Doe",
      position: "CEO, Company XYZ",
      review: "SKV Buiness made setting up our business in the UAE incredibly easy. Their team handled all the paperwork and legalities efficiently, and their expertise was evident throughout the process. Highly recommend their services!",
      rating: 5,
    },
    {
      id: 1,
      image: testimonialProfilePicture,
      name: "John Doe",
      position: "CEO, Company XYZ",
      review: "Fantastic service from SKV Buiness! They guided us through every step of establishing our business in the UAE, making the whole process smooth and stress-free. Five stars for their professionalism and support!",
      rating: 5,
    },
  ];

  const fetchBlogs = async () => {
    const response = await actions.getBlogs(`/blog/rest/blogs/`);
    if (response) {
      setBlogs(response.results);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <Container fluid className="background-video-container">
        <Container
          className={`d-flex flex-column ${large
            ? "justify-content-center align-items-center"
            : "align-items-start mt-5 pt-5"
            }`}
          style={{ minHeight: "100vh" }}
        >
          <video autoPlay loop muted className="background-video">
            <source src={homeHeroBackgroundWebm} type="video/webm" />
            <source src={homeHeroBackgroundMp4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div>
            <Button
              className="d-flex justify-content-center align-items-center text-primary px-1 py-0 mt-lg-0 mt-4"
              style={{ ...styles.milestoneButton, height: 32 }}
            >
              <Button
                className="d-flex justify-content-center align-items-center text-primary py-0"
                style={{ ...styles.milestoneButton, height: 24 }}
              >
                <b>{t("milestones")}</b>
              </Button>
              <span className="mx-2">
                <b>{t("42+ Countries served")}</b>&nbsp;
                <FaArrowRight />
              </span>
            </Button>
          </div> */}
          <div className="text-center w-100">
            <Image
              className={`animated-skv-logo ${large ? "mt-4" : "mt-5"}`}
              src={newSkvWhiteLogo}
              alt="SKV Logo"
              style={large ? { maxHeight: 350 } : { maxHeight: 250 }}
            />
          </div>
          <div
            className={`home-heading text-white mt-2 ${large ? "text-center" : ""
              }`}
            style={large ? { paddingLeft: 150, paddingRight: 150 } : {}}
          >
            <span className="poppins-600">{t("businessSetup")}</span>
            <span className="poppins-200"> {t("inTheHeartof")} </span>
            <span className="poppins-600"> {t("dubai")}</span>
          </div>
          <div
            className={`subtitle-400 text-white ${large ? "text-center mb-3" : "mb-5"
              }`}
            style={large ? { paddingLeft: 235, paddingRight: 235 } : {}}
          >
            {t("Empowering Your Business Formation")}
          </div>
          <div className="text-center w-100">
            <Button
              style={styles.exploreButton}
              onClick={() => navigate(`/services`)}
            >
              <span className="poppins-600">{t("exploreOurServices")}</span>
            </Button>
          </div>
        </Container>
      </Container>

      <BusinessSetupService />

      <SetUsApart />

      <AllServices />

      <Container fluid className="bg-white">
        <OurNetworks backgroundColor="bg-white" />
      </Container>

      <Container fluid className="py-5" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}>
        <Container className="d-flex flex-column justify-content-center align-items-center user-select-none">
          <div className="custom-header custom-blue-color mb-3">Hear From Our Clients</div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            {t("whyChooseUsDesc")}
          </div>
          <Row className="d-flex justify-content-center align-items-center mb-4">
            <Col lg={6}>
              <Carousel controls={false} indicators={false}>
                {testimonials.map((testimonial, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <Card
                        className="p-3 d-flex justify-content-between align-items-start border-0"
                        style={{ height: large ? 300 : "100%", background: "transparent" }}
                      >
                        <RatingStars rating={testimonial.rating} />
                        <div className="font-size18 my-3">{testimonial.review}</div>
                        <div className="d-flex align-items-center">
                          <Image
                            src={testimonial.image}
                            alt={testimonial.name}
                            style={{ width: 56, height: 56 }}
                          />
                          <div className="ms-3 d-flex flex-column">
                            <b>{testimonial.name}</b>
                            <span>{testimonial.position}</span>
                          </div>
                        </div>
                      </Card>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col lg={6}>
              <div
                className="overflow-hidden d-flex justify-content-center align-items-center"
                style={{ borderRadius: 20, width: "100%", height: 300 }}
              >
                <Image
                  className="w-100"
                  src={clientTestimonial}
                  alt="Client Testimonial"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* <WhyChooseUs /> */}

      <Breakpoint large up>
        <Container fluid className="d-flex flex-column align-items-center px-lg-5 py-5">
          <div className="custom-header custom-blue-color text-primary mb-3">{t("latestBlogs")}</div>
          <div className="subtitle-400 text-secondary text-center mb-5">
            {t("Interviews, tips, guides, industry best practices, and news.")}
          </div>
          <Container>
            <Row className="d-flex row-cols-2">
              {blogs.slice(0, 4).map((blog) => {
                return (
                  <Col>
                    <Card
                      className="overflow-hidden d-flex justify-content-center align-items-center p-0 m-0"
                      style={{ borderRadius: "20px", background: "#eff0fa", height: 400 }}
                    >
                      <a className="w-100 p-0" href={`/blogs/${blog.slug}`}>
                        <Image
                          className="w-100"
                          src={blog.blog_image}
                          alt={blog.title}
                          style={{ minHeight: 400, width: "auto" }}
                        />
                      </a>
                    </Card>
                    <h4 className="my-3">{blog.title}</h4>
                    <div className="mb-5">{blog.description}</div>
                  </Col>
                );
              })}
            </Row>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className="bg-tertiary text-primary primary-button border border-primary"
                onClick={() => {
                  navigate(`/blogs`);
                }}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 30,
                  height: 60,
                }}
              >
                {t("viewAllBlogs")}
              </Button>
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

const styles = {
  milestoneButton: {
    backgroundColor: "white",
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 16,
    border: "2px solid",
  },
  exploreButton: {
    paddingLeft: 45,
    paddingRight: 45,
    borderRadius: 55,
    height: 70,
  },
};

export default Home;
