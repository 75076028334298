import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import {
  winningPartnership,
  secureConfidential,
  fiveStarSupport,
} from "assets";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";

const WhyChooseUs = ({ color = "#F5EBFF" }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const whyChooseUs = [
    {
      id: 1,
      title: t("winningPartnerships"),
      description: t("winningPartnershipsText"),
      image: winningPartnership,
    },
    {
      id: 2,
      title: t("secureAndConfidential"),
      description: t("secureAndConfidentialText"),
      image: secureConfidential,
    },
    {
      id: 3,
      title: t("fiveStarSupport"),
      description: t("fiveStarSupportText"),
      image: fiveStarSupport,
    },
  ];

  return (
    <Container
      className={`${large ? "d-flex flex-column align-items-center px-lg-5 mb-5" : ""
        } py-5`}
      fluid
      style={{ backgroundColor: color, borderRadius: large ? 48 : 20 }}
    >
      <div className="custom-header mb-3">{t("whyChooseUs")}</div>
      <div
        className={`subtitle-400 text-secondary mb-5 ${large ? "text-center w-50" : ""
          }`}
      >
        {t("whyChooseUsDesc")}
      </div>
      <Container>
        <Row
          //   lg={3}
          //   md={2}
          //   sm={1}
          className={`${large ? "row-cols-3" : "row-cols-1"}`}
        >
          {whyChooseUs.map((item) => {
            return (
              <Col>
                <Card
                  className={`d-flex justify-content-center align-items-center ${large ? "border-0 p-5" : "p-3 my-3"
                    }`}
                  style={
                    large
                      ? { borderRadius: 32, minHeight: 400 }
                      : {
                        borderRadius: 32,
                        minHeight: 300,
                        border: "1px solid #2E3192",
                      }
                  }
                >
                  <Image src={item.image} alt={item.title} style={{ width: 96 }} />
                  <h4>{item.title}</h4>
                  <div className="text-center">{item.description}</div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
};

export default WhyChooseUs;
