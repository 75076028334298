import { paperPlane } from "assets";
import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Breakpoint } from "react-socks";

const Subscribe = () => {
  const { t, i18n } = useTranslation();

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container
      className="position-relative py-5"
      style={{ minHeight: large ? "30vh" : "20vh" }}
    >
      <div
        className="py-5"
        style={{
          minHeight: "20vh",
          backgroundColor: "#F2F5F7",
          borderRadius: 16,
        }}
      >
        <Row className="p-0 m-0">
          <Col
            sm={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="font-size32 poppins-500">
              {`${t("join")} 2,000+ ${t("subscribers")}`}
            </div>
            <div className="subtitle-400 text-secondary mt-2">
              {t("Stay in the loop with everything you need to know.")}
            </div>
          </Col>
          <Col sm={6} className="p-0">
            <div className="d-flex">
              <Form.Control
                name="subscribe"
                placeholder={t("enterYourEmail")}
                type="text"
                className="bg-white"
                style={{
                  height: 60,
                  width: 350,
                  borderRadius: 30,
                  border: "1px solid #D0D5DD",
                }}
              />
              <Breakpoint large up>
                <Button
                  className="bg-primary text-white primary-button border border-primary ms-4"
                  style={{
                    paddingLeft: 35,
                    paddingRight: 35,
                    borderRadius: 40,
                    height: 60,
                  }}
                >
                  {t("subscribe")}
                </Button>
              </Breakpoint>
            </div>
            <div className="mt-1">
              {t("We care about your data in our privacy policy.")}
            </div>
            <Breakpoint medium down>
              <Button
                className="bg-primary text-white primary-button border border-primary mt-5"
                style={{
                  paddingLeft: 35,
                  paddingRight: 35,
                  borderRadius: 40,
                  height: 60,
                }}
              >
                {t("subscribe")}
              </Button>
            </Breakpoint>
          </Col>
        </Row>
      </div>
      <Image
        src={paperPlane}
        alt="Paper Plane"
        className="d-none d-lg-block img-fluid position-absolute end-0"
        style={{ width: 240, bottom: 30 }}
      />
    </Container>
  );
};

export default Subscribe;
