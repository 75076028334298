import { Button, Form, Spinner } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const SignupForm = ({
  form,
  handleSignUp,
  checked,
  setChecked,
  isSubmitting,
  setShowForm,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-5" style={{ height: 700 }}>
      <h3 className="pe-3">{t("letsStart")}</h3>
      <div className="text-secondary my-3">{t("pleaseEnterYourDetails")}</div>
      <Form
        className="text-start w-100"
        ref={form}
        onSubmit={(e) => handleSignUp(e)}
      >
        <div className="d-flex">
          <Form.Group className="me-4">
            <Form.Label className="body-text1">{t("firstName")}</Form.Label>
            <Form.Control
              name="first_name"
              placeholder={t("john")}
              type="text"
              className="bg-white ps-4"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="">
            <Form.Label className="body-text1">{t("lastName")}</Form.Label>
            <Form.Control
              name="last_name"
              placeholder={t("doe")}
              type="text"
              className="bg-white ps-4"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
        </div>
        <Form.Group className="mt-2">
          <Form.Label className="body-text1">{t("email")}</Form.Label>
          <Form.Control
            name="email"
            placeholder="name@example.com"
            type="text"
            className="bg-white ps-4"
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label className="body-text1">{t("mobile")}</Form.Label>
          <Form.Control
            name="mobile"
            placeholder="9999999999"
            type="text"
            className="bg-white ps-4"
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </Form.Group>
        <div className="d-flex mt-2">
          <Form.Group className="me-4">
            <Form.Label className="body-text1">{t("password")}</Form.Label>
            <Form.Control
              name="password"
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
              type="password"
              className="bg-white ps-4"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="">
            <Form.Label className="body-text1">
              {t("confirmPassword")}
            </Form.Label>
            <Form.Control
              name="password1"
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
              type="password"
              className="bg-white ps-4"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <Form.Check
            type="checkbox"
            label={t("iAgreeToTermsAndConditions")}
            name="notifications"
            className="text-lightgrey"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </div>
        <Button
          className="my-2 bg-primary text-white primary-button poppins-600 w-100"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            height: 60,
          }}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span>
              <Spinner size="sm" className="me-2" />
              {`${t("signinUp")}...`}
            </span>
          ) : (
            <span>{t("signUp")}</span>
          )}
        </Button>
        <div className="d-flex justify-content-center align-items-center">
          <p className="text-lightgrey me-2">{t("alreadyHaveAnAccount")}</p>
          <div onClick={() => setShowForm("login")} className="cursor-pointer">
            <p className="text-primary">
              <b>{t("login")}</b>
            </p>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SignupForm;
