import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "reducer/utilitySlice";
import * as authActions from "reducer/authSlices";
import {
  FAQ,
  PlanCard,
  Subscribe,
  WhyChooseUs,
  LoginSignupForm,
  DocumentUploadForm,
} from "components";
import { pending, current, done } from "assets";
import { notify } from "utilities/notifications/Notify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const StatusInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="w-100 mt-3 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="poppins-600">{t("serviceDetails")}</span>
        <span style={{ color: "#0010F7" }}>$ 2999</span>
      </div>
      <div style={{ height: 0, width: 330 }} />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="poppins-600">{t("basicDetails")}</span>
        <span style={{ color: "#0010F7" }}>{t("nameAndDetails")}</span>
      </div>
      <div style={{ height: 0, width: 320 }} />
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="poppins-600">{t("docUpload")}</span>
        <span style={{ color: "#0010F7" }}>{t("requiredDocs")}</span>
      </div>
    </div>
  );
};

const ServiceProcessDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const [plans, setPlans] = useState([]);
  const [currentSession, setCurrentSession] = useState(null);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);
  const form = useRef();
  const [loginForm, setLoginForm] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    setIsSubmitting(true);
    dispatch(authActions.userLogin(formData));
    setIsSubmitting(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);

    if (formData.get("password") !== formData.get("confirm_password")) {
      notify(t("passwordsDidNotMatch"), "error");
      setIsSubmitting(false);
      return;
    }

    formData.append("username", formData.get("email"));
    const response = await authActions.signingUp(formData);
    if (response.status === 201) {
      form.current.reset();
      setIsSubmitting(false);
      notify(t("accountCreatedSuccessfully"), "success");
    } else if (response?.response?.status === 400) {
      notify(t("pleaseEnterValidData"), "error");
    } else {
      notify(t("problemWhileSigningUp"), "error");
    }
    setIsSubmitting(false);
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param1);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchSession = async () => {
    const response = await actions.getSession(sessionId);
    if (response) {
      setCurrentSession(response);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      setCurrentSession(response);
      setSessionId(response.id);
    }
  };

  const updateSession = async () => {
    const response = await actions.updateSession(sessionId, { user: user.id });
    if (response) {
      fetchSession();
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param1);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  useEffect(() => {
    if (param1) {
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param1]);

  useEffect(() => {
    if (sessionId) {
      fetchSession();
    }
  }, [sessionId]);

  useEffect(() => {
    if (user && sessionId) {
      updateSession();
    }
  }, [user, sessionId]);

  return (
    <Container
      fluid
      className="px-0 mt-5 d-flex flex-column justify-content-center align-items-center"
    >
      <Container
        fluid
        className="mt-5 pt-5 d-flex flex-column align-items-center bg-tertiary"
        style={{ minHeight: "100vh" }}
      >
        <div className="page-header my-3">
          {plans.length > 0 ? plans[0]?.service_data?.title : "Services"}
        </div>
        <div className="text-secondary subtitle">
          {t("theLatestIndustryNewsTechEtcText")}
        </div>
        {!currentSession ? (
          <>
            <div className="mt-5 w-100 d-flex justify-content-center">
              {plans?.map((plan) => (
                <PlanCard
                  plan={plan}
                  reqDocumentTypes={reqDocumentTypes}
                  serviceSlug={param1}
                  createSession={createSession}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="w-75">
            {currentSession.step === 1 ? (
              <div
                className="d-flex mt-5 flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <div
                    className="bg-tertiary d-flex justify-content-center align-items-center"
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <Image src={current} alt="Current" style={{ width: 32, height: 32 }} />
                  </div>
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#EAECF0",
                    }}
                  />
                  <Image src={pending} alt="Pending" style={{ width: 32, height: 32 }} />
                </div>
                <StatusInfo />
              </div>
            ) : currentSession.step === 2 ? (
              <div
                className="d-flex mt-5 flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <div
                    className="bg-tertiary d-flex justify-content-center align-items-center"
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <Image src={current} alt="Current" style={{ width: 32, height: 32 }} />
                  </div>
                </div>
                <StatusInfo />
              </div>
            ) : currentSession.step === 3 ? (
              <div
                className="d-flex mt-5 flex-column justify-content-center align-items-center"
                style={{
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                  <div
                    style={{
                      height: 2,
                      width: 400,
                      backgroundColor: "#0010F7",
                    }}
                  />
                  <Image src={done} alt="Done" style={{ width: 32, height: 32 }} />
                </div>
                <StatusInfo />
              </div>
            ) : (
              <></>
            )}
            {currentSession.step === 1 && (
              <div className="d-flex justify-content-center align-items-center">
                <Card
                  className="w-50 my-5 d-flex justify-content-center align-items-center card-shadow border-0"
                  style={{ borderRadius: 16 }}
                >
                  <LoginSignupForm
                    form={form}
                    handleLogin={handleLogin}
                    handleSignUp={handleSignUp}
                    isSubmitting={isSubmitting}
                    loginForm={loginForm}
                    setLoginForm={setLoginForm}
                  />
                </Card>
              </div>
            )}
            {(currentSession.step === 2 || currentSession.step === 3) && (
              <div className="d-flex justify-content-center align-items-center">
                <Card
                  className="w-100 my-5 d-flex justify-content-center align-items-center border-0"
                  style={{ borderRadius: 16 }}
                >
                  <DocumentUploadForm
                    documentTypes={reqDocumentTypes}
                    session={currentSession}
                    fetchSession={fetchSession}
                    currentDocuments={currentSession?.session_documents}
                    type="plan"
                  />
                </Card>
              </div>
            )}
            {/* {currentSession.step === 2 && (
              <div className="w-100 d-flex justify-content-center">
                Your documents are being reviewed!
              </div>
            )} */}
          </div>
        )}
      </Container>
      {!currentSession && <WhyChooseUs color="#FFFFFF" />}
      <Subscribe />
      {!currentSession && <FAQ />}
    </Container>
  );
};

export default ServiceProcessDocuments;