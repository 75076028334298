import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, GetInTouchFormSelect } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { MdOutlineArrowOutward } from "react-icons/md";
import { reason_1, reason_2, reason_3, reason_4 } from "assets";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "react-socks";
import { useMediaQuery } from "react-responsive";
import Collaborations from "components/Home/Collaborations";

export const BusinessSetupCard = ({ service }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <>
      <Breakpoint large up>
        <Card
          className="mx-3 mb-5"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            border: "1px solid #CCCCCC",
            borderRadius: 16,
          }}
        >
          <Row className="m-0">
            <Col
              className="p-4 d-flex flex-column justify-content-between"
              lg={6}
              style={{
                borderRadius: "16px 0px 0px 16px",
                // backgroundColor: hover ? "#FFFFFF" : "#000000",
                background: hover ? "#2e3192" : "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)"
              }}
            >
              <div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: hover ? "#FFFFFF" : "#000000",
                    width: 48,
                    height: 48,
                    borderRadius: 24,
                  }}
                >
                  <MdOutlineArrowOutward
                    size={30}
                    color={hover ? "#000000" : "#FFFFFF"}
                  />
                </div>
                <h3 className={`${hover ? "text-white" : "text-black"} mt-5 mb-3`}>{service.title}</h3>
                <div className={`${hover ? "text-white" : "text-black"}`}>{service.description}</div>
              </div>
              {service.slug ?
                <Button
                  className={`${hover
                    ? "text-primary bg-white border border-primary"
                    : "bg-primary text-white"
                    } poppins-600 w-100`}
                  style={{ borderRadius: 30, height: 60 }}
                  onClick={() => navigate(`/services/${service.slug}`)}
                >
                  {t("knowMore")}
                </Button>
                :
                <Button
                  className={`${hover
                    ? "text-primary bg-white border border-primary"
                    : "bg-primary text-white"
                    } poppins-600 w-100 mt-4`}
                  style={{ borderRadius: 30, height: 60 }}
                  onClick={() => navigate(`/get-in-touch`)}
                >
                  Consult Us
                </Button>
              }
            </Col>
            <Col className="p-0 h-100" lg={6}>
              <Image
                src={service?.service_image}
                alt={service.title}
                style={{ borderRadius: "0px 16px 16px 0px", minHeight: 400 }}
                className="w-100"
              />
            </Col>
          </Row>
        </Card>
      </Breakpoint>
      <Breakpoint medium down>
        <Card
          className="m-3 p-3"
          style={{ border: "1px solid #CCCCCC", borderRadius: 16, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
        >
          <div className="mb-5">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#000000",
                width: 48,
                height: 48,
                borderRadius: 24,
              }}
            >
              <MdOutlineArrowOutward size={30} color={"#FFFFFF"} />
            </div>
            <h2 className={`text-black my-3`}>{service.title}</h2>
            <div className={`text-black`}>{service.description}</div>
          </div>
          {service.slug ?
            <Button
              className={`text-white bg-primary border border-primary poppins-600 w-100`}
              style={{ borderRadius: 30, height: 60 }}
              onClick={() => navigate(`/services/${service.slug}`)}
            >
              {t("knowMore")}
            </Button>
            :
            <Button
              className={`text-white bg-primary border border-primary poppins-600 w-100`}
              style={{ borderRadius: 30, height: 60 }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              Consult Us
            </Button>
          }
        </Card>
      </Breakpoint>
    </>
  )
}

const BusinessSetupServices = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [childServices, setChildServices] = useState([]);
  const topReasons = [
    {
      id: 1,
      icon: reason_1,
      title: t("strategicLocation"),
      description: t(t("strategicLocationDesc")),
    },
    {
      id: 2,
      icon: reason_2,
      title: t("stableEconomy"),
      description: t("stableEconomyDesc"),
    },
    {
      id: 3,
      icon: reason_3,
      title: t("taxBenefits"),
      description: t("taxBenefitsDesc"),
    },
    {
      id: 4,
      icon: reason_4,
      title: t("globalConnectivity"),
      description: t("globalConnectivityDesc"),
    },
    {
      id: 5,
      icon: reason_1,
      title: t("qualityOfLife"),
      description: t("qualityOfLifeDesc"),
    },
    {
      id: 6,
      icon: reason_2,
      title: t("culturalDiversity"),
      description: t("culturalDiversityDesc"),
    },
    {
      id: 7,
      icon: reason_3,
      title: t("legalFramework"),
      description: t("legalFrameworkDesc"),
    },
    {
      id: 8,
      icon: reason_4,
      title: t("fullOwnership"),
      description: t("fullOwnershipDesc"),
    },
  ];

  const fetchChildrenServices = async () => {
    const response = await actions.getChildServices(param1);
    if (response) {
      setChildServices(response.results);
    }
  };

  useEffect(() => {
    if (param1) {
      fetchChildrenServices();
    }
  }, [param1]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : "align-items-start"}`}
        >
          <div
            className="px-3 text-primary body-text2 d-flex justify-content-center align-items-center"
            style={{ height: 28, borderRadius: 14, backgroundColor: "#e6e7fa" }}
          >
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">
            {t("businessSetupServices")}
          </div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={8}>
            <div className="page-header mb-3">
              Company Formation In Dubai, UAE
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              {t('businessSetupServicesPara3')}
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              {t('businessSetupServicesPara4')}
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              {t('businessSetupServicesPara5')}
            </div>
          </Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
        {childServices.slice(0, 2).map((service) => {
          return (
            <BusinessSetupCard service={service} />
          );
        })}
      </Container>
      <Container fluid className="bg-tertiary">
        <Container>
          <div className={`mt-5 pt-5 ${large ? "w-75" : ""}`}>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("whyToChooseUAE")}</b>
            </div>
            <div className="page-header my-2">
              {t("Top Reasons to Choose the UAE for Your Business")}
            </div>
            <div className="subtitle-400 text-secondary mb-5">
              {t("whyToChooseDesc")}
            </div>
          </div>
          <Row className={`mb-5 pb-5 ${large ? "row-cols-4" : medium ? "row-cols-2" : "row-cols-1"}`}>
            {topReasons.map((reason) => {
              return (
                <Col className="mb-4">
                  <Card
                    className="p-4 d-flex justify-content-between border-0"
                    style={{ height: large ? 280 : 230, backgroundColor: "#F9FAFB" }}
                  >
                    <div
                      className="bg-primary d-flex justify-content-center align-items-center"
                      style={{ width: 48, height: 48, borderRadius: 10 }}
                    >
                      <Image src={reason.icon} alt={reason.title} />
                    </div>
                    <div>
                      <div className="subtitle mb-2">{reason.title}</div>
                      <div className="font-size16 text-secondary">
                        {reason.description}
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <Container className="d-flex flex-column align-items-start my-5">
        <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
          <b>{t("ourServices")}</b>
        </div>
        <div className="page-header my-2">
          Business Setup in other parts of the world
        </div>
        {childServices.slice(2).map((service) => {
          return (
            <BusinessSetupCard service={service} />
          );
        })}
      </Container>
      <BoostBusiness />
      <Collaborations />
      <FAQ />
      <Subscribe />
    </>
  );
};

export default BusinessSetupServices;
