import { Button, Form, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const VerifyForm = ({
  form,
  handleVerifyEmail,
  resendOtpRequest,
  setShowForm,
  timer,
  setTimer,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="p-5 d-flex flex-column justify-content-center"
      style={{ height: 700 }}
    >
      <h3 className="pe-3">{t("verifyEmail")}</h3>
      <div className="text-secondary my-3">{t("pleaseEnterOtpAndVerify")}</div>
      <Form
        className="text-start w-100"
        ref={form}
        onSubmit={(e) => handleVerifyEmail(e)}
      >
        <Form.Group className="mt-2">
          <Form.Label className="body-text1">{t("otp")}</Form.Label>
          <Form.Control
            name="email_verified_otp"
            placeholder="&#8226;&#8226;&#8226;&#8226;"
            type="password"
            className="bg-white ps-4"
            required
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </Form.Group>
        <div className="d-flex justify-content-end align-items-center my-2">
          <div
            className={`${
              timer !== 0 ? "text-lightgrey" : "text-primary cursor-pointer"
            }`}
            onClick={(e) => {
              if (timer === 0) {
                resendOtpRequest(e);
              }
            }}
          >
            {t("resendOtp")}
          </div>
          <div className={`${timer !== 0 ? "mx-2 caption" : "d-none"}`}>
            {`in ${timer} seconds`}
          </div>
        </div>
        <Button
          className="bg-primary text-white primary-button poppins-600 w-100 my-2"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            height: 60,
          }}
          type="submit"
        >
          {t("verify")}
        </Button>
      </Form>
      <div className="d-flex justify-content-center align-items-center">
        <p className="text-lightgrey me-2">{t("accountVerified")}</p>
        <div onClick={() => setShowForm("login")} className="cursor-pointer">
          <p className="text-primary">
            <b>{t("backToLogin")}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyForm;
