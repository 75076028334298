import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import {
  BoostBusiness,
  ChatWithProfessionals,
  Subscribe,
} from "components";
import { t } from "i18next";
import { newSkvWhiteLogo } from "assets";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const AboutUs = () => {
  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="about-us-hero-background text-white d-flex flex-column justify-content-start align-items-start about-us-full-page-background-content"
        style={{ minHeight: "100vh" }}
      >
        <Container className="py-5">
          <div className="mt-4">
            <a href="/about-us" className="text-white" style={{ textDecoration: "none" }}>{t("aboutUs")}</a>
            &nbsp;{" > "}&nbsp;Chairman's Message
          </div>
          <Row className="mt-5">
            <Col lg={2}>
              <Image className="w-100" src={newSkvWhiteLogo} alt="SKV logo" />
            </Col>
            <Col lg={10} className="subtitle">
              <div>Dear Clients, Partners, and Stakeholders,</div>
              <br />
              <div>
                I am delighted to welcome you to SKV Business Services, where we specialize in turning your
                business setup visions into reality. Our journey began with a simple yet powerful idea: to provide
                comprehensive, tailored, and efficient business setup solutions that cater to the unique needs of
                each entrepreneur and organization we serve.
              </div>
              <br />
              <div>
                At SKV Business Services, we understand that establishing a new business is a significant endeavour,
                filled with both opportunities and challenges. Our mission is to empower entrepreneurship success
                through customized solutions and integrated methodologies, and to ensure unparalleled satisfaction
                and success in every venture we undertake.
              </div>
              <br />
              <div>
                Our team of experienced professionals is dedicated to providing exceptional service, leveraging their
                extensive knowledge and industry insights to deliver solutions that are both innovative and practical.
              </div>
              <br />
              <div>
                We pride ourselves on our commitment to excellence, integrity, and customer satisfaction, and we are
                constantly striving to exceed your expectations.
              </div>
              <br />
              <div>
                As we move forward, we remain focused on our core values of trust, reliability, and partnership. We
                believe that by fostering strong relationships with our clients and partners, we can create a supportive
                and dynamic environment where businesses can thrive.
              </div>
              <br />
              <div>
                Thank you for choosing SKV Business Services as your trusted partner in business setup. We look forward
                to working with you and contributing to your success!
              </div>
              <Button
                className={`mt-5 bg-transparent text-white border border-white poppins-600 ${medium ? "" : "w-100"
                  }`}
                style={{
                  paddingLeft: 35,
                  paddingRight: 35,
                  borderRadius: 34,
                  height: 60,
                }}
                onClick={() => navigate(`/get-in-touch`)}
              >
                {t("getInTouch")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="bg-white py-5">
        <ChatWithProfessionals />
      </Container>
      <BoostBusiness />
      <Container fluid className="bg-white">
        <Subscribe />
      </Container>
    </Container>
  );
};

export default AboutUs;
