import { Button, Col, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "reducer/utilitySlice";
import { RxCross2 } from "react-icons/rx";
import Flags from "country-flag-icons/react/3x2";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const LanguageModal = ({
  showLanguageModal,
  setShowLanguageModal,
  languages,
}) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const FlagIcon = ({ language }) => {
    const Flag = Flags[language?.country_code];

    return (
      <Flag
        title={language?.name}
        className="text-black"
        style={{ height: 30 }}
      />
    );
  };

  return (
    <Modal
      dialogClassName="d-flex align-items-center h-100"
      contentClassName="border-R40"
      show={showLanguageModal}
      onHide={() => setShowLanguageModal(false)}
      animation={false}
    >
      <Modal.Header
        closeButton={false}
        className="d-flex justify-content-center border-0 position-relative"
      >
        <Modal.Title className="text-secondary">
          {t("selectLanguage")}
        </Modal.Title>
        <div
          className="text-black position-absolute"
          onClick={() => setShowLanguageModal(false)}
          style={large ? { right: -20, top: -20 } : { right: -10, top: -10 }}
        >
          <RxCross2 size={25} color="white" className="cursor-pointer" />
        </div>
      </Modal.Header>

      <Modal.Body className="px-5">
        <Row className="row-cols-2">
          {languages.map((lan) => {
            return (
              <Col className="d-flex align-items-center my-2">
                <div
                  className="cursor-pointer d-flex"
                  onClick={() => {
                    setShowLanguageModal(false);
                    dispatch(actions.setLanguage(lan?.language_code));
                  }}
                >
                  <FlagIcon language={lan} />
                  <div className="body-text1 ms-3">{lan.name}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LanguageModal;
