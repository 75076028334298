import { default_profile_picture, uaeFlag } from "assets";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import * as authSlices from "reducer/authSlices";
import * as utilitySlice from "reducer/utilitySlice";
import { useMediaQuery } from "react-responsive";
import BasicDetails from "components/Account/BasicDetails";
import ChangePassword from "components/Account/ChangePassword";
import TrackServices from "components/Account/TrackServices";
import Billing from "components/Account/Billing";
import { Subscribe } from "components";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { notify } from "utilities/notifications/Notify";

const Account = () => {
  const form = useRef(null);
  const dispatch = useDispatch();
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [profilePhoto, setProfilePhoto] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const accountPages = [
    { id: 1, name: t("trackYourService"), slug: "service", active: true },
    { id: 2, name: t("basicDetails"), slug: "details", active: true },
    // { id: 3, name: t("paymentHistory"), slug: "payment", active: true },
    { id: 4, name: t("changePassword"), slug: "password", active: true },
    { id: 5, name: t("settings"), slug: "settings", active: true },
  ];

  const [currentPage, setCurrentPage] = useState(accountPages[0].slug);

  const handleProfilePictureClick = () => {
    document.getElementById("profile-picture-input").click();
  };

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_pic", file);
    const response = await authSlices.userProfilePicture(user.id, formData);
    if (response) {
      console.log(response);
      dispatch(authSlices.updateProfilePicture(response.profile_pic));
      notify("Profile Picture updated successfully!", "success");
    }
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    await dispatch(authSlices.userLogout());
    await dispatch(utilitySlice.removeSessionId());
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      setProfilePhoto(user.profile_pic);
    }
  }, [user]);

  return (
    <Container fluid className="p-0" style={{ minHeight: "100vh" }}>
      <div
        className="mt-5 profile-top-background"
        fluid
        style={{ minHeight: "30vh" }}
      />
      <Container style={{ minHeight: "20vh" }}>
        <div
          className="d-flex justify-content-center align-items-center profile-picture-box"
          style={{
            height: 200,
            width: 200,
            borderRadius: "50%",
            backgroundColor: "#F2F4F7",
            border: "7px solid #FFFFFF",
          }}
        >
          <input
            type="file"
            id="profile-picture-input"
            accept="image/*"
            className="d-none"
            onChange={handleProfilePictureUpload}
          />
          <div
            className="overflow-hidden cursor-pointer border-0 rounded-circle d-flex justify-content-center align-items-center"
            onClick={handleProfilePictureClick}
            style={{ width: 200, height: 189 }}
          >
            <img
              src={profilePhoto ? profilePhoto : default_profile_picture}
              alt="Profile"
              className="w-100"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginLeft: 230 }}
        >
          <div className="mt-4">
            <h3 className="">
              {user.first_name} {user.last_name}
            </h3>
            <span className="text-secondary">{user.email}</span>
          </div>
          <div className="mt-4 d-flex flex-column">
            <span>
              <b>{t("chatToSales")}</b> {t("callTimingText")}
            </span>
            <span className="mt-2">
              <a href="mailto:info@skvbusiness.com">
                <Button
                  className="py-2 px-3 bg-white text-black"
                  style={{ border: "1px solid #D0D5DD", borderRadius: "20px" }}
                >
                  <HiOutlineMail size={23} />
                  <span className="ms-2 poppins-500">
                    info@skvbusiness.com
                  </span>
                </Button>
              </a>
              <a href="tel:+971-54-440-7897">
                <Button
                  className="py-2 px-3 ms-2 bg-white text-black"
                  style={{ border: "1px solid #D0D5DD", borderRadius: "20px" }}
                >
                  <FiPhone size={20} />
                  <span className="ms-2 poppins-500">+971-54-440-7897</span>
                </Button>
              </a>
            </span>
          </div>
        </div>
      </Container>
      <Container style={{ borderTop: "2px solid #EAECF0" }}>
        <div className="mt-4">
          {accountPages.map((page) => {
            return (
              <Button
                className={`me-4 bg-tertiary text-primary primary-button poppins-600 mb-2 ${
                  currentPage === page.slug
                    ? "border border-primary"
                    : "border-0"
                }`}
                onClick={() => setCurrentPage(page.slug)}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  height: 60,
                  borderRadius: 30,
                }}
              >
                {page.name}
              </Button>
            );
          })}
        </div>
      </Container>
      {currentPage == "service" && <TrackServices />}
      {currentPage == "details" && <BasicDetails />}
      {currentPage == "payment" && <Billing />}
      {currentPage == "password" && <ChangePassword />}
      {currentPage == "settings" && (
        <Container className="mt-5 d-flex justify-content-center align-items-center">
          <Button
            className="bg-primary text-white primary-button border border-primary poppins-600"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              borderRadius: 30,
              height: 60,
            }}
            onClick={(e) => handleLogout(e)}
          >
            {t("logout")}
          </Button>
        </Container>
      )}
      <div className="pt-2">
        <Subscribe />
      </div>
    </Container>
  );
};

export default Account;
