import React, { useState } from "react";
import { Card, Col, Row, Carousel, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactCardFlip from "react-card-flip";
import {
  mainlandBusinessSetup,
  freezoneBusinessSetup,
  ukBusinessSetup,
  hungaryBusinessSetup,
} from "assets";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";

const ServiceCard = ({ item }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <ReactCardFlip isFlipped={hover} flipDirection="horizontal">
      <Card
        className="overflow-hidden border-0 p-1"
        style={{
          background: "-webkit-linear-gradient(0deg, #568ABE 7.74%, #ff0000 100%)",
          minHeight: 200,
          borderRadius: 24,
        }}
        onMouseEnter={() => setHover(true)}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Card.Img
            variant="center"
            src={item.image}
            alt={item.title}
            className="user-select-none"
            style={{
              width: "100%",
              minHeight: 200,
              objectFit: "cover",
              borderRadius: 24,
            }}
          />
        </div>
        <div className="mx-4 position-absolute text-white" style={{ bottom: 0 }}>
          <h3 className="py-3">{item.title}</h3>
        </div>
      </Card>
      <Card
        className="overflow-hidden border-0"
        style={{
          minHeight: 200,
          borderRadius: 24,
        }}
        onMouseLeave={() => setHover(false)}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Card.Img
            variant="center"
            src={item.image}
            alt={item.title}
            className="user-select-none"
            style={{
              width: "100%",
              minHeight: 200,
              objectFit: "cover",
              borderRadius: 24,
            }}
          />
        </div>
        <div
          className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
          style={{ background: "#2e3192" }}
        >
        </div>
        <div className="w-100 position-absolute text-primary d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 99 }}>
          <h3 className="px-5 pt-4 text-white">{item.title}</h3>
          <>{item.inclusive}</>
          <Button
            style={styles.readMoreButton}
            className="bg-tertiary text-primary"
            onClick={() => navigate(item.url)}
          >
            Read More
          </Button>
        </div>
      </Card>
    </ReactCardFlip>
  );
};

const BusinessSetupService = () => {
  const { t } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const serviceData = [
    {
      id: 1,
      title: t("UAEMainlandBusinessSetup"),
      image: mainlandBusinessSetup,
      url: "services/uae-mainland-business-setup",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />100% Business Ownership</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />All Inclusive Licenses</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Quick License Approval</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Visa Assistance</div>
      </div>
    },
    {
      id: 2,
      title: t("UAEFreezoneBusinessSetup"),
      image: freezoneBusinessSetup,
      url: "services/uae-free-zone-business-setup",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />100% Business Ownership</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Visa Assistance</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />IFZA</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />RAKEZ</div>
      </div>
    },
    {
      id: 3,
      title: "UK Business Setup",
      image: ukBusinessSetup,
      url: "uk-business-setup",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Access to a stable economy</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Global market connectivity</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Favorable tax regime</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Highly skilled workforce</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Government support for businesses</div>
      </div>
    },
    {
      id: 4,
      title: "Hungary Business Setup",
      image: hungaryBusinessSetup,
      url: "hungary-business-setup",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Low corporate tax rates</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Strategic location in Central Europe</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Growing economy and infrastructure</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />Favorable investment incentives</div>
        <div className="my-3 subtitle"><FaCheck size={25} color="#ff0000" className="me-3" />EU membership benefits</div>
      </div>
    },
  ];

  return (
    <Container fluid className="bg-white"
      style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
    >
      <Container
        className={`d-flex flex-column justify-content-center align-items-center user-select-none ${large ? "mt-5" : "fluid p-0"}`}
        style={{ minHeight: "100vh" }}
      >
        <div className="custom-header custom-blue-color poppins-600 mb-3 mt-5">{t("businessSetupServices")}</div>
        <div className="subtitle-400 text-secondary text-center mb-5">
          {t("Your needs, our expertise. Find the perfect solution.")}
        </div>
        <div className="w-100 position-relative">
          <div className="d-flex justify-content-center w-100 pt-3 pb-5">
            {large ?
              <Row className="w-100 container" lg={2}>
                {serviceData.map((item, index) => (
                  <Col key={index} className="mb-4">
                    <ServiceCard item={item} />
                  </Col>
                ))}
              </Row>
              :
              <div className="w-100">
                <Carousel className="pb-3" interval={2000} controls={false}>
                  {serviceData.map((item, index) => (
                    <Carousel.Item className="px-2" key={index}>
                      <ServiceCard item={item} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            }
          </div>
        </div>
      </Container>
    </Container>
  );
};

const styles = {
  readMoreButton: {
    paddingLeft: 35,
    paddingRight: 35,
    borderRadius: 30,
    height: 60,
  },
};

export default BusinessSetupService;
