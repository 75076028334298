import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notify } from "utilities/notifications/Notify";
import * as utilitySlice from "reducer/utilitySlice";


const GetInTouchForm = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);

  const handleServiceChange = (event) => {
    const { value, checked } = event.target;
    setSelectedServices((prevSelectedServices) => {
      if (checked) {
        return [...prevSelectedServices, value];
      } else {
        return prevSelectedServices.filter((service) => service !== value);
      }
    });
  };

  const fetchContactService = async () => {
    const response = await utilitySlice.getContactServices();
    if (response.status === 200) {
      setServices(response.data)
    }
  }

  const handleSendMessge = async (e) => {
    e.preventDefault();

    setIsSending(true);
    const formData = new FormData(form.current);

    for (const value of formData.values()) {
      if (value === "" || value === null) {
        notify("Please enter all the values");
        setIsSending(false);
        return false;
      }
    }

    formData.append('service_list', JSON.stringify(selectedServices));

    const response = await utilitySlice.addContact(formData);
    if (response.status === 201) {
      form.current.reset();
      notify(
        "Thank you for contacting us. We'll connect with you soon!",
        "success"
      );
    } else {
      notify("There was some problem submitting the form!", "error");
    }
    setIsSending(false);
  };

  useEffect(() => {
    fetchContactService()
  }, [])

  return (
    <Form ref={form} onSubmit={(e) => handleSendMessge(e)}>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">{t("fullName")}</Form.Label>
        <Form.Control
          name="full_name"
          placeholder="Enter your Full Name"
          type="text"
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">{t("email")}</Form.Label>
        <Form.Control
          name="email"
          placeholder="Enter your Email"
          type="text"
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">{t("phone")}</Form.Label>
        <Form.Control
          name="phone"
          placeholder="Enter your Phone Number"
          type="text"
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">{t("services")}</Form.Label>
        <Row className="row-cols-2">
          {services.map((service) => {
            return (
              <Col>
                <Form.Check
                  name="service"
                  type="checkbox"
                  className="me-5 mb-2"
                  label={t(service.name)}
                  value={service.id}
                  checked={selectedServices.includes(service.id)}
                  onChange={handleServiceChange}
                />
              </Col>
            );
          })}
        </Row>
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">
          {`${t("message")} (${t("yourRequirement")})`}
        </Form.Label>
        <Form.Control
          name="message"
          as="textarea"
          rows={5}
          placeholder={t("leaveUsAMessage")}
          type="text"
          className="bg-white p-4"
          style={{
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Check
          type="checkbox"
          required
          label={t("You agree to our friendly privacy policy.")}
        />
      </Form.Group>
      <Button
        className="my-4 bg-primary text-white primary-button poppins-600 w-100"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 30,
          height: 60,
        }}
        type="submit"
      >
        {isSending ? (
          <span>
            <Spinner size="sm" className="me-2" />
            Sending...
          </span>
        ) : (
          <span className="poppins">Send</span>
        )}
      </Button>
    </Form>
  );
};

export default GetInTouchForm;
