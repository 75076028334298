import React, { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Loader from "components/Loader/Loader";

const ScrollToTop = () => {

    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useLayoutEffect(() => {
        let timer = setTimeout(() => {
            setLoading(false)
        }, 1500);
        setLoading(true);
        return () => clearTimeout(timer);
    }, [location?.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location?.pathname])

    if (loading) {
        return <Loader />
    }

    return null;
}


export default ScrollToTop;