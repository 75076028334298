import React from "react";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";


const BillingAndInvoicing = ({ documentTypes = [] }) => {
  const { t } = useTranslation();

  // const user = useSelector((state) => state.auth.user);

  // const [uploads, setUploads] = useState({});
  // const docRef = useRef(null);

  return (
    <Container className="mb-4 mb-lg-5">
      <div>
        <h4>{t("billingAndInvoicing")}</h4>
        <div>{t("Pick an account plan that fits your workflow.")}</div>
      </div>
      <Form
        className="w-100 py-4 d-flex flex-column justify-content-center align-items-center"
        style={{ borderRadius: 12 }}
      >
        <div
          className="w-100 d-flex flex-column border"
          style={{ borderRadius: 12 }}
        >
          <Row className="p-2">
            <Col sm={4} className="d-flex align-items-center">
              {/* <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: 40,
                  height: 40,
                  border: "8px solid #F9F5FF",
                  borderRadius: 20,
                  backgroundColor: "#F4EBFF",
                }}
              >
                <IoDocumentOutline size={18} color="#0010F7" />
              </div>
              <span className="body-text2 text-secondary">Document List</span> */}
              {t("invoice")}
            </Col>
            <Col
              sm={2}
              className="d-flex align-items-center body-text2 text-secondary"
            >
              {t("billingDate")}
            </Col>
            <Col
              sm={2}
              className="d-flex align-items-center body-text2 text-secondary"
            >
              {t("status")}
            </Col>
            <Col
              sm={2}
              className="d-flex align-items-center body-text2 text-secondary"
            >
              {t("amount")}
            </Col>
            <Col
              sm={2}
              className="d-flex align-items-center body-text2 text-secondary"
            >
              {t("action")}
            </Col>
          </Row>
          {documentTypes.map((document) => {
            let is_available = true;
            return (
              <>
                <hr className="m-0" style={{ color: "#475467" }} />
                <Row>
                  <Col sm={4} className="d-flex flex-column py-4">
                    <div className="body-text1">
                      <b>{document.document_type_title}:</b>
                    </div>
                    <div className="body-text1 text-secondary">
                      {document.document_type_description}
                    </div>
                  </Col>
                  <Col
                    sm={1}
                    className="d-flex align-items-center body-text2 text-secondary"
                  >
                    {/* {uploads.hasOwnProperty(document.document_type)
                      ? uploads[document.document_type]?.size / 1000 + " kB"
                      : is_available[0]?.document_data?.size} */}
                    Dec 1, 2022
                  </Col>
                  {/* <Col sm={2} className="d-flex align-items-center">
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <a
                        href={`/`}
                        target="_blank"
                        className="text-decoration-none"
                      >
                        <div
                          className="d-flex justify-content-center align-items-center me-3 cursor-pointer"
                          style={{
                            width: 48,
                            height: 48,
                            border: "8px solid #F9F5FF",
                            borderRadius: 24,
                            backgroundColor: "#F4EBFF",
                          }}
                        >
                          <PiEyeBold className="text-primary" size={22} />
                        </div>
                      </a>
                      <div
                        className="d-flex justify-content-center align-items-center cursor-pointer"
                        style={{
                          width: 48,
                          height: 48,
                          border: "8px solid #F9F5FF",
                          borderRadius: 24,
                          backgroundColor: "#F4EBFF",
                        }}
                        // onClick={() =>
                        //   handleDeleteDocument(is_available[0].document)
                        // }
                      >
                        <MdDeleteOutline className="text-primary" size={22} />
                      </div>
                    </div>
                  </Col> */}
                  <Col sm={2} className="d-flex align-items-center">
                    USD $10.00
                  </Col>
                  <Col sm={2} className="d-flex align-items-center">
                    <span style={{ color: "#6941C6" }}>Download</span>
                  </Col>
                </Row>
              </>
            );
          })}
        </div>
        {documentTypes.length > 0 && (
          <Button
            className="mt-4 text-white primary-button border border-primary"
            style={{
              paddingLeft: 35,
              paddingRight: 35,
              borderRadius: 40,
              height: 60,
            }}
            type="submit"
          >
            {t("submit")}
          </Button>
        )}
      </Form>
    </Container>
  );
};

export default BillingAndInvoicing;
