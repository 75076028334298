import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { contactUs } from "assets";
import { GetInTouchForm } from "components";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const GetInTouch = () => {
  const { t, i18n } = useTranslation();

  const communication = [
    {
      id: 1,
      icon: <HiOutlineMail color="#2E3192" size={20} />,
      title: t("email"),
      description: t("Our friendly team is here to help."),
      info: "info@skvbusiness.com",
    },
    {
      id: 2,
      icon: <FiPhone color="#2E3192" size={20} />,
      title: t("phone"),
      description: t("callTimingText"),
      info: "+971 52 739 6758",
    },
    {
      id: 3,
      icon: <HiOutlineLocationMarker color="#2E3192" size={20} />,
      title: t("office"),
      description: "Come say hello at our office HQ.",
      info: t("Gold souq deira dubai, UAE"),
    },
  ];

  return (
    <Container fluid className="mt-5 pt-5 px-0" style={{ minHeight: "100vh" }}>
      <Container className="my-5 pt-5">
        <Row>
          <Col sm={6} className="pe-5">
            <h3 className="pe-3">
              {t("Let's level up your brand, together in Dubai")}
            </h3>
            <div className="subtitle-400 text-secondary my-4">
              {t("Our friendly team would love to hear from you.")}
            </div>
            <GetInTouchForm />
          </Col>
          <Col sm={6}>
            <Image src={contactUs} alt="Contact Us Image" />
            <Stats />
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="bg-tertiary d-flex justify-content-center align-items-center"
        style={{ minHeight: "40vh" }}
      >
        <Container>
          <Row>
            {communication.map((comm) => {
              return (
                <Col
                  sm={4}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 48,
                      height: 48,
                      border: "8px solid #F9F5FF",
                      borderRadius: 24,
                    }}
                  >
                    {comm.icon}
                  </div>
                  <b className="mt-4">{comm.title}</b>
                  <div className="mb-4">{comm.description}</div>
                  <div className="text-primary">
                    <b>{comm.info}</b>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <Container
        className="py-5 my-5"
        style={{
          minHeight: "20vh",
          backgroundColor: "#F2F5F7",
          borderRadius: 16,
        }}
      >
        <Row className="p-0 m-0">
          <Col
            sm={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="font-size32 poppins-500">
              {`${t("join")} 2,000+ ${t("subscribers")}`}
            </div>
            <div className="subtitle-400 text-secondary mt-2">
              {t("Stay in the loop with everything you need to know.")}
            </div>
          </Col>
          <Col sm={6} className="p-0">
            <div className="d-flex">
              <Form.Control
                name="subscribe"
                placeholder={t("enterYourEmail")}
                type="text"
                className="bg-white"
                style={{
                  height: 60,
                  width: 350,
                  borderRadius: 30,
                  border: "1px solid #D0D5DD",
                }}
              />
              <Button
                className="bg-primary text-white primary-button border border-primary ms-4"
                style={{
                  paddingLeft: 35,
                  paddingRight: 35,
                  borderRadius: 40,
                  height: 60,
                }}
              >
                {t("subscribe")}
              </Button>
            </div>
            <div className="mt-1">
              {t("We care about your data in our privacy policy.")}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export const Stats = () => {
  const stats = [
    { id: 1, title: t("totalBusinessSetup"), count: "400+" },
    { id: 2, title: t("licenseServices"), count: "125+" },
    {
      id: 3,
      title: t("vatRegistrationAndCorporateTaxRegistration"),
      count: "10k",
    },
    { id: 4, title: t("translationServices"), count: "200+" },
  ];

  return (
    <Row className="row-cols-2 mt-3">
      {stats.map((stat) => {
        return (
          <Col className="mb-4">
            <div className="home-heading poppins-600 text-primary">
              {stat.count}
            </div>
            <div className="body-text1 poppins-600">{stat.title}</div>
          </Col>
        );
      })}
    </Row>
  );
};

export default GetInTouch;
