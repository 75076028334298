import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, PlanCard, GetInTouchFormSelect } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import {
  moneyLaundering,
  financialInstitutions,
  ngo,
  nonFinancialBusinesses,
  uaeGovernment,
  freezoneBusinessSetup
} from "assets";

import { RiBankFill, RiGovernmentFill } from "react-icons/ri";
import { MdBusinessCenter } from "react-icons/md";
import { FaMapLocationDot, FaPeopleGroup } from "react-icons/fa6";

const AMLRegistrationService = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const topReasons = [
    {
      id: 1,
      icon: <RiBankFill color="white" size={25} />,
      image: financialInstitutions,
      title: "Financial Institutions",
      description: <div className="mt-3">
        <ol>
          <li className="my-4"><b>Banks : </b><div>All banks operating within the UAE must register with goAML.</div></li>
          <li className="my-4"><b>Insurance Companies : </b><div>Particularly those offering life insurance and other long-term savings products.</div></li>
          <li className="my-4"><b>Money Exchange Centers : </b><div>Entities involved in currency exchange or remittance services.</div></li>
          <li className="my-4"><b>Brokerage Firms : </b><div>Companies involved in trading securities or other financial instruments.</div></li>
        </ol>
      </div>,
    },
    {
      id: 2,
      icon: <MdBusinessCenter color="white" size={25} />,
      image: nonFinancialBusinesses,
      title: "Designated Non-Financial Businesses and Professions",
      description: <div className="mt-3">
        <ol>
          <li className="my-4"><b>Real Estate Brokers and Agents : </b><div>Those involved in buying and selling real estate on behalf of clients.</div></li>
          <li className="my-4"><b>Dealers in Precious Metals and Stones : </b><div>Entities dealing with high-value items such as gold, diamonds, and other precious stones.</div></li>
          <li className="my-4"><b>Lawyers, Notaries, and Legal Consultants : </b><div>Particularly those involved in financial transactions, company formation, and real estate.</div></li>
          <li className="my-4"><b>Accountants and Auditors : </b><div>Professionals offering financial and auditing services.</div></li>
        </ol>
      </div>,
    },
    {
      id: 3,
      icon: <RiGovernmentFill color="white" size={25} />,
      image: uaeGovernment,
      title: "Government Entities",
      description: <div className="mt-3">
        <ol>
          <li className="my-4"><b>Regulatory Authorities : </b><div>Entities responsible for overseeing compliance with AML regulations within their respective sectors.</div></li>
          <li className="my-4"><b>Other Government Bodies : </b><div>Involved in financial oversight or law enforcement.</div></li>
        </ol>
      </div>,
    },
    {
      id: 4,
      icon: <FaMapLocationDot color="white" size={25} />,
      image: freezoneBusinessSetup,
      title: "Free Zone Entities",
      description: <div className="mt-3">
        <ol>
          <li className="my-4"><b>Free Zone Companies : </b><div>Particularly those in financial or high-value goods sectors within UAE Free Zones.</div></li>
          <li className="my-4"><b>Trading Companies : </b><div>Entities involved in the import/export business, particularly those dealing with high-value goods such as electronics, luxury items, or vehicles.</div></li>
          <li className="my-4"><b>Holding and Investment Companies : </b><div>Free Zone companies that manage and invest in other businesses, particularly those with complex ownership structures.</div></li>
        </ol>
      </div>,
    },
    {
      id: 5,
      icon: <FaPeopleGroup color="white" size={25} />,
      image: ngo,
      title: "Non-Profit Organizations",
      description: <div className="mt-3">
        <ol>
          <li className="my-4"><b>Charitable Organizations : </b><div>Organizations involved in collecting and distributing donations, particularly those with international outreach or involvement in conflict zones.</div></li>
          <li className="my-4"><b>International NGOs : </b><div>Non-Governmental Organizations with operations or partners in multiple countries, especially those involved in cross-border financial transactions.</div></li>
          <li className="my-4"><b>Religious Organizations : </b><div>Entities that collect donations or distribute funds for religious purposes, particularly those with international affiliations or activities.</div></li>
        </ol>
      </div>,
    },
  ];

  const faqs = [
    {
      id: 1,
      question: "What does AML stand for?",
      answer: "AML stands for Anti-Money Laundering."
    },
    {
      id: 2,
      question: "What is GoAML?",
      answer: "GoAML (Government Anti-Money Laundering) is a system introduced by the UAE government to combat money laundering and terrorist financing activities. Businesses operating in certain sectors are required to register with GoAML and adhere to Anti-Money Laundering (AML) regulations."
    },
    {
      id: 3,
      question: "What is GoAML Registration?",
      answer: "GoAML registration involves signing up on the UAE FIU's goAML portal. After meeting the AML registration requirements, entities receive an email from the FIU confirming their successful registration."
    },
    {
      id: 4,
      question: "How do I register for the AML in the UAE?",
      answer: "To register for anti-money laundering in the UAE, entities must sign up on the FIU's goAML system, which collects data on SAR, STR, REAR, DPMSR, HRC, HRCA, FFR, and PNMR submissions."
    },
    {
      id: 5,
      question: "How many countries use GoAML?",
      answer: "The goAML application is used by over 60 UNODC member states through their national FIUs to combat money laundering and terrorist financing."
    },
  ]

  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">
            {t("amlRegistration")}
          </div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={8} className="pe-5">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("goamlRegistration")}</b>
            </div>
            <div className="page-header my-2">
              What is AML in the UAE?
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              GoAML (Governemnt Anti-Money Laundering) is a software application utilized
              by the UAE’s Financial Intelligence Unit (FIU) to combat money laundering and
              terrorist financing. It serves as part of the UNODC's strategic initiatives to
              address these global issues.
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              The goAML portal handles data collection, management, analysis, document
              handling, workflow, and statistical needs for the UAE’s Financial Intelligence
              Unit (FIU). It is used by the UAE FIU to gather information and data on suspicious
              transactions related to money laundering and terrorist financing.
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              GoAML registration, also known as AML Registration, simply involves signing up on
              the goAML portal managed by the UAE FIU. Once entities meet the AML registration
              requirements, they receive an email from the FIU confirming their successful Anti-Money
              Laundering registration.
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              In the UAE, regulated entities must complete this registration to report suspicious
              activities and transactions to the FIU.
            </div>
          </Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Container>
          <div className={`mt-5 pt-5`}>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("goamlRegistration")}</b>
            </div>
            <div className={`page-header my-2 ${large ? "w-75" : ""}`}>
              Entities Required to Register with the GoAML System in the UAE
            </div>
            <div className="subtitle-400 text-secondary mb-5">
              In the UAE, certain entities are required to register with the goAML system to comply with
              Anti-Money Laundering (AML) regulations and to report suspicious activities. These entities
              are typically those operating in sectors vulnerable to money laundering and terrorist financing.
              Here's a breakdown of who should register:
            </div>
          </div>
          <Row className={`mb-5 pb-5 row-cols-1`}>
            {topReasons.map((reason) => {
              return (
                <Col className="mb-4">
                  <Card
                    className="p-4 d-flex border-0"
                    style={{ borderRadius: 10, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                  >
                    <Row>
                      <Col sm={6} lg={6}>
                        <div className="d-flex align-items-center">
                          <span
                            className="bg-primary d-flex justify-content-center align-items-center me-3"
                            style={{ width: 50, height: 50, borderRadius: 10 }}
                          >
                            {reason.icon}
                          </span>
                          <span className="subtitle">{reason.title}</span>
                        </div>
                        <div>
                          <div className="font-size16 text-secondary">
                            {reason.description}
                          </div>
                        </div>
                      </Col>
                      <Col sm={6} lg={6}>
                        <Image src={reason.image} alt={reason.title} className="w-100" style={{ borderRadius: 10 }} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Container className="py-5 my-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("goamlRegistration")}</b>
            </div>
            <div className={`page-header my-2 ${large ? "w-75" : ""}`}>
              Documents requirements for goAML registration
            </div>
          </div>
          <Row>
            <Col sm={6} lg={7}>
              <div className="subtitle-400 text-secondary mb-1">
                To register with the goAML system in the UAE, entities must provide specific documents
                that verify their identity and ensure compliance with Anti-Money Laundering (AML) regulations.
                The exact requirements can vary depending on the type of entity (e.g., financial institutions,
                DNFBPs, non-profit organizations), but generally, the following documents are needed:
              </div>
              <ul className="subtitle-400 text-secondary">
                <li>The organization's Authorization Letter (Click to download the goAML Registration Authorization
                  Letter template) appointing the designated AML/CFT Compliance Officer.</li>
                <li>A copy of the Passport</li>
                <li>A copy of the Resident Visa</li>
                <li>A copy of Emirates ID of the Compliance Officer</li>
                <li>A copy of the organization’s commercial or trade license</li>
              </ul>
            </Col>
            <Col sm={6} lg={5}>
              <Image src={moneyLaundering} className="w-100" style={{ borderRadius: 10 }} />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="py-5" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}>
        <Container className="p-0 d-flex flex-column justify-content-center align-items-center py-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("documents")}</b>
            </div>
            <h3 className="my-2">{t("processDocuments")}</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("letsStartWithDocumentation")}
            </div>
          </div>
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param2}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container>
      <FAQ inputFaqs={faqs} />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default AMLRegistrationService;
