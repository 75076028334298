import { Button, Col, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "reducer/utilitySlice";
import { RxCross2 } from "react-icons/rx";
import Flags from "country-flag-icons/react/3x2";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
import { FaWhatsapp } from "react-icons/fa6";

const WhatsAppModal = ({ showWhatsAppModal, setShowWhatsAppModal }) => {

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const whatsappNumbers = [
    { id: 1, country: t('UAE'), country_code: "AE", number: "+971527396758" },
    { id: 2, country: t('unitedKingdom'), country_code: "GB", number: "+447700305114" },
    { id: 3, country: t('hungary'), country_code: "HU", number: "+36202767431" },
  ];

  const FlagIcon = ({ wanumber }) => {
    const Flag = Flags[wanumber?.country_code];

    return (
      <Flag
        // title={wanumber?.name}
        className="text-black"
        style={{ height: 30 }}
      />
    );
  };

  return (
    <Modal
      dialogClassName="d-flex align-items-center h-100"
      contentClassName="border-R40"
      show={showWhatsAppModal}
      onHide={() => setShowWhatsAppModal(false)}
      animation={false}
    >
      <Modal.Header
        closeButton={false}
        className="d-flex justify-content-center border-0 position-relative"
      >
        <Modal.Title className="text-secondary">
          {t("connectOnWhatsapp")}
        </Modal.Title>
        <div
          className="text-black position-absolute"
          onClick={() => setShowWhatsAppModal(false)}
          style={large ? { right: -20, top: -20 } : { right: -10, top: -10 }}
        >
          <RxCross2 size={25} color="white" className="cursor-pointer" />
        </div>
      </Modal.Header>

      <Modal.Body className="px-5">
        {whatsappNumbers.map((wanumber) => {
          return (
            <a
              className="d-flex align-items-center text-decoration-none my-3"
              href={`https://wa.me/${wanumber.number}`}
              target="_blank"
              rel="noopener"
            >
              <Button
                className="bg-tertiary text-secondary primary-button border border-info poppins-600"
                style={{ width: 48, height: 48, borderRadius: 24 }}
              >
                <FaWhatsapp size={20} />
              </Button>
              <div className="mx-3">-</div>
              <FlagIcon wanumber={wanumber} />
              <div className="ms-3 subtitle">{wanumber.country}</div>
            </a>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default WhatsAppModal;
