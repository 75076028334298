import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  BoostBusiness,
  ChatWithProfessionals,
  Subscribe,
} from "components";
import { t } from "i18next";
import { useMediaQuery } from "react-responsive";

const OurCommunity = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const instagramUrls = [
    { id: 1, url: "https://www.instagram.com/p/CuY0je_LUfd/" },
    { id: 2, url: "https://www.instagram.com/p/Cr0c5CFowzI/" },
    { id: 3, url: "https://www.instagram.com/p/CtyPAX9LDlS/" },
  ]

  useEffect(() => {
    // Ensure the Instagram script is loaded
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    document.body.appendChild(script);

    // Cleanup script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="about-us-hero-background text-white d-flex flex-column justify-content-start align-items-start about-us-full-page-background-content"
        style={{ minHeight: "100vh" }}
      >
        <Container className="py-5" style={{ minHeight: "100vh" }}>
          <div className="my-4">
            <a href="/about-us" className="text-white" style={{ textDecoration: "none" }}>{t("aboutUs")}</a>
            &nbsp;{" > "}&nbsp;Our Community
          </div>
          <h2 className="my-3">Latest Instagram Posts</h2>
          <Row className="my-5">
            {instagramUrls.map((insta) => {
              return (
                <Col xl={4} lg={6} md={6}>
                  <blockquote
                    className="instagram-media bg-white border-0 shadow rounded m-1 p-0 w-100 align-self-center"
                    data-instgrm-permalink={insta.url}
                    style={{ maxWidth: "540px", minWidth: "326px" }}
                  >
                    <div className="p-3">
                      <a
                        href={insta.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                        style={{
                          color: "#c9c8cd",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          lineHeight: "18px",
                          wordWrap: "break-word",
                        }}
                      >
                        A post shared by SKV Business Services (@skv_business)
                      </a>
                    </div>
                  </blockquote>
                </Col>
              )
            })}
          </Row>
          <h2 className="my-3">Latest Facebook Posts</h2>
          <Row className="my-5">
            <Col lg={6}>
              <iframe
                src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fphoto%2F%3Ffbid%3D122096897624263871%26set%3Da.122096897072263871&show_text=true&width=${small ? "500" : "100%"}`}
                width={small ? "500" : "100%"}
                height="534"
                style={{
                  border: "none",
                  overflow: "hidden",
                }}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              >
              </iframe>
            </Col>
            <Col lg={6}>
              <iframe src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fphoto%2F%3Ffbid%3D122096897180263871%26set%3Da.122096897222263871&show_text=true&width=${small ? "600" : "100%"}`}
                width={small ? "600" : "100%"}
                height={small ? "500" : "398"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  overflow: "hidden",
                }}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              >
              </iframe>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="bg-white py-5">
        <ChatWithProfessionals />
      </Container>
      <BoostBusiness />
      <Container fluid className="bg-white">
        <Subscribe />
      </Container>
    </Container>
  );
};

export default OurCommunity;
