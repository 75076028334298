import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, ToastContainer } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import './i18n';
import { useTranslation } from "react-i18next";

import { Navbar, Footer } from "components";
import Home from "pages/Home/Home";
import GetInTouch from "pages/GetInTouch/GetInTouch";
import AboutUs from "pages/AboutUs/AboutUs";
import ChairmanMessage from "pages/AboutUs/ChairmanMessage";
import OurCommunity from "pages/AboutUs/OurCommunity";
// import Promotions from "pages/Promotions/Promotions";
import Blogs from "pages/Blogs/Blogs";
import Blog from "pages/Blogs/Blog";
import ProcessDocuments from "pages/Service/ProcessDocuments";
import ServiceProcessDocuments from "pages/Service/ServiceProcessDocuments"

import Service from "pages/Service/Service";
import Services from "pages/Service/Services";
import BusinessSetupServices from "pages/Service/BusinessSetupService/BusinessSetupServices";
import FreeZoneBusinessSetup from "pages/Service/BusinessSetupService/FreeZoneBusinessSetup";
import MainlandBusinessSetup from "pages/Service/BusinessSetupService/MainlandBusinessSetup";
import UkBusinessSetup from "pages/Service/BusinessSetupService/UkBusinessSetup";
import HungaryBusinessSetup from "pages/Service/BusinessSetupService/HungaryBusinessSetup";
import LicenseService from "pages/Service/LicenseService";
import AMLRegistrationService from "pages/Service/AMLRegistrationService";
import PROService from "pages/Service/PROService";
import VisaService from "pages/Service/VisaService/VisaService";
import GoldenVisaService from "pages/Service/VisaService/GoldenVisaService";
import SchengenVisaService from "pages/Service/VisaService/SchengenVisaService";
import VatCorpTaxRegistration from "pages/Service/VatCorpTaxRegistration";

import Account from "pages/Account/Account";
import Orders from "pages/Account/Order/Orders";
import ParticularOrder from "pages/Account/Order/ParticularOrder";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

// import { Breakpoint, useCurrentWidth } from "react-socks";
// import { ToastContainer } from "react-toastify";
// import { persistor } from "store";

const Routers = () => {

  const token = useSelector((state) => state.auth.token);
  // const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [showLoginModal, setShowLoginModal] = useState(false);
  // const deviceWidth = useCurrentWidth();

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  const language = useSelector((state) => state.utilities.language);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage('en');
    }
  }, [i18n, language])

  return (
    <Router>
      <Container fluid className="px-0 poppins">
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />

        <Routes>
          <Route path="" element={<Home />} />
          <Route path="get-in-touch" element={<GetInTouch />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="about-us/chairman-message" element={<ChairmanMessage />} />
          <Route path="our-community" element={<OurCommunity />} />
          {/* <Route path="promotions" element={<Promotions />} /> */}
          <Route path="blogs" element={<Blogs />}>
            <Route path=":blogId" element={<Blog />} />
          </Route>
          <Route path="services" element={<Services />} />
          <Route path="business-setup-services" element={<BusinessSetupServices />} />
          <Route path="uk-business-setup" element={<UkBusinessSetup />}>
            <Route path="plan" element={<ServiceProcessDocuments />} />
          </Route>
          <Route path="hungary-business-setup" element={<HungaryBusinessSetup />}>
            <Route path="plan" element={<ServiceProcessDocuments />} />
          </Route>
          <Route path="services" element={<Service />}>
            {/* Business Setup Start */}
            <Route path="uae-free-zone-business-setup" element={<FreeZoneBusinessSetup />} />
            <Route path="uae-mainland-business-setup" element={<MainlandBusinessSetup />} />
            {/* Business Setup End */}
            {/* Other Services Start */}
            <Route path="license-service" element={<LicenseService />} />
            <Route path="pro-service" element={<PROService />} />
            <Route path="aml-registration" element={<AMLRegistrationService />} />
            <Route path="visa-service" element={<VisaService />} />
            <Route path="golden-visa" element={<GoldenVisaService />} />
            <Route path="schengen-visa" element={<SchengenVisaService />} />
            <Route path="vat-corporate-tax-registration" element={<VatCorpTaxRegistration />} />
            {/* Other Services End */}
            <Route path=":serviceSlug">
              <Route path="plan" element={<ProcessDocuments />} />
            </Route>
          </Route>
          {isAuthenticated &&
            <>
              <Route path="account" element={<Account />} />
            </>
          }
          <Route path="orders" element={<Orders />}>
            <Route path=":orderId" element={<ParticularOrder />} />
          </Route>
        </Routes>

        <Footer />
      </Container>
    </Router>
  );
};

export default Routers;
