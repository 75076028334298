import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, ChatWithProfessionals, PlanCard } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
import { FaUserClock, FaBusinessTime } from "react-icons/fa6";
import { FcMultipleInputs } from "react-icons/fc";
import { whySKVforVisaService } from "assets";

const GoldenVisaService = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const keyFeaturesAndBenefits = [
    {
      id: 1,
      icon: <FaUserClock size={40} color="#1565c0" />,
      title: t("longTermResidency"),
      description: t("longTermResidencyText"),
    },
    {
      id: 2,
      icon: <FaBusinessTime size={40} color="#1565c0" />,
      title: t("freedomToWorkStudy"),
      description: t("freedomToWorkStudyText"),
    },
    {
      id: 3,
      icon: <FcMultipleInputs size={40} color="#1565c0" />,
      title: t("multiLevelPermit"),
      description: t("multiLevelPermitText"),
    },
  ];

  const eligibilityCriteria = [
    { id: 1, title: "Real Estate Investors" },
    { id: 2, title: "Entrepreneurs" },
    { id: 3, title: "Exceptional Specialized Talents" },
    { id: 4, title: "Top-performing Students" },
    // { id: 5, title: "" },
  ]

  const [service, setService] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchService = async () => {
    const response = await actions.getService(param2);
    if (response) {
      setService(response);
    }
  };

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchService();
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-3">{service?.title}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container
        className="my-5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <Row>
          <Col sm={6}>
            <div className="d-flex justify-content-start align-items-center w-100">
              <Image src={service?.service_image} style={{ borderRadius: 20 }} />
            </div>
          </Col>
          <Col sm={6} className="d-flex flex-column">
            <h3 className="mb-2">
              Obtain the Prestigious UAE Golden Visa
            </h3>
            <div className="subtitle-400 text-secondary d-flex flex-column">
              <span>{t("goldenVisaPara1")}</span>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="mt-5 w-75">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("services")}</b>
          </div>
          <h3 className="my-2">
            {t("goldenVisa")} : Key Features and Benefits
          </h3>
          <div className="subtitle-400 text-secondary mb-5">
            The Golden Visa is designed to create a welcoming environment for those seeking to establish the UAE as their base of operations. It offers investors and entrepreneurs the opportunity to easily reside in the UAE, along with several additional benefits:
          </div>
        </div>
        <Row className={`mb-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
          {keyFeaturesAndBenefits.map((reason) => {
            return (
              <Col className="mb-5">
                <Card
                  className="p-4 d-flex justify-content-between bg-tertiary border-0"
                  style={{ height: 350 }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 80,
                      height: 80,
                      border: "10px solid #ffffff",
                      borderRadius: 40,
                    }}
                  >
                    {reason.icon}
                  </div>
                  <div>
                    <div className="subtitle mb-2">{reason.title}</div>
                    <div className="font-size16 text-secondary">
                      {reason.description}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="py-5 bg-tertiary" style={{}}>
        <Container className="">
          <div className="mt-5">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("services")}</b>
            </div>
            <h3 className="my-2">
              {t("goldenVisa")} : Eligibility and {t("applicationProcess")}
            </h3>
            <div className="subtitle-400 text-secondary mb-5">
              To determine your eligibility for a Dubai Golden Visa, it's essential to know that it is only available to specific categories of individuals.&nbsp;
              <a href="/">SKV Business</a> provides services to help you obtain the Golden Visa, guiding you through the requirements based on your eligibility and the desired residency duration:
            </div>
          </div>
          <div>
            <span className="page-header">01</span>
            <span className="subtitle mb-2 text-bold"> {t("eligibilityCriteria")}</span>
            <div className="subtitle-400 text-secondary"> {t("eligibilityCriteriaText")}</div>
            <Row className={`my-4 ${large ? "row-cols-4" : "row-cols-1"}`}>
              {eligibilityCriteria.map((criteria) => {
                return (
                  <Col className={`d-flex justify-content-center align-items-center`}>
                    <Card
                      className="p-4 border-0 d-flex justify-content-center align-items-center text-center bg-primary"
                      style={{ width: 300, minHeight: 120, borderRadius: 20, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                    >
                      <h4 className="text-primary">
                        {criteria.title}
                      </h4>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className={`text-white primary-button border-0 poppins-600 ${large ? "" : "w-100"}`}
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                  borderRadius: 30,
                  height: 60,
                }}
                onClick={() => navigate("/get-in-touch")}
              >
                Check Eligibility
              </Button>
            </div>
          </div>
          <span className="page-header">02</span>
          <span className="subtitle mb-2 text-bold"> {t("documentationRequirements")}</span>
          <div className="subtitle-400 text-secondary mb-2"> {t("documentationRequirementsText")}</div>
          <span className="page-header">03</span>
          <span className="subtitle mb-2 text-bold"> {t("multiLevelPermit")}</span>
          <div className="subtitle-400 text-secondary mb-2"> {t("multiLevelPermitText")}</div>
          <br />
        </Container>
      </Container>
      <Container className="my-5">
        <div className="mt-5">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("services")}</b>
          </div>
          <h3 className="my-2">
            {t("whyChooseSKVForGoldenVisa")}
          </h3>
          <Row className="mt-4">
            <Col sm={6} className="d-flex justify-content-center align-items-center">
              <div className="subtitle-400 text-secondary mb-5">
                {t("whyChooseSKVForGoldenVisaText")}
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end align-items-center">
              <Image className="w-80" src={whySKVforVisaService} alt="Why Choose SKV for Golden Visa" style={{ borderRadius: 20 }} />
            </Col>
          </Row>
        </div>
        <div className="subtitle mb-2 text-bold">
          {t("benefitOfChoosingSKVVisaService")}
        </div>
        <div className="subtitle mb-2 text-bold">
          {`1. ${t("persionalizedConsultaion")}`}
        </div>
        <div className="mb-2"> {t("persionalizedConsultaionText")}</div>
        <div className="subtitle mb-2 text-bold">
          {`2. ${t("streamlinedProcess")}`}
        </div>
        <div className="mb-2"> {t("streamlinedProcessText")}</div>
        <div className="subtitle mb-2 text-bold">
          {`3. ${t("complianceAssurance")}`}
        </div>
        <div className=""> {t("complianceAssuranceText")}</div>
      </Container>
      <Container fluid className="my-5 bg-tertiary d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5">
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("documents")}</b>
          </div>
          <h3 className="my-2">{t("processDocuments")}</h3>
          <div className="subtitle-400 text-secondary mb-5">
            {t("letsStartWithDocumentation")}
          </div>
        </div>
        {plans.map((plan) => {
          return (
            <PlanCard
              plan={plan}
              reqDocumentTypes={reqDocumentTypes}
              serviceSlug={param2}
              createSession={createSession}
            />
          );
        })}
      </Container>
      <ChatWithProfessionals />
      <FAQ />
      <BoostBusiness />
      <Subscribe />
    </>
  );
};

export default GoldenVisaService;
