import { Subscribe } from "components";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as authSlices from "reducer/authSlices";
import { notify } from "utilities/notifications/Notify";

const ChangePassword = () => {
  const form = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);

    if (formData.get("new_password") === formData.get("confirm_password")) {
      const response = await authSlices.change_password(formData);
      if (response) {
        form.current.reset();
        switch (response.status) {
          case "INCORRECT_PASSWORD":
            notify(response.msg, "error");
            break;
          case "SAME_AS_CURRENT_PASSWORD":
            notify(response.msg, "error");
            break;
          case "PASSWORD_CHANGED":
            notify(response.msg, "success");
            break;
          default:
            notify("Error while changing password, Try again later!", "error");
        }
      }
    } else {
      notify("Passwords Does Not Match.");
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Container className="pt-4">
        <h3 className="">{t("password")}</h3>
        <div>
          {t("Please enter your current password to change your password.")}
        </div>
        <div className="py-3 mt-3 mt-lg-4" style={{ borderRadius: 12 }}>
          <Row>
            <Col lg={8}>
              <Form
                className="text-start w-100"
                ref={form}
                onSubmit={(e) => handleChangePassword(e)}
              >
                <Row
                  xs={1}
                  lg={2}
                  className="align-items-center pb-3 mb-3"
                  style={{ borderBottom: "2px solid #EAECF0" }}
                >
                  <Col lg={4}>
                    <div>
                      <h6 className="font-weight-bold mb-2 mb-lg-0">
                        {t("currentPassword")}
                      </h6>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <Form.Control
                      name="current_password"
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                      type="password"
                      className="bg-white ps-4"
                      style={{
                        height: 60,
                        borderRadius: 30,
                        border: "1px solid #D0D5DD",
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  xs={1}
                  lg={2}
                  className="align-items-center pb-3 mb-3"
                  style={{ borderBottom: "2px solid #EAECF0" }}
                >
                  <Col lg={4}>
                    <div>
                      <h6 className="font-weight-bold mb-2 mb-lg-0">
                        {t("newPassword")}
                      </h6>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <Form.Control
                      name="new_password"
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                      type="password"
                      className="bg-white ps-4 mb-2"
                      style={{
                        height: 60,
                        borderRadius: 30,
                        border: "1px solid #D0D5DD",
                      }}
                    />
                    <div>
                      {t("Your new password must have more than 8 characters")}
                    </div>
                  </Col>
                </Row>
                <Row xs={1} lg={2} className="align-items-center mb-3">
                  <Col lg={4}>
                    <div>
                      <h6 className="font-weight-bold mb-2 mb-lg-0">
                        {t("confirmNewPassword")}
                      </h6>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <Form.Control
                      name="confirm_password"
                      placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                      type="password"
                      className="bg-white ps-4"
                      style={{
                        height: 60,
                        borderRadius: 30,
                        border: "1px solid #D0D5DD",
                      }}
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  <Button
                    className={`my-2 bg-primary text-white primary-button poppins-600 ${
                      medium ? "" : "w-100"
                    }`}
                    style={{
                      paddingLeft: 30,
                      paddingRight: 30,
                      borderRadius: 30,
                      height: 60,
                    }}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <span>
                        <Spinner size="sm" className="me-2" />
                        {`${t("reseting")}...`}
                      </span>
                    ) : (
                      <span className="poppins">{t("resetPassword")}</span>
                    )}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ChangePassword;
