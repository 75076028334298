import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, GetInTouchFormSelect } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { MdOutlineArrowOutward } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "react-socks";
import { useMediaQuery } from "react-responsive";
import { FaCheck } from "react-icons/fa6";

export const VisaServiceCard = ({ service }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <>
      <Breakpoint large up>
        <Card
          className="mx-3 mb-5"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            border: "1px solid #CCCCCC",
            borderRadius: 16,
          }}
        >
          <Row className="m-0">
            <Col
              className="p-4 d-flex flex-column justify-content-between"
              lg={6}
              style={{
                borderRadius: "16px 0px 0px 16px",
                // backgroundColor: hover ? "#FFFFFF" : "#000000",
                background: hover ? "#2e3192" : "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)"
              }}
            >
              <div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    // backgroundColor: hover ? "#000000" : "#FFFFFF",
                    backgroundColor: hover ? "#FFFFFF" : "#000000",
                    width: 48,
                    height: 48,
                    borderRadius: 24,
                  }}
                >
                  <MdOutlineArrowOutward
                    size={30}
                    color={hover ? "#000000" : "#FFFFFF"}
                  />
                </div>
                <h3 className={`${hover ? "text-white" : "text-black"} mt-5 mb-3`}>{service.title}</h3>
                <div className={`${hover ? "text-white" : "text-black"}`}>{service.description}</div>
              </div>
              {service.slug ?
                <Button
                  className={`${hover
                    ? "text-primary bg-white border border-primary"
                    : "bg-primary text-white"
                    } poppins-600 w-100`}
                  style={{ borderRadius: 30, height: 60 }}
                  onClick={() => navigate(`/services/${service.slug}`)}
                >
                  {t("knowMore")}
                </Button>
                :
                <Button
                  className={`${hover
                    ? "text-primary bg-white border border-primary"
                    : "bg-primary text-white"
                    } poppins-600 w-100 mt-4`}
                  style={{ borderRadius: 30, height: 60 }}
                  onClick={() => navigate(`/get-in-touch`)}
                >
                  Consult Us
                </Button>
              }
            </Col>
            <Col className="p-0 h-100" lg={6}>
              <Image
                src={service?.service_image}
                alt={service.title}
                style={{ borderRadius: "0px 16px 16px 0px" }}
                className="w-100"
              />
            </Col>
          </Row>
        </Card>
      </Breakpoint>
      <Breakpoint medium down>
        <Card
          className="m-3 p-3"
          style={{ border: "1px solid #CCCCCC", borderRadius: 16 }}
        >
          <div className="mb-5">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#000000",
                width: 48,
                height: 48,
                borderRadius: 24,
              }}
            >
              <MdOutlineArrowOutward size={30} color={"#FFFFFF"} />
            </div>
            <h2 className={`text-black my-3`}>{service.title}</h2>
            <div className={`text-black`}>{service.description}</div>
          </div>
          {service.slug ?
            <Button
              className={`text-primary bg-white border border-primary poppins-600 w-100`}
              style={{ borderRadius: 30, height: 60 }}
              onClick={() => navigate(`/services/${service.slug}`)}
            >
              {t("knowMore")}
            </Button>
            :
            <Button
              className={`text-primary bg-white border border-primary poppins-600 w-100`}
              style={{ borderRadius: 30, height: 60 }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              Consult Us
            </Button>
          }
        </Card>
      </Breakpoint>
    </>
  )
}

const VisaService = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const targetRef = useRef(null);

  const [childServices, setChildServices] = useState([]);
  const [single, setSingle] = useState(true);
  const singleVisitTypes = [
    {
      id: 1,
      days: "30 Days",
      title: "Single Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>60 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>30 days from date of entry</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extendable</b>
            <span>
              <span>Yes</span><span style={{ color: "red" }}>*</span>
            </span>
          </div>
        </div>
      </div>,
    },
    {
      id: 2,
      days: "60 Days",
      title: "Single Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>60 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>60 days from date of entry</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extendable</b>
            <span>
              <span>Yes</span><span style={{ color: "red" }}>*</span>
            </span>
          </div>
        </div>
      </div>,
    },
    {
      id: 3,
      days: "Extension",
      title: "Single Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extension Duration</b>
            <span>30 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Total Stay</b>
            <span>Initial Visa days + 30 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extension available on Visa</b>
            <span>30 Days, 60 Days</span>
          </div>
        </div>
      </div>,
    }
  ];
  const [array, setArray] = useState(singleVisitTypes)

  const multipleVisitTypes = [
    {
      id: 1,
      days: "30 Days",
      title: "Multiple Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>60 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>30 days from date of entry</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extendable</b>
            <span>
              <span>Yes</span><span style={{ color: "red" }}>*</span>
            </span>
          </div>
        </div>
      </div>,
    },
    {
      id: 2,
      days: "60 Days",
      title: "Multiple Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>60 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>60 days from date of entry</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extendable</b>
            <span>
              <span>Yes</span><span style={{ color: "red" }}>*</span>
            </span>
          </div>
        </div>
      </div>,
    },
    {
      id: 3,
      days: "5 Years",
      title: "Multiple Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Valid for entry within</b>
            <span>90 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Stay Period</b>
            <span>90 days from date of entry</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extendable</b>
            <span>
              <span>Yes</span><span style={{ color: "red" }}>*</span>
            </span>
          </div>
        </div>
      </div>,
    },
    {
      id: 3,
      days: "Extension",
      title: "Multiple Visit Entry",
      inclusive: <div className="text-white poppins-400">
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Processing Time</b>
            <span>1-3 Business Days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extension Duration</b>
            <span>30 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Total Stay</b>
            <span>Initial Visa days + 30 days</span>
          </div>
        </div>
        <div className="my-3 text-primary d-flex">
          <FaCheck size={20} color="#ff0000" className="me-2" />
          <div className="d-flex flex-column">
            <b>Extension available on Visa</b>
            <span>30 Days, 60 Days</span>
          </div>
        </div>
      </div>,
    }
  ];

  const fetchChildrenServices = async () => {
    const response = await actions.getChildServices(param2);
    console.log(response);
    if (response) {
      setChildServices(response.results);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchChildrenServices();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">
            {t("visaServices")}
          </div>
          <div ref={targetRef} className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={8}>
            <div className="page-header mb-3">
              Visa Services, UAE
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              Dubai, the largest and most populous city in the United Arab Emirates (UAE), is strategically located on the southeast coast of the Persian Gulf. Renowned for its rapid development, Dubai attracts people from around the globe for both business and leisure.
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              As one of the fastest-growing cities in the nation, Dubai has established itself as a key business hub in the Middle East. Its dynamic economy, marked by diverse sectors such as real estate, tourism, finance, and trade, contributes significantly to its global reputation. The city's luxurious lifestyle, high standard of living, and state-of-the-art infrastructure make it a prime destination for expatriates and tourists alike.
            </div>
            <div className="subtitle-400 text-secondary mb-3">
              In addition to its economic prowess, Dubai is famed for its architectural marvels and innovative projects. Iconic landmarks such as the Burj Khalifa, the tallest building in the world, and the Palm Jumeirah, an artificial archipelago, symbolize the city's ambition and vision. The city's commitment to innovation is also evident in its focus on becoming a leading smart city and adopting cutting-edge technologies. With its blend of tradition and modernity, Dubai offers a unique and vibrant experience, making it a standout destination on the global stage.
            </div>
          </Col>
          <Col lg={4} className="d-flex justify-content-center align-items-end">
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Container>
          <div className={`mt-5 pt-5`}>
            <div className="my-3 text-primary body-text2">
              <div className="d-flex flex-column">
                <b>{t("whyToChooseUAE")}</b>
              </div>
            </div>
            <div className="page-header my-2">
              Choose your Visa
            </div>
            <div className="subtitle-400 text-secondary">
              The type of visa you need will depend on your nationality or residence and the duration of your stay in the UAE. Select a visa type below to find out more.
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
            <Card className="p-2 d-flex flex-row border-0" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)", borderRadius: 40 }}>
              <Button
                className={`border-0 poppins-600 ${single ? "bg-primary text-white" : "bg-transparent text-primary"} ${large ? "" : "w-100"}`}
                style={{
                  paddingLeft: single ? 40 : 20,
                  paddingRight: single ? 40 : 20,
                  borderRadius: 30,
                  height: 60,
                }}
                onClick={() => {
                  setSingle(true)
                  setArray(singleVisitTypes)
                }}
              >
                Single Entry
              </Button>
              <Button
                className={`border-0 poppins-600 ${single ? "bg-transparent text-primary" : "bg-primary text-white"} ${large ? "" : "w-100"}`}
                style={{
                  paddingLeft: single ? 20 : 40,
                  paddingRight: single ? 20 : 40,
                  borderRadius: 30,
                  height: 60,
                }}
                onClick={() => {
                  setSingle(false)
                  setArray(multipleVisitTypes)
                }}
              >
                Multiple Entry
              </Button>
            </Card>
          </div>
          <Row className={`mb-5 ${large ? single ? "row-cols-3" : "row-cols-4" : "row-cols-1"}`}>
            {array.map((type) => {
              return (
                <Col className={`mb-4 d-flex justify-content-center align-items-center`}>
                  <Card
                    className="p-4 border-0 d-flex justify-content-between"
                    style={{ minWidth: 300, minHeight: 500, borderRadius: 20, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                  >
                    <h3 className="text-primary d-flex justify-content-center align-items-center">
                      {type.days}
                    </h3>
                    <div className="subtitle mb-4 text-center">{type.title}</div>
                    <div className="bg-primary" style={{ height: 3, borderRadius: 2 }} />
                    <div className="font-size16 text-secondary">
                      {type.inclusive}
                    </div>
                    <Button
                      className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"}`}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        borderRadius: 15,
                        height: 50,
                      }}
                      onClick={() => targetRef.current?.scrollIntoView({ behavior: "smooth" })}
                    >
                      {t("process")}
                    </Button>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <div className="pb-5">
            <span style={{ color: "red" }}>*</span>
            <span style={{ fontSize: 12 }}>The extension of a UAE visa is subject to the current regulations and policies set by the UAE government. Please contact SKV Business Services before hand in case you're planning an extension</span>
          </div>
        </Container>
      </Container>
      <Container className="my-5">
        <div className="my-3 text-primary body-text2">
          <div className="d-flex flex-column">
            <b>{t("ourServices")}</b>
          </div>
        </div>
        <div className="page-header my-2">
          Other Visa Services
        </div>
        {childServices.map((service) => {
          return (
            <VisaServiceCard service={service} />
          );
        })}
      </Container>
      <BoostBusiness />
      <FAQ />
      <Subscribe />
    </>
  );
};

export default VisaService;
