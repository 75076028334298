import React from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ParallaxBanner } from "react-scroll-parallax";
import { chatWithProfessionals } from "assets";


const ChatWithProfessionals = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center p-0"
      // chat-with-professionals-background
      style={{
        minHeight: "40vh",
        backgroundColor: "#F2F5F7",
        borderRadius: 16,
      }}
    >
      <ParallaxBanner
        layers={[{ image: chatWithProfessionals, speed: -20 },]}
        style={{
          minHeight: '40vh',
          borderRadius: 16,
          zIndex: 0,
        }}
        className="d-flex flex-column justify-content-center align-items-center py-5"
      >
        <h3 className="poppins-600 text-white" style={{ zIndex: 999 }}>{t("chatWithProfessionals")}</h3>
        <div className="mt-1 text-white text-center" style={{ zIndex: 999 }}>{t("chatWithProfessionalsText")}</div>
        <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 999 }}>
          <Button
            className="mt-3 bg-primary text-white primary-button border border-primary"
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              borderRadius: 40,
              height: 60,
            }}
            onClick={() => navigate(`/get-in-touch`)}
          >
            {t("getInTouch")}
          </Button>
        </div>
      </ParallaxBanner>
    </Container>
  );
};

export default ChatWithProfessionals;
