import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Subscribe, BoostBusiness, FAQ, PlanCard, GetInTouchFormSelect } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { proServiceMain, proServiceHelp } from "assets";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Collaborations from "components/Home/Collaborations";
import { useDispatch } from "react-redux";

import { GrUserWorker } from "react-icons/gr";
import { HiTranslate } from "react-icons/hi";
import { FaStamp } from "react-icons/fa6";
import { FaCcVisa, FaHandshake, FaHouseUser, FaNotesMedical, FaRibbon, FaUserFriends } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";
import { SiEmirates } from "react-icons/si";

const PROService = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const allProServices = [
    {
      id: 1,
      icon: <GrUserWorker size={40} color="#1565c0" />,
      title: t("labourCardRenewal"),
      description: "Labour card renewal in the UAE ensures employees can legally continue working. Employers handle this process through the Ministry of Human Resources, requiring valid documents like a passport and visa.",
    },
    {
      id: 2,
      icon: <HiTranslate size={40} color="#1565c0" />,
      title: t("translationServices"),
      description: "Enter SKV, a prominent certified translation services company based in the UAE. Our services extend across all business verticals in Dubai and other Emirates, catering to both legal and general document translation needs.",
    },
    {
      id: 3,
      icon: <FaStamp size={40} color="#1565c0" />,
      title: t("attestationServices"),
      description: "Attestation services in the UAE involve verifying the authenticity of documents such as educational certificates, marriage licenses, and legal papers for use within the country. These services ensure that documents are recognized by UAE authorities.",
    },
    {
      id: 4,
      icon: <FaNotesMedical size={40} color="#1565c0" />,
      title: t("visaAndMedicalTyping"),
      description: "Visa and medical typing services in the UAE prepare and submit required documents for residency, work visas, and health screenings, ensuring compliance with government regulations.",
    },
    {
      id: 5,
      icon: <MdOutlineSecurity size={40} color="#1565c0" />,
      title: t("SIRAServices"),
      description: "SIRA services in the UAE help businesses in the security sector obtain necessary approvals and certifications, ensuring regulatory compliance.",
    },
    {
      id: 6,
      icon: <FaCcVisa size={40} color="#1565c0" />,
      title: "Family Visa",
      description: "Family visa services in the UAE assist residents in sponsoring visas for their family members, ensuring the application process complies with government requirements for residency and legal stay.",
    },
    {
      id: 7,
      icon: <SiEmirates size={40} color="#1565c0" />,
      title: t("emiratesIDTyping"),
      description: "Emirates ID typing services in the UAE assist with accurately filling and submitting applications for Emirates ID cards, ensuring compliance with government regulations during the registration or renewal process.",
    },
    {
      id: 8,
      icon: <FaUserFriends size={40} color="#1565c0" />,
      title: t("powerOfAttorney"),
      description: "Power of Attorney services in the UAE help with drafting and notarizing legal documents that authorize someone to act on another's behalf, ensuring compliance with local legal requirements.",
    },
    {
      id: 9,
      icon: <FaHandshake size={40} color="#1565c0" />,
      title: "Local Sponsorship",
      description: "Local sponsorship services in the UAE assist foreign businesses and individuals in securing a local partner or sponsor to comply with regulatory requirements for setting up or operating in the country.",
    },
    {
      id: 10,
      icon: <FaRibbon size={40} color="#1565c0" />,
      title: t("AmerAndTasheelServices"),
      description: "Amer and Tasheel services in the UAE provide assistance with government transactions, including visa applications, document processing, and other administrative tasks, facilitating interactions with various government entities.",
    },
    {
      id: 11,
      icon: <FaHouseUser size={40} color="#1565c0" />,
      title: "KHARI Registration",
      description: "KHARI registration in the UAE provides housing assistance to nationals through loans and grants for building, buying, or renovating homes, with specific eligibility and registration criteria.",
    },
  ];

  const faqs = [
    {
      id: 1,
      question: "What are PRO services in the UAE?",
      answer: "In the UAE, a Public Relations Officer (PRO) manages the paperwork and clearance services necessary to start a business. They guide you through the legal requirements of the Department of Economic Development (DED) and assist with license renewals, visas, labor agreements, and immigration processes."
    },
    {
      id: 2,
      question: "Why are PRO services needed in the UAE?",
      answer: "You need PRO services in the UAE to effectively manage relationships with local and national government offices, obtain necessary certifications and approvals, and handle various administrative tasks. This includes securing the Certificate of Origin/Attestation, labor approvals, entry permits, employment visas, labor cards, labor contracts, residence visas, and more."
    },
    {
      id: 3,
      question: "Will I get professional help with the services?",
      answer: "SKV Business Setup, a leading business consulting firm in the UAE, offers a end-to-end solution for all your PR-related needs. Let our experts help you and deliver the best PRO services for your business."
    },
  ]

  const [plans, setPlans] = useState([]);
  const [reqDocumentTypes, setReqDocumentTypes] = useState([]);

  const fetchServicePlans = async () => {
    const response = await actions.getServicePlans(param2);
    if (response) {
      setPlans(response.results);
    }
  };

  const fetchServiceReqDocuments = async () => {
    const response = await actions.getServiceDocumentTypes(param2);
    if (response) {
      setReqDocumentTypes(response.results);
    }
  };

  const createSession = async (planId) => {
    const response = await actions.addSession({ plan: planId });
    if (response) {
      dispatch(actions.setSessionId(response.id));
      navigate(`/services/${param2}/plan`);
    }
  };

  useEffect(() => {
    if (param2) {
      fetchServicePlans();
      fetchServiceReqDocuments();
    }
  }, [param2]);

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">{t("proServices")}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
          <div
            className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
              }`}
          >
            <Button
              className={`bg-primary text-white primary-button border border-primary poppins-600 ${large ? "" : "w-100"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/services/${param2}/plan`)}
            >
              {t("process")}
            </Button>
            <Button
              className={`bg-transparent text-primary poppins-600 ${large ? "ms-4" : "w-100 my-4"
                }`}
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                borderRadius: 30,
                height: 60,
              }}
              onClick={() => navigate(`/get-in-touch`)}
            >
              {t("getInTouch")}
            </Button>
          </div>
        </Container>
      </Container>
      <Container className="d-flex flex-column">
        <Row className={`mt-5 pt-5`}>
          <Col lg={8} className="pe-5">
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("proServices")}</b>
            </div>
            <div className="page-header my-2"> {t("whatAreProServicesInDubai")}</div>
            <div className="subtitle-400 text-secondary mb-3">{t("proServicesAns1")}</div>
            <div className="subtitle-400 text-secondary mb-3">{t("proServicesAns2")}</div>
            <div className="subtitle-400 text-secondary mb-3">{t("proServicesAns3")}</div>
          </Col>
          <Col lg={4}>
            <Card className="mb-4" border="primary" style={{ borderRadius: 15, boxShadow: "8px 8px #2e3192" }}>
              <GetInTouchFormSelect />
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5 bg-tertiary">
        <Container className="py-5 my-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("proServices")}</b>
            </div>
            <div className={`page-header my-2 ${large ? "w-75" : ""}`}>
              Why choose SKV for PRO Services?
            </div>
          </div>
          <Row>
            <Col sm={6} lg={7}>
              <div className="subtitle-400 text-secondary mb-3">{t("proServicesPara1")}</div>
              <div className="subtitle-400 text-secondary mb-3">{t("proServicesPara2")}</div>
            </Col>
            <Col sm={6} lg={5}>
              <Image src={proServiceMain} className="w-100" style={{ borderRadius: 10 }} />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <div className={`pt-5 ${large ? "w-75" : ""}`}>
          <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
            <b>{t("proServices")}</b>
          </div>
          <div className="page-header my-2">
            Some of SKV’s PRO Services
          </div>
          <div className="subtitle-400 text-secondary mb-5">
            Understanding the complexity of this process, SKV provides a complete
            solution by offering our expertise as your dedicated PRO agent.
          </div>
        </div>
        <Row className={`pb-5 ${large ? "row-cols-3" : "row-cols-1"}`}>
          {allProServices.map((item) => {
            return (
              <Col className="mb-5">
                <Card
                  className="p-4 d-flex justify-content-between border-0"
                  style={{ height: 350, background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 80,
                      height: 80,
                      border: "10px solid #ffffff",
                      borderRadius: 40,
                    }}
                  >
                    {item.icon}
                  </div>
                  <div>
                    <div className="subtitle mb-2">{item.title}</div>
                    <div className="font-size16 text-secondary">
                      {item.description}
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container fluid className="bg-tertiary">
        <Collaborations bold={false} />
      </Container>
      <Container fluid className="my-5">
        <Container className="py-5 my-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("proServices")}</b>
            </div>
            <div className={`page-header my-2 ${large ? "w-75" : ""}`}>
              Why Business Investors Require PRO Services in Dubai?
            </div>
          </div>
          <div className="text-center">
            <Image src={proServiceHelp} className="w-100 mb-5" style={{ borderRadius: 10, maxWidth: 1000 }} />
          </div>
          <div className="subtitle-400 text-secondary mb-1">
            Business investors in Dubai need PRO (Public Relations Officer) services for several key reasons:
          </div>
          <ol className="subtitle-400 text-secondary">
            <li><b>Navigating Regulations</b>: PRO services help investors understand and comply with complex local laws and regulations, including business licensing and visa requirements.</li>
            <li><b>Streamlining Paperwork</b>: They manage essential paperwork and documentation for business setup, including trade licenses, permits, and registrations.</li>
            <li><b>Handling Government Liaison</b>: PRO services act as intermediaries between the business and various government departments, facilitating approvals and resolving issues.</li>
            <li><b>Efficient Visa Processing</b>: They handle the processing of employment and residency visas, ensuring timely and accurate completion of all requirements.</li>
            <li><b>Reducing Administrative Burden</b>: By managing routine administrative tasks, PRO services allow investors to focus on core business activities and strategic planning.</li>
            <li><b>Ensuring Compliance</b>: They help ensure ongoing compliance with UAE laws, including renewals of licenses and permits, to avoid legal issues and penalties.</li>
          </ol>
        </Container>
      </Container>
      <Container fluid className="py-5" style={{ background: "-webkit-linear-gradient(0deg, #c4dff4 7.74%, #ffdddd 100%)" }}>
        <Container className="p-0 d-flex flex-column justify-content-center align-items-center py-5">
          <div>
            <div className="text-primary body-text2 d-flex justify-content-start align-items-center">
              <b>{t("documents")}</b>
            </div>
            <h3 className="my-2">{t("processDocuments")}</h3>
            <div className="subtitle-400 text-secondary mb-5">
              {t("letsStartWithDocumentation")}
            </div>
          </div>
          {plans.map((plan) => {
            return (
              <PlanCard
                plan={plan}
                reqDocumentTypes={reqDocumentTypes}
                serviceSlug={param2}
                createSession={createSession}
              />
            );
          })}
        </Container>
      </Container>
      <BoostBusiness />
      <FAQ inputFaqs={faqs} />
      <Subscribe />
    </>
  );
};

export default PROService;
