import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Form,
} from "react-bootstrap";
import { BlogCard, Subscribe } from "components";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import * as actions from "reducer/utilitySlice";
import { IoArrowDownOutline, IoSearch } from "react-icons/io5";
import { t } from "i18next";
import { useMediaQuery } from "react-responsive";

const Blogs = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("LATEST");
  const [nextUrl, setNextUrl] = useState(null);
  const [blogCount, setBlogCount] = useState(0);

  const fetchBlogs = async (sortBy) => {
    let ordering = "";
    switch (sortBy) {
      case "LATEST":
        ordering = "-published_date";
        break;
      case "OLDEST":
        ordering = "published_date";
        break;
      default:
        break;
    }
    const response = await actions.getBlogs(
      `/blog/rest/blogs/?ordering=${ordering}`
    );
    if (response) {
      setBlogCount(response.count);
      setNextUrl(response.next);
      setBlogs(response?.results);
    } else {
      setError(t("blogsNotFound"));
    }
  };

  const fetchMoreBlogs = async () => {
    const response = await actions.getBlogs(nextUrl);
    if (response) {
      if (!response.previous) {
        setBlogCount(response.count);
      }
      setNextUrl(response.next);
      const temp = blogs.concat(...response?.results);
      setBlogs(temp);
      // setBlogs(blogs => [...blogs, ...response?.results])
    } else {
      setError(t("blogsNotFound"));
    }
  };

  const handleSearch = async (text) => {
    const response = await actions.searchBlog(text);
    if (response) {
      setBlogs(response.results);
      if (response.results.length === 0) {
        setError(t("noBlogsMatching"));
      }
    }
  };

  useEffect(() => {
    fetchBlogs(sortBy);
  }, []);

  if (blogId) {
    return <Outlet />;
  }

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : "align-items-start"}`}>
          <div
            className="px-3 text-primary body-text2 d-flex justify-content-center align-items-center"
            style={{ height: 28, borderRadius: 14, backgroundColor: "#e6e7fa" }}
          >
            <b>{t("ourBlogs")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">{t("resourcesAndInsights")}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-lg-5 mb-2">
            {t("theLatestIndustryNewsTechEtcText")}
          </div>
          <div className="d-flex">
            <Form
              className="d-flex align-items-center bg-white me-lg-3 me-1 px-4"
              style={{
                height: large ? 60 : 50,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            >
              <IoSearch size={25} className="me-1" color="#667085" />
              <Form.Control
                type="search"
                placeholder="Search"
                className="bg-white outline-none border-0 m-0 p-0"
                style={{
                  height: large ? 58 : 48,
                  borderRadius: 30,
                  width: large ? 200 : 170,
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Form>
            <Form.Select
              className="outline-none bg-white focus-none poppins-600 px-4"
              onChange={(e) => {
                setSortBy(e.target.value);
                fetchBlogs(e.target.value);
              }}
              value={sortBy}
              style={{
                width: large ? 270 : "100%",
                height: large ? 60 : 50,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            >
              <option value="LATEST">
                <IoArrowDownOutline size={18} />
                {t("newestFirst")}
              </option>
              <option value="OLDEST">{t("oldestFirst")}</option>
            </Form.Select>
          </div>
        </Container>
      </Container>
      <Container className="mt-4">
        <Row className={`${large ? "row-cols-3" : "row-cols-1"}`}>
          {blogs.map((blog) => {
            return (
              <Col>
                <BlogCard blog={blog} />
              </Col>
            );
          })}
        </Row>
        {nextUrl && (
          <Button
            className="bg-tertiary text-primary primary-button border-0 poppins-600"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              borderRadius: 30,
              height: 60,
            }}
          >
            <IoArrowDownOutline size={18} />
            <span className="ms-2">{t("loadMore")}</span>
          </Button>
        )}
      </Container>
      <Subscribe />
    </>
  );
};

export default Blogs;
