import { Button, Form, Spinner } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const LoginSignupForm = ({
  form,
  handleSignUp,
  handleLogin,
  isSubmitting,
  loginForm = true,
  setLoginForm,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {loginForm ? (
        <Form
          className="text-start w-100"
          ref={form}
          onSubmit={(e) => handleLogin(e)}
        >
          <Form.Group className="px-5 pt-5">
            <Form.Label className="body-text1">{t("email")}</Form.Label>
            <Form.Control
              name="username"
              placeholder="you@company.com"
              type="text"
              className="ps-4 bg-white"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="px-5 pt-3">
            <Form.Label className="body-text1">{t("enterPassword")}</Form.Label>
            <Form.Control
              name="password"
              placeholder="Password"
              type="password"
              className="ps-4 bg-white"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <div className="px-5">
            <Button
              className="my-4 bg-primary text-white primary-button poppins-600 w-100"
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 30,
                height: 60,
              }}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <span>
                  <Spinner size="sm" className="me-2" />
                  {t("loggingIn")}
                </span>
              ) : (
                <span>{t("login")}</span>
              )}
            </Button>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-4">
            <div className="me-2 body-text1">{t("dontHaveAnAccount")}</div>
            <div onClick={() => setLoginForm(false)} className="cursor-pointer">
              {t("signUp")}
            </div>
          </div>
        </Form>
      ) : (
        <Form
          className="text-start w-100"
          ref={form}
          onSubmit={(e) => handleSignUp(e)}
        >
          <Form.Group className="px-5 pt-5">
            <Form.Label className="body-text1">{`${t(
              "firstName"
            )} *`}</Form.Label>
            <Form.Control
              name="first_name"
              placeholder={t("firstName")}
              type="text"
              className="ps-4 bg-white"
              required
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="px-5 pt-3">
            <Form.Label className="body-text1">`${t("lastName")} *`</Form.Label>
            <Form.Control
              name="last_name"
              placeholder={t("lastName")}
              type="text"
              className="ps-4 bg-white"
              required
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="px-5 pt-3">
            <Form.Label className="body-text1">{t("email")}</Form.Label>
            <Form.Control
              name="email"
              placeholder="you@company.com"
              type="text"
              className="ps-4 bg-white"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="px-5 pt-3">
            <Form.Label className="body-text1">{t("phone")}</Form.Label>
            <Form.Control
              name="mobile"
              placeholder="999999999"
              type="text"
              className="ps-4 bg-white"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="px-5 pt-3">
            <Form.Label className="body-text1">{t("enterPassword")}</Form.Label>
            <Form.Control
              name="password"
              placeholder="Password"
              type="password"
              className="ps-4 bg-white"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <Form.Group className="px-5 pt-3">
            <Form.Label className="body-text1">
              {t("confirmPassword")}
            </Form.Label>
            <Form.Control
              name="confirm_password"
              placeholder="Confirm Password"
              type="password"
              className="ps-4 bg-white"
              style={{
                height: 60,
                borderRadius: 30,
                border: "1px solid #D0D5DD",
              }}
            />
          </Form.Group>
          <div className="px-5">
            <Button
              className="my-4 bg-primary text-white primary-button poppins-600 w-100"
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 30,
                height: 60,
              }}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <span>
                  <Spinner size="sm" className="me-2" />
                  {`${t("creating")}...`}
                </span>
              ) : (
                <span>{t("createAnAccount")}</span>
              )}
            </Button>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-4">
            <div className="me-2 body-text1">{t("alreadyHaveAnAccount")}</div>
            <div onClick={() => setLoginForm(true)} className="cursor-pointer">
              {t("login")}
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default LoginSignupForm;
