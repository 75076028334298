import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { asyncStatuses } from "../store/enums";
import axios from "axios";
import { notify } from "utilities/notifications/Notify";

const initialState = {
  user: null,
  token: null,
  isAuthenticated: null,
  status: null,
};

// console.log(process.env.REACT_APP_BASE_URLs)
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const userLogin = createAsyncThunk("auth/userLogin", async (data, thunkApi) => {
  try {
    const response = await axios.post("/user/login/", data);
    notify("You are successfully logged in.", "success");
    return response.data;
  } catch (err) {
    return thunkApi.rejectWithValue({ message: err?.response?.data?.msg || "Login Failed!" })
  }
});

export const userLogout = createAsyncThunk("auth/userLogout", async () => {
  const response = await axios.post("/user/logout/");
  return response;
});

export const userUpdate = createAsyncThunk("auth/userUpdate", async (data) => {
  const response = await axios.put(`/user/rest/users/${data.get("id")}/`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
});

export const signingUp = async (data) => {
  try {
    const response = await axios.post(`/user/signup/`, data);
    console.log("Response for signing up: ", response);
    return response;
  } catch (err) {
    console.log("Error signing up: ", err);
    return { error: true, msg: JSON.stringify(err.response.data) };
  }
};

export const verifyAccount = async (data) => {
  try {
    const response = await axios.post("/user/verify/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const resendVerifyOtp = async (data) => {
  try {
    const response = await axios.post(`/user/resend_verify_otp/`, data);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const sendResetOtp = async (data) => {
  try {
    const response = await axios.post("/user/send_reset_otp/", data);
    if (response.status === 200) {
      console.log(response);
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const reset_password = async (data) => {
  try {
    const response = await axios.post("/user/reset_password/", data);
    return response.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const change_password = async (data) => {
  try {
    const response = await axios.post("/user/change_password/", data);
    return response.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const userProfilePicture = async (id, data) => {
  try {
    const response = await axios.put(`/user/rest/users/${id}/update_profile_pic/`, data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg || "Update Failed!", "error");
    return false;
  }
}

const authSlices = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetAuthSlice: (state, action) => {
      return initialState
    },
    saveSocialLoginInfo: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user = { ...action.payload.user };
    },
    updateProfilePicture: (state, action) => {
      state.user.profile_pic = action.payload;
    }
  },
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(userLogin.pending, (state) => {
      state.status = asyncStatuses.LOADING;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.status = asyncStatuses.SUCCESS;
      console.log(action.payload);
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user = { ...action.payload.user };
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.status = asyncStatuses.FAILED;
      state.isAuthenticated = null;
      state.token = null;
      state.user = null;
      if (action.payload.message) {
        notify(action.payload.message, "error", "Login-Failed")
      }
    });

    // LOGOUT
    builder.addCase(userLogout.pending, (state) => {
      state.status = asyncStatuses.LOADING;
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.status = asyncStatuses.SUCCESS;
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.status = asyncStatuses.FAILED;
      // state.isAuthenticated = null;
      // state.token = null;
      // state.user = null;
    });

    // Profile
    builder.addCase(userUpdate.pending, (state) => {
      state.status = asyncStatuses.LOADING;
    });
    builder.addCase(userUpdate.fulfilled, (state, action) => {
      state.status = asyncStatuses.SUCCESS;
      state.user = { ...action.payload };
    });
    builder.addCase(userUpdate.rejected, (state, action) => {
      state.status = asyncStatuses.FAILED;
    });
  },
});

export const { resetAuthSlice, saveSocialLoginInfo, updateProfilePicture } = authSlices.actions;

export default authSlices.reducer;
