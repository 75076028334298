import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  BoostBusiness,
  ChatWithProfessionals,
  Subscribe,
  OurNetworks,
  MissionAndVision,
  RoadMap,
} from "components";
import { t } from "i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Collaborations from "components/Home/Collaborations";
import { useMediaQuery } from "react-responsive";

const AboutUs = () => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param2 = params.length > 1 ? params[1] : "";

  if (param2) {
    return <Outlet />
  }

  return (
    <Container fluid className="mt-5 px-0">
      <Container fluid className="about-us-full-page-background p-0"></Container>
      <Container
        fluid
        className="about-us-hero-background text-white d-flex flex-column justify-content-start align-items-start about-us-full-page-background-content"
        style={{ minHeight: "100vh" }}
      >
        <Container className="py-5">
          {/* <div className="mt-4 mt-lg-0">{t("aboutUs")}&nbsp;{" > "}&nbsp;Empowering Your Business Formation</div> */}
          <div className="mt-4">{t("aboutUs")}</div>
          <h2 className="my-3">{t("aboutSKV")}</h2>
          <Row className="mb-lg-0 mb-4">
            <Col lg={6}>
              <div className="subtitle mb-5">{t("aboutPara1")}</div>
              <div className="subtitle mb-5">{t("aboutPara2")}</div>
            </Col>
            <Col lg={6}>
              <iframe
                width={small ? 560 : "100%"}
                height="315"
                src="https://www.youtube.com/embed/LudfXLKhCLs?si=2QSXdMmD_E1hA4y9"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Col>
          </Row>
          <div className="subtitle mb-5">{t("aboutPara3")}</div>
          <Button
            className={`bg-transparent text-white border border-white poppins-600 ${medium ? "" : "w-100"
              }`}
            style={{
              paddingLeft: 35,
              paddingRight: 35,
              borderRadius: 34,
              height: 60,
            }}
            onClick={() => navigate(`/get-in-touch`)}
          >
            {t("getInTouch")}
          </Button>
        </Container>
      </Container>
      {/* <AboutUsNumbers /> */}
      <OurNetworks />
      <Container fluid className="bg-transparent">
        <Container style={{ height: '60vh' }}>
          <div className="custom-header d-flex flex-column text-white pt-5 mt-5 mb-3">
            <span>RESPONSIBILITY & QUALITY</span>
          </div>
          <h4 className="text-white">Our goal is to attain excellence by delivering superior services to our partners.</h4>
        </Container>
        <Container style={{ height: '60vh' }}>
          <div className="custom-header d-flex flex-column align-items-end text-white mb-3">
            <span>CUSTOMER-CENTRIC ORGANIZATION</span>
          </div>
          <h4 className="text-white d-flex justify-content-end">We are committed to fostering a customer-centric culture throughout every facet of our organization.</h4>
        </Container>
        <Container style={{ height: '40vh' }}>
          <div className="custom-header d-flex flex-column text-white mb-3">
            <span>SUSTAINABLE BUSINESS PRACTICES</span>
          </div>
          <h4 className="text-white">We are dedicated to making a positive impact by prioritizing sustainability, community, and prosperity in all our actions.</h4>
        </Container>
      </Container>
      <div className="bg-white">
        <Collaborations />
      </div>
      <MissionAndVision />
      <RoadMap />
      <Container fluid className="bg-white py-5">
        <ChatWithProfessionals />
      </Container>
      <BoostBusiness />
      <Container fluid className="bg-white">
        <Subscribe />
      </Container>
    </Container>
  );
};

export default AboutUs;
