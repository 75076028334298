import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ form, isSubmitting, handleResetPassword }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="pe-3">{t("setNewPassword")}</h3>
      <div className="text-secondary my-2">
        {t("Your new password must be different to previously used passwords.")}
      </div>
      <Form
        className="text-start w-100"
        ref={form}
        onSubmit={(e) => handleResetPassword(e)}
      >
        <Form.Group className="mt-2">
          <Form.Label className="body-text1">{t("otp")}</Form.Label>
          <Form.Control
            name="otp"
            placeholder="&#8226;&#8226;&#8226;&#8226;"
            type="password"
            className="bg-white ps-4"
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label className="body-text1">{t("enterPassword")}</Form.Label>
          <Form.Control
            name="password1"
            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            type="password"
            className="bg-white ps-4"
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label className="body-text1">
            {t("Confirm Password")}
          </Form.Label>
          <Form.Control
            name="password2"
            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            type="password"
            className="bg-white ps-4"
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </Form.Group>
        <Button
          className="my-4 bg-primary text-white primary-button poppins-600 w-100"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 30,
            height: 60,
          }}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span>
              <Spinner size="sm" className="me-2" />
              {`${t("resetting")}`}
            </span>
          ) : (
            <span className="poppins">{t("resetPassword")}</span>
          )}
        </Button>
      </Form>
    </>
  );
};

export default ResetPassword;
