import React from "react";
import "./App.css";
import "./App.scss";
import { BreakpointProvider } from "react-socks";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import Routers from "./Routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ParallaxProvider } from 'react-scroll-parallax';

// persistor.purge();

const App = () => {
  // setDefaultBreakpoints([
  //   { xsmall: 0 }, // all mobile devices
  //   { small: 576 }, // mobile devices (not sure which one's this big)
  //   { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  //   { large: 992 }, // smaller laptops
  //   { xlarge: 1200 }, // laptops and desktops
  //   { xxlarge: 1400 }, // laptops and desktops
  // ]);

  return (
    <GoogleOAuthProvider clientId="1011376067907-fnd2nefcq2ttr0jjdcf4jqr6ldmo1a6u.apps.googleusercontent.com">
      <BreakpointProvider>
        <Provider store={store}>
          <ParallaxProvider>
            <ToastContainer />
            <Routers />
          </ParallaxProvider>
        </Provider>
      </BreakpointProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
