import React from "react";
import { Container, Button, Card, Image, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  BoostBusiness,
  Subscribe,
  FAQ,
} from "components";

import {
  licensePhoto,
  proPhoto,
  taxPhoto,
  amlPhoto,
  visaPhoto,
} from "assets";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Services.scss";
import { Breakpoint } from "react-socks";

const Services = () => {
  const { t } = useTranslation();

  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const navigate = useNavigate();

  const services = [
    {
      id: 2,
      title: t("licenseServices"),
      description: t("licenseServicesDesc"),
      image: licensePhoto,
      url: "license-service",
    },
    {
      id: 3,
      title: t("amlRegistration"),
      description: t("amlRegistration"),
      image: amlPhoto,
      url: "aml-registration",
    },
    {
      id: 4,
      title: t("proServices"),
      description: t("proServicesDesc"),
      image: proPhoto,
      url: "pro-service",
    },
    {
      id: 5,
      title: t("visaServices"),
      description: t("visaServicesDesc"),
      image: visaPhoto,
      url: "visa-service",
    },
    {
      id: 6,
      title: t("vatRegistrationAndCorporateTaxRegistration"),
      description: t("vatRegistrationAndCorporateTaxRegistrationDesc"),
      image: taxPhoto,
      url: "vat-corporate-tax-registration",
    },
  ];

  const truncateDescription = (description) => {
    if (description.length <= 150) {
      return description;
    }
    return `${description.substring(0, 150)}...`;
  };

  return (
    <>
      <Container
        fluid
        className="service-top-background d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>{t("ourServices")}</b>
          </div>
          <div className="page-header my-lg-3 my-2">{t("whatWeOffer")}</div>
          <div className="subtitle-400 text-secondary text-lg-center mb-5">
            {t("Your needs, our expertise. Find the perfect solution.")}
          </div>
        </Container>
      </Container>
      <Breakpoint large up>
        <Container>
          <Row className="row-cols-2">
            {services.map((service) => {
              return (
                <Col className="">
                  <Card className="my-4 service-page-service-card">
                    <Image src={service.image} alt={service.title} className="card__img" />
                    <span className="card__footer">
                      <h3 className="p-3 text-primary">{service.title}</h3>
                    </span>
                    <span className="card__action">
                      <Button
                        className="bg-primary text-white primary-button poppins-600"
                        style={xlarge ?
                          {
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 30,
                            height: 60,
                          }
                          :
                          {
                            paddingLeft: 20,
                            paddingRight: 20,
                            borderRadius: 20,
                            height: 40,
                          }
                        }
                        onClick={() => navigate(`/services/${service.url}`)}
                      >
                        Learn More
                      </Button>
                    </span>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container>
          {services.map((service) => {
            return (
              <Card className="my-4 d-flex flex-column" style={{ overflow: "hidden" }}>
                <Row>
                  <Col sm={6}>
                    <Image width="100%" height="auto" src={service.image} alt={service.title} />
                  </Col>
                  <Col sm={6}>
                    <div className="d-flex flex-column justify-content-center p-3">
                      <h4 className="card-title">{service.title}</h4>
                      <div className="card-title">{truncateDescription(service.description)}</div>
                      <div>
                        <Button
                          className="bg-primary text-white primary-button border poppins-600"
                          style={{
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 30,
                            height: 60,
                          }}
                          onClick={() => navigate(`/services/${service.url}`)}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            )
          })}
        </Container>
      </Breakpoint>
      <BoostBusiness />
      <Subscribe />
      <FAQ />
    </>
  );
};

export default Services;
