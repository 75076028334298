import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import {
  // collaborations
  amer,
  dubai_armed_forces,
  dubai_courts,
  dubai_economy,
  dubai_health_authority,
  dubai_municipality,
  dubai_police,
  emirates_identity_authority,
  government_of_dubai,
  land_department,
  sira,
  tasheel,
  tawjeeh,
  uae_ministry_of_finance,
} from "assets";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Collaborations = ({ bold = true }) => {
  const { t } = useTranslation();

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const collaborations = [
    { id: 1, title: "Tasheel", image: tasheel },
    { id: 2, title: "Tawjeeh", image: tawjeeh },
    { id: 3, title: "UAE Ministry of Finance", image: uae_ministry_of_finance },
    { id: 4, title: "Amer", image: amer },
    {
      id: 5,
      title: "Emirates Identity Authority",
      image: emirates_identity_authority,
    },
    { id: 6, title: "Dubai Police", image: dubai_police },
    {
      id: 7,
      title: "Dubai Health Authority",
      image: dubai_health_authority,
    },
    { id: 8, title: "Government of Dubai", image: government_of_dubai },
    { id: 9, title: "Dubai Courts", image: dubai_courts },
    { id: 10, title: "Dubai Economy", image: dubai_economy },
    { id: 11, title: "Dubai Municipality", image: dubai_municipality },
    { id: 12, title: "Land Department", image: land_department },
    { id: 13, title: "Dubai Armed Forces", image: dubai_armed_forces },
    { id: 14, title: "Sira", image: sira },
  ];

  return (
    <Container
      fluid
      className="py-5 user-select-none d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <div className="text-center">
        {bold ?
          <>
            <div className="custom-header text-center mb-3">
              {t("ourCollaborations")}
            </div>
            <div className="subtitle-400 text-secondary text-center mb-5">
              {t("We have collaborations with multiple government agencies")}
            </div>
          </>
          :
          <>
            <div className="px-lg-3 text-primary body-text2">
              <b>Government Collaborations</b>
            </div>
            <div className="page-header my-lg-3 my-2">{t("ourCollaborations")}</div>
            <div className="subtitle-400 text-secondary text-lg-center mb-5">
              {t("We have collaborations with multiple government agencies")}
            </div>
          </>
        }
        <Row
          xs={2}
          md={3}
          lg={4}
          className={`d-flex justify-content-center align-items-center `}
        >
          {collaborations.map((client) => {
            return (
              <Col className="d-flex justify-content-center align-items-center my-3">
                <Image
                  className="mx-4"
                  src={client.image}
                  alt={client.title}
                  style={{
                    width: large ? 200 : 130,
                    maxHeight: large ? 160 : 100,
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
};

export default Collaborations;
